import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../UI/AgGrid";
import {
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
// import { USERS_AGGRID } from "../../../services/AgGrid/UserAgGrid";
import { useRoleColumnDefs } from "./columnDefs";
import { ROLES_AGGRID } from "../../../services/AgGrid/UserAgGrid";

const RoleTable = () => {
  const gridRef = useRef();
  const navigate = useNavigate();

  const graphQLClientUser: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/user/role/${id}?tab=inventory`);
  };

  const columnDefs = useRoleColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      try {
        const { RolesView } = await graphQLClientUser.request(ROLES_AGGRID, {
          aggridInput: {
            startRow,
            endRow,
            filterModel,
            sortModel,
          },
        });
        params.success({
          rowData: RolesView.results as any[],
          rowCount: RolesView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      path={"/user/role"}
      disabledSidebar
      onGridReady={onGridReady}
    />
  );
};

export default RoleTable;
