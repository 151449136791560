import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../generated/general";
import { renderAddressString } from "../utils/Formatter/Global";
import { ITag } from "../types/global";

export const usePDFMultiplePage = (
  data: any,
  documentType: string,
  rowWidth: number, // item name and description cell width
  maxHeightWithoutFooter: number, //Max height of table body (Footer is summary + remark & tag)
  maxHeightWithFooter: number,
  leftHeaderMinHeight: number,
  rightHeaderMinHeight: number,
  leftFooterMinHeight: number = 0,
  rightFooterMinHeight: number = 0
) => {
  const baseHeaderHeight = Math.max(leftHeaderMinHeight, rightHeaderMinHeight);
  const baseFooterHeight = Math.max(leftFooterMinHeight, rightFooterMinHeight);

  let paginatedItems: any[][] = [];
  let currentItems: any[] = [];
  let currentTableHeight = 0;
  let footerHeight = 0;

  let itemList =
    data?.item_list || data?.deposit_list || data?.trace_entry_list;

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: company } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: 1,
      },
    }
  );

  const defaultAddress =
    company?.CompanyFindUnique &&
    company.CompanyFindUnique.address_list &&
    company.CompanyFindUnique.address_list.find(
      (address: { is_default: any }) => address.is_default
    );

  //Calculate header height

  const calculateLeftHeaderHeight = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const companyAddressMaxWidth = 282.73;
    const addressMaxWidth = 303.11;
    const nameMaxWidth = 303.11;

    if (!context) {
      throw new Error("Cannot get 2D context");
    }

    context.font = "10.66px";

    const companyAddress = `${defaultAddress?.address} ${defaultAddress?.sub_district} ${defaultAddress?.district} ${defaultAddress?.province} ${defaultAddress?.postal_code}`;

    const customerAddress =
      renderAddressString(data?.delivery_address || {}) || "-";

    let name = "-";

    if (data?.requester) {
      name = `${data?.requester.unique_id} - ${data?.requester.name}`;
    } else if (data?.vendor_unique_id) {
      name = `${data.vendor_unique_id} - ${data.vendor_details.name}`;
    }

    let additionalHeight = 0;

    let companyAddressWidth = context.measureText(companyAddress).width;
    if (companyAddressWidth > companyAddressMaxWidth) {
      const lines = Math.ceil(companyAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 15.98;
    }

    let customerAddressWidth = context.measureText(customerAddress).width;
    if (customerAddressWidth > addressMaxWidth) {
      const lines = Math.ceil(customerAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 21.313;
    }

    let nameWidth = context.measureText(name).width;
    if (nameWidth > nameMaxWidth) {
      const lines = Math.ceil(nameWidth / nameMaxWidth);
      additionalHeight += (lines - 1) * 21.313;
    }

    return leftHeaderMinHeight + additionalHeight;
  };

  const calculateRightHeaderHeight = () => {
    const referenceIds = data?.reference_document_list;
    let additionalLines = 0;

    if (referenceIds && referenceIds.length > 0) {
      const refLines = Math.ceil(referenceIds.length / 2);
      additionalLines = refLines - 1;
    }

    return rightHeaderMinHeight + additionalLines * 21.313;
  };

  //Calculate Footer Height

  const calculateLeftFooterHeight = () => {
    const remark = data?.remark ? data.remark : "-";
    const tags =
      data?.tag_list && data?.tag_list.length > 0
        ? data.tag_list.map((tag: ITag) => tag.name).join(", ")
        : "-";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let lines = 0;

    if (rightFooterMinHeight === 0) {
      lines =
        Math.ceil(context.measureText(remark).width / 735.688) +
        Math.ceil(context.measureText(tags).width / 735.688);
    } else {
      lines =
        Math.ceil(context.measureText(remark).width / 424.14) +
        Math.ceil(context.measureText(tags).width / 424.14);
    }

    const additionalRemarkLines = lines - 2;

    return leftFooterMinHeight + additionalRemarkLines * 11.95;
  };

  const calculateRightFooterHeight = () => {
    const additional_discount =
      documentType === "sales_quotation"
        ? 0
        : data?.additional_discount > 0
        ? 1
        : 0;
    const vat_exem = data?.vat_exempted_amount > 0 ? 1 : 0;
    const zero_percent = data?.vat_0_percent_amount > 0 ? 1 : 0;
    const seven_percent = data?.vat_7_percent_amount > 0 ? 1 : 0;
    const vat_amount =
      documentType === "sales_quotation" ? 0 : data?.vat_amount > 0 ? 1 : 0;

    const additionalLines =
      additional_discount +
      vat_exem +
      zero_percent +
      seven_percent +
      vat_amount;

    return rightFooterMinHeight + additionalLines * 21.313;
  };

  const leftHeaderHeight = calculateLeftHeaderHeight();
  const rightHeaderHeight = calculateRightHeaderHeight();

  const additionalHeaderHeight =
    Math.max(leftHeaderHeight, rightHeaderHeight) - baseHeaderHeight;

  if (leftFooterMinHeight > 0 || rightFooterMinHeight > 0) {
    const leftFooterHeight = calculateLeftFooterHeight();
    const rightFooterHeight = calculateRightFooterHeight();

    footerHeight = Math.max(leftFooterHeight, rightFooterHeight);

    const additionalFooterHeight = footerHeight - baseFooterHeight;

    maxHeightWithFooter -= additionalHeaderHeight + additionalFooterHeight;
  } else {
    maxHeightWithFooter -= additionalHeaderHeight;
  }
  maxHeightWithoutFooter -= additionalHeaderHeight;

  //Calculate each row's height in table
  itemList?.forEach((item: any, index: number) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let numberOfLines = 1;
    let rowHeight = 0;
    const { item_name: itemName, item_sku_desc: itemDescription } = item;

    const nameText = itemName;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;
    // Handle multiline description
    const descriptionLines = itemDescription ? itemDescription.split("\n") : [];

    if (descriptionLines.length > 0) {
      descriptionLines.forEach((line: string) => {
        const lineWidth = context.measureText(line).width;
        const linesRequired = Math.ceil(lineWidth / rowWidth);
        numberOfLines += linesRequired > 1 ? linesRequired : 1;
      });
    }

    if (numberOfLines > 1) {
      rowHeight = 53 + (numberOfLines - 1) * 15;
    } else {
      rowHeight = 53;
    }

    if (index === itemList.length - 1 && documentType === "customer_deposit") {
      if (currentTableHeight + rowHeight + 25 > maxHeightWithoutFooter) {
        paginatedItems.push(currentItems);
        currentItems = [];
        currentTableHeight = rowHeight;
        currentItems.push(item);
      } else {
        currentTableHeight += rowHeight;
        currentItems.push(item);
      }
    } else {
      if (currentTableHeight + rowHeight > maxHeightWithoutFooter) {
        paginatedItems.push(currentItems);
        currentItems = [];
        currentTableHeight = rowHeight;
        currentItems.push(item);
      } else {
        currentTableHeight += rowHeight;
        currentItems.push(item);
      }
    }
  });

  paginatedItems.push(currentItems);

  if (currentTableHeight > maxHeightWithFooter) {
    paginatedItems.push([]);
  }

  let startNumbers: number[] = [];
  let currentNumber = 0;

  paginatedItems.forEach((pageItems, index) => {
    if (index === 0) {
      startNumbers.push(1);
      currentNumber = pageItems.length;
    } else {
      startNumbers.push(currentNumber + 1);
      currentNumber += pageItems.length;
    }
  });

  return {
    paginatedItems,
    startNumbers,
  };
};
