import { IAttachment, ICreatedBy, IImporterError } from "../../../types/global";
import { uploadFileToS3 } from "../../s3";
import { AddImporterErrorHandler } from "../../Importer";

export const couponImportFormatter = async (
  files: IAttachment[],
  created_by: ICreatedBy,
  state_uuid: string,
  promotion_id?: number
) => {
  let uploadedAttachment: IAttachment[] = [];
  if (files && files.length > 0) {
    for (const file of files) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "coupon",
          created_by.user_unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  return {
    created_by,
    promotion_id,
    state_uuid,
    url: uploadedAttachment[0].url,
  };
};

export const couponImportErrorFormatter = (
  err: any,
  resultArray: IImporterError[],
  addImporterErrorHandler: AddImporterErrorHandler
) => {
  if (err?.duplicated_code_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "ข้อมูลในไฟล์ซ้ำกัน",
      "รหัสคูปอง",
      err.duplicated_code_list.map((item: string) => ({
        name: item,
        value: item,
        description: "รหัสคูปอง",
      })),
      true
    );
  }
  if (err?.duplicated_sequence_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "ข้อมูลในไฟล์ซ้ำกัน",
      "ลำดับ",
      err.duplicated_sequence_list?.map((item: number) => ({
        name: "",
        value: item,
        description: "ลำดับ",
      })),
      true
    );
  }
  if (err?.invalid_sequence_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบข้อมูลไม่ถูกต้อง",
      "ลำดับ",
      err.invalid_sequence_list?.map((item: number) => ({
        name: "",
        value: item,
        description: "ลำดับ",
      })),
      true
    );
  }
  if (err?.missing_sequence_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "ไม่พบลำดับคูปองนี้ในระบบ",
      "ลำดับ",
      err.missing_sequence_list?.map((item: number) => ({
        name: "",
        value: item,
        description: "ลำดับ",
      })),
      true
    );
  }
  if (err?.invalid_status_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบข้อมูลไม่ถูกต้อง",
      "สถานะ",
      err.invalid_status_list?.map((item: string) => ({
        name: "",
        value: item,
        description: "สถานะ",
      })),
      true
    );
  }
  if (err?.invalid_coupon_status_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบการเปลี่ยนสถานะไม่ถูกต้อง",
      "สถานะ",
      err.invalid_coupon_status_list?.map((item: any) => ({
        name: item.code,
        value: formatStatus(item.status),
        description: "สถานะ",
      })),
      true
    );
  }
  if (err?.used_coupon_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบการเปลี่ยนสถานะไม่ถูกต้อง",
      "สถานะ",
      err.used_coupon_list?.map((item: any) => ({
        name: item.code,
        value: formatStatus(item.status),
        description: "สถานะ",
      })),
      true
    );
  }
  if (err?.special_character_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบข้อมูลไม่ถูกต้อง",
      "รหัสคูปอง",
      err.special_character_list?.map((item: string) => ({
        name: "",
        value: item,
        description: "รหัสคูปอง",
      })),
      true
    );
  }
  if (err?.exceeded_character_length_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "รูปแบบข้อมูลไม่ถูกต้อง",
      "รหัสคูปอง",
      err.exceeded_character_length_list?.map((item: string) => ({
        name: "",
        value: item,
        description: "รหัสคูปอง",
      })),
      true
    );
  }
  if (err?.duplicated_update_coupon_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "ข้อมูลซ้ำกับในระบบ",
      "รหัสคูปอง",
      err.duplicated_update_coupon_list?.map((item: string) => ({
        name: item,
        value: "",
        description: "รหัสคูปอง",
      })),
      true
    );
  }
  if (err?.duplicated_create_coupon_list?.length > 0) {
    addImporterErrorHandler(
      resultArray,
      "ข้อมูลซ้ำกับในระบบ",
      "รหัสคูปอง",
      err.duplicated_create_coupon_list?.map((item: string) => ({
        name: item,
        value: "",
        description: "รหัสคูปอง",
      })),
      true
    );
  }
  if (err?.limit_coupon) {
    addImporterErrorHandler(
      resultArray,
      "จำนวนข้อมูลในไฟล์เกินในระบบ",
      "จำนวนคูปอง",
      [{ name: "", value: err.limit_coupon, description: "จำนวนคูปอง" }],
      true
    );
  }
};

const formatStatus = (status: string): string => {
  switch (status) {
    case "active":
      return "พร้อมใช้งาน";
    case "used":
      return "ใช้งานแล้ว";
    case "inactive":
      return "ยกเลิก";
    default:
      return status;
  }
};
