import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useRoleColumnDefs = () => {
  const { t } = useTranslation();
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "name",
        headerName: t("user.role.name"),
        sort: "asc",
        filter: "agTextColumnFilter",
        flex: 1,
      },
      {
        field: "description",
        headerName: t("user.role.description"),
        filter: "agTextColumnFilter",
        flex: 1,
      },
      {
        field: "user_count",
        headerName: t("user.role.total_user"),
        filter: "agNumberColumnFilter",
        flex: 1,
      },
    ],
    [t]
  );

  return columnDefs;
};
