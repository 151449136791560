import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";

import SubHeader from "../../Purchase/SubHeader";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CheckedCustomerModal from "../../../UI/Modal/CheckedCustomerModal";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { useDisable } from "../../../../hooks/use-disable";
import { useContactDescription } from "../../../../hooks/use-contact-description";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";
import { watch } from "fs";

type Props = {
  documentType?: string;
  disabledField?: boolean;
};

const CustomerInfo = ({
  documentType = "delivery_order",
  disabledField,
}: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IDeliveryOrder>();

  const watchCustomer = watch("customer_unique_id");

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    finishContactsSelect,
    openContactDetail,
  } = useContactDescription("sales", documentType);

  return (
    <Fragment>
      <CustomizedBox>
        <Box display="flex" gap={1} alignItems="center" mb={2}>
          <SubHeader title={t("logistic.delivery_order.customer.info")} />
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.unique_id_name"
              label={
                t("logistic.delivery_order.customer.unique_id") +
                " - " +
                t("logistic.delivery_order.customer.name")
              }
              disabled
              viewMode={disabled}
              required
              error={Boolean(errors.customer_details?.name)}
              helperText={errors?.customer_details?.name?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.identity_no"
              label={t("sales.identity_no")}
              error={Boolean(errors.customer_details?.identity_no)}
              helperText={errors?.customer_details?.identity_no?.message?.toString()}
              viewMode={disabled}
              disabled
              required
            />
          </Grid>
          {watchCustomer ? (
            <Grid item xs={12} md={4}>
              <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                <IconButton onClick={openContactDetail} color="primary">
                  <LaunchOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          ) : (
            <Box width={"100%"} />
          )}
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.phone"
              label={t("global.phone_number")}
              viewMode={disabled}
              disabled={disabledField}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.email"
              label={t("global.email")}
              viewMode={disabled}
              disabled={disabledField}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.fax"
              label={"โทรสาร"}
              viewMode={disabled}
              disabled={disabledField}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedCustomerModal
        showSelectCustomer={openContactTable}
        closeCustomerTable={closeContactTableHandler}
        customerIds={contactIds}
        setCustomerIds={setContactIds}
        customerIdsSnapshot={contactIdsSnapshot}
        setCustomerIdsSnapshot={setContactIdsSnapshot}
        finishCustomersSelect={finishContactsSelect}
        rowSelection="single"
      />
    </Fragment>
  );
};

export default CustomerInfo;
