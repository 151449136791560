import * as Yup from "yup";
import { IRole } from "../../../types/Auth/role";
import { inventoryPermissionValue } from "../../../utils/Permission/Inventory";
import { contactPermissionValue } from "../../../utils/Permission/Contact";
import { salesPermissionValue } from "../../../utils/Permission/Sales";
import { purchasePermissionValue } from "../../../utils/Permission/Purchase";
import { userPermissionValue } from "../../../utils/Permission/User";
import { settingPermissionValue } from "../../../utils/Permission/Setting";
import { marketingPermissionValue } from "../../../utils/Permission/Marketing";
import { logisticPermissionValue } from "../../../utils/Permission/Logistic";

export const roleSchema: IRole = {
  name: "",
  description: "",
  inventory_permission: inventoryPermissionValue,
  contact_permission: contactPermissionValue,
  sales_permission: salesPermissionValue,
  purchase_permission: purchasePermissionValue,
  user_permission: userPermissionValue,
  setting_permission: settingPermissionValue,
  marketing_permission: marketingPermissionValue,
  logistic_permission: logisticPermissionValue,
  user_list: [],
  user_id_list: [],
};

export const roleValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุ"),
});
