import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { Box, Grid, Typography } from "@mui/material";

import LabelInput from "../../../UI/LabelInput";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { useDisable } from "../../../../hooks/use-disable";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";
import { useDeliveryTypeOptions } from "../../../../hooks/Logistic/use-do-setting-option";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";

const DeliveryInfo = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IDeliveryOrder>();

  const { options } = useDeliveryTypeOptions();

  return (
    <Fragment>
      <CustomizedBox margin={"1.5rem 0 0 0"}>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item xs={4} sm={3} md={2}>
            <Typography fontWeight={600} color={"primary.main"}>
              {t("logistic.delivery_order.delivery_info.index")}
            </Typography>
          </Grid>
          <Box width={"100%"} />
          <Grid item xs={12} md={6}>
            {disabled ? (
              <LabelInput
                label={t("logistic.delivery_order.delivery_info.type")}
                value={getValues("delivery_type")}
              />
            ) : (
              <Controller
                control={control}
                name="delivery_type"
                render={({ field }) => (
                  <CustomizedComboBox
                    options={options}
                    label={t("logistic.delivery_order.delivery_info.type")}
                    {...field}
                    onChange={(_, newValue) => {
                      field.onChange(newValue ? newValue.value : "");
                    }}
                    error={Boolean(errors.delivery_type)}
                    helperText={errors.delivery_type?.message}
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              label={t("logistic.delivery_order.delivery_info.tracking_no")}
              name="tracking_no"
              control={control}
              viewMode={disabled}
              disabled={disabled}
              error={Boolean(errors.tracking_no)}
              helperText={errors.tracking_no?.message}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    </Fragment>
  );
};

export default DeliveryInfo;
