import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../../../generated/general";
import { Box, Skeleton, Typography } from "@mui/material";
import { formatCompanyName } from "../../../utils/Global";

type Props = {
  branch_unique_id: string;
};

const PdfCompanyInfo = ({ branch_unique_id }: Props) => {
  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: 1,
      },
    }
  );

  const defaultAddress =
    data?.CompanyFindUnique &&
    data.CompanyFindUnique.address_list &&
    data.CompanyFindUnique.address_list.find((address) => address.is_default);

  const fontSize = 10.66;

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box>
        <img
          alt="npr-erp-logo"
          src={
            data?.CompanyFindUnique?.img_url
              ? data?.CompanyFindUnique.img_url[0]
                ? data?.CompanyFindUnique.img_url[0]
                : "/static/logo.png"
              : "/static/logo.png"
          }
          className="image-no-hover"
        />
      </Box>
      <Box>
        <Typography fontSize={16} fontWeight={600} lineHeight={2}>
          {formatCompanyName(
            data?.CompanyFindUnique?.type || "",
            data?.CompanyFindUnique?.name || ""
          )}
        </Typography>
        <Typography fontSize={fontSize}>
          {`${defaultAddress?.address || ""} ${
            defaultAddress?.sub_district || ""
          } ${defaultAddress?.district || ""} ${
            defaultAddress?.province || ""
          } ${defaultAddress?.postal_code || ""}`}
        </Typography>
        <Box display={"flex"} gap={0.5}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            เลขประจำตัวผู้เสียภาษี
          </Typography>
          <Typography fontSize={fontSize} lineHeight={2}>
            {data?.CompanyFindUnique?.identity_no || "-"} ({branch_unique_id})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfCompanyInfo;
