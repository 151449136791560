import { useCallback, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "../../../UI/AgGrid";
import {
  ColDef,
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { usePurchaseOrderColumnDefs } from "./columnDefs";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { PURCHASE_ORDER_AGGRID } from "../../../../services/AgGrid/PurchaseAgGrid";

interface Props {
  isFilter?: string | null;
  referenceUniqueId?: string;
}

const PurchaseOrderTable = ({ isFilter, referenceUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const branch_id = parseInt(sessionStorage.getItem("branch-id") || "");

  const columnDef: ColDef[] | undefined = usePurchaseOrderColumnDefs();

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        issue_date,
        within_date,
        delivery_date,
        created_by_object,
        related_user_object_list,
        reference_document_list,
        last_updated_date,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        within_date: dateFilterModel(within_date),
        created_by: created_by_object,
        last_updated_date: dateFilterModel(last_updated_date),
        related_user_list: related_user_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_object_list.values,
            }
          : undefined,
        reference_document_list: referenceUniqueId
          ? {
              filterType: "array",
              type: "hasSome",
              values: [referenceUniqueId],
            }
          : undefined,
        branch_id:
          branch_id !== -1
            ? {
                filterType: "number",
                type: "equals",
                filter: branch_id,
              }
            : undefined,
      };

      try {
        const { PurchaseOrderViewsAggrid } =
          await graphQLClientWithHeader.request(PURCHASE_ORDER_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: PurchaseOrderViewsAggrid.results as any[],
          rowCount: PurchaseOrderViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_approve":
          instance?.setModel({ values: ["wait_approve"] });
          break;
        case "not_approved":
          instance?.setModel({ values: ["not_approved"] });
          break;
        case "approved":
          instance?.setModel({ values: ["approved"] });
          break;
        case "partially_imported":
          instance?.setModel({ values: ["partially_imported"] });
          break;
        case "fully_imported":
          instance?.setModel({ values: ["fully_imported"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/purchase/order/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/purchase/order"}
    />
  );
};

export default PurchaseOrderTable;
