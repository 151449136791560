import { gql } from "graphql-request";

export const ALL_SALES_TYPE = gql`
  query SalesTypesFindAll {
    SalesTypesFindAll {
      id
      unique_id
      name_th
      name_en
      description
    }
  }
`;

export const ALL_SALES_CHANNEL = gql`
  query SalesChannelsFindAll {
    SalesChannelsFindAll {
      id
      unique_id
      name_th
      name_en
      description
    }
  }
`;

export const SALES_TAG_LIST = gql`
  query Tags($findManyInput: TagFindManyInput) {
    Tags(findManyInput: $findManyInput) {
      id
      name
      document_type
    }
  }
`;

export const QUOTATION_AGGRID = gql`
  query QuotationViewsAggrid($aggridInput: AnyAggridInput) {
    QuotationViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        due_date
        branch_id
        branch_unique_id
        branch_name
        status
        customer_unique_id
        customer_name
        sales_type
        sales_channel_id
        sales_channel
        tag_list
        net_amount
        created_by_object
        created_by
        created_by_unique_id
        related_user_list
        related_user_object_list
        related_user_unique_id_list
      }
    }
  }
`;

export const SALES_ORDER_AGGRID = gql`
  query SalesOrderViewsAggrid($aggridInput: AnyAggridInput) {
    SalesOrderViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        delivery_date
        branch_id
        branch_unique_id
        branch_name
        status
        customer_unique_id
        customer_name
        sales_type
        sales_channel_id
        sales_channel
        tag_list
        net_amount
        created_by_object
        created_by
        created_by_unique_id
        related_user_object_list
        related_user_list
        related_user_unique_id_list
        reference_unique_id
        reference_id
        item_list {
          id
          unique_id
          branch_id
          reference_line_item {
            line_item_unique_id
            line_item_document_type
          }
          reference_unique_id
          reference_document_type
          item_unique_id
          item_sku_name
          item_name
          item_sku_desc
          item_img_url
          tracability
          barcode
          qty
          so_qty
          uom_id
          uom_name
          price_per_unit
          discount
          vat_percentage
          volume
          weight
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          qty_shipped
          qty_to_ship
          qty_invoiced
          qty_returned
          qty_issued
          qty_received
        }
      }
    }
  }
`;

export const CASH_SALES_AGGRID = gql`
  query CashSalesViewsAggrid($aggridInput: AnyAggridInput!) {
    CashSalesViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        branch_id
        branch_unique_id
        branch_name
        status
        customer_unique_id
        customer_name
        sales_type
        sales_channel_id
        sales_channel
        tag_list
        tax_invoice_no
        net_amount
        created_by_object
        created_by
        related_user_object_list
        related_user_list
        reference_unique_id
        reference_id
        cd_unique_id_list
        cd_last_deduction_date
        cd_deduction_total_amount
      }
    }
  }
`;

export const SALES_RETURN_AGGRID = gql`
  query SalesReturnViewsAggrid($aggridInput: AnyAggridInput) {
    SalesReturnViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        branch_id
        branch_unique_id
        branch_name
        status
        customer_unique_id
        customer_name
        sales_type
        sales_channel_id
        sales_channel
        tag_list
        net_amount
        created_by_object
        created_by
        related_user_object_list
        related_user_list
        reference_unique_id
        reference_id
      }
    }
  }
`;

export const SALES_INVOICE_AGGRID = gql`
  query SalesInvoiceViewsAggrid($aggridInput: AnyAggridInput) {
    SalesInvoiceViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        due_date
        branch_id
        branch_unique_id
        branch_name
        status
        customer_unique_id
        customer_name
        sales_type
        sales_channel_id
        sales_channel
        tag_list
        tax_invoice_no
        net_amount
        paid_total_amount
        created_by_object
        created_by
        related_user_object_list
        related_user_list
        reference_unique_id
        reference_id
        cd_last_deduction_date
        cd_deduction_total_amount
      }
    }
  }
`;

export const CUSTOMER_DEPOSIT_AGGRID = gql`
  query CustomerDepositViewsAggrid($aggridInput: AnyAggridInput) {
    CustomerDepositViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        issue_date
        branch_unique_id
        branch_name
        branch_id
        status
        customer_unique_id
        customer_name
        tag_list
        net_amount
        created_by
        related_user_list
        remaining_deposit_amount
        created_by_object
        related_user_object_list
        tax_invoice_no
      }
    }
  }
`;

export const SALES_ITEMS = gql`
  query SalesItemFindMany($input: SalesItemFindManyInput) {
    SalesItemFindMany(input: $input) {
      id
      unique_id
      branch_id
      reference_unique_id
      reference_document_type
      item_unique_id
      item_sku_name
      item_name
      item_sku_desc
      item_img_url
      tracability
      barcode
      qty
      so_qty
      uom_id
      uom_name
      price_per_unit
      discount
      vat_percentage
      volume
      weight
      pre_vat_amount
      withholding_tax_type
      withholding_tax_value
      remark
      stock_qty
      qty_shipped
      qty_to_ship
      qty_invoiced
      qty_returned
      qty_issued
      qty_received
      last_updated_date
      reference_line_item {
        line_item_document_type
        line_item_unique_id
      }
    }
  }
`;
