import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedMenuList from "../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../types/global";
import { useStateContext } from "../contexts/auth-context";
import { hasPermission } from "../utils/Global";

const Landing = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
      permissionKey: "contact",
    },
    {
      name: t("inventory.index"),
      to: "/inventory",
      permissionKey: "inventory",
    },
    {
      name: t("sales.index"),
      to: "/sales",
      permissionKey: "sales",
    },
    {
      name: t("marketing.index"),
      to: "/marketing",
      permissionKey: "marketing",
    },
    {
      name: t("logistic.index"),
      to: "/logistic",
      permissionKey: "logistic",
    },
    {
      name: t("purchase.index"),
      to: "/purchase",
      permissionKey: "purchase",
    },
    {
      name: t("user.index"),
      to: "/user",
      permissionKey: "user",
    },
    {
      name: t("setting.index"),
      to: "/setting",
      permissionKey: "setting",
    },
  ].filter((item) => hasPermission(item.permissionKey, permissions));

  return (
    <>
      <Typography fontWeight="bold">หน้าแรก</Typography>
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Landing;
