import { Box, SxProps, Typography } from "@mui/material";

type Props = {
  title: string;
  required?: boolean;
  sx?: SxProps;
};

const SubHeader = ({ title, sx, required }: Props) => {
  return (
    <Box display={"flex"}>
      <Typography fontWeight={600} color={"primary.main"} sx={sx}>
        {title}
      </Typography>
      {required && (
        <Typography fontWeight={600} color={"red"}>
          *
        </Typography>
      )}
    </Box>
  );
};

export default SubHeader;
