import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderTable } from "../../../../types/global";

export const useSalesPromotionHeader = (disabled: boolean) => {
  const { t } = useTranslation();
  const [header, setHeader] = useState<IHeaderTable[]>([]);

  const defaultHeader: IHeaderTable[] = useMemo(
    () => [
      {
        thaiLabel: "",
        width: 34,
        align: "left",
      },
      {
        thaiLabel: "รหัส",
        width: 118,
        align: "left",
      },
      {
        thaiLabel: t("marketing.promotion.index"),
        width: 318,
        align: "left",
      },
      {
        thaiLabel: t("marketing.coupon.index"),
        width: 168,
        align: "left",
      },
      {
        thaiLabel: "อัตราส่วนลด",
        width: 134,
        align: "right",
      },
      {
        thaiLabel: "มูลค่าส่วนลด",
        width: 134,
        align: "right",
      },
      {
        thaiLabel: "ของแถม",
        width: 318,
        align: "left",
      },
      {
        thaiLabel: "จำนวน",
        width: 134,
        align: "right",
      },
    ],
    [t]
  );

  useEffect(() => {
    if (!disabled) {
      setHeader([
        ...defaultHeader,
        {
          thaiLabel: "",
          width: 36,
        },
      ]);
    } else {
      setHeader(defaultHeader);
    }
  }, [defaultHeader, disabled]);

  return header;
};
