import { Box, IconButton } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { usePromotionSelectOption } from "../../../../../hooks/Marketing/use-promotion-select-option";
import CloseIcon from "@mui/icons-material/Close";
import LabelInput from "../../../../UI/LabelInput";
import CustomizedComboBox from "../../../../Custom/CustomizedComboBox";

type Props = {
  removeItemGroupHandler: (index: number) => void;
  index: number;
};

const ItemGroupName = ({ removeItemGroupHandler, index }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const itemGroup = useWatch({
    control,
    name: `item_group.${index}`,
  });

  const { categoryOptions, subCategoryOptions, brandOptions } =
    usePromotionSelectOption(true, false);

  const itemGroupNameOptions = (itemGroupType: string) => {
    switch (itemGroupType) {
      case "main_category":
        return categoryOptions;
      case "sub_category":
        return subCategoryOptions;
      case "brand":
        return brandOptions;
      default:
        return [];
    }
  };
  return (
    <>
      {!disabled ? (
        <Box display={"flex"} gap={0.5}>
          <Controller
            control={control}
            name={`item_group.${index}.name`}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={itemGroupNameOptions(itemGroup.type)}
                label={t("marketing.promotion.item_group.name")}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.value : "");
                }}
                error={Boolean(
                  errors?.item_group && errors.item_group[index]?.name
                )}
                helperText={
                  errors?.item_group && errors?.item_group[index]?.name?.message
                }
                disabled={isApplied || !itemGroup?.type}
                required
              />
            )}
          />
          {/* 
          <ControlledSelect

            control={control}
            name={`item_group.${index}.name`}
            options={itemGroupNameOptions(itemGroup.type)}
           
          /> */}
          {index !== 0 && !isApplied && (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => removeItemGroupHandler(index)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      ) : (
        <LabelInput
          label={t("marketing.promotion.item_group.name")}
          value={itemGroup.name}
        />
      )}
    </>
  );
};

export default ItemGroupName;
