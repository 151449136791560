import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

import { IOptionsCreatable } from "../../types/global";
import {
  DeliveryTypesFindAllQuery,
  useDeliveryTypesFindAllQuery,
} from "../../generated/general";

export const useDeliveryTypeOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useDeliveryTypesFindAllQuery<DeliveryTypesFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.DeliveryTypesFindAll?.map((type) => ({
      id: type?.id || 0,
      label: type?.name_th || "",
      value: type?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allType: data || [],
  };
};
