import { Box, Grid, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { IOptionsCreatable } from "../../../../types/global";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import { useDisable } from "../../../../hooks/use-disable";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import LabelInput from "../../../UI/LabelInput";

const priorityOptions = () => {
  let options: IOptionsCreatable[] = [];
  for (let i = 0; i <= 99; i++) {
    options.push({
      id: i,
      label: i.toString(),
      value: i.toString(),
    });
  }

  return options;
};

const PromotionPriority = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IPromotion>();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const [disabled] = useDisable();
  const { t } = useTranslation();
  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Box display={"flex"} mb={2} alignItems={"center"} gap={0.5}>
        <Box display={"flex"} gap={0.3}>
          <Typography
            fontWeight={600}
            color={Boolean(errors.priority) ? "error.main" : undefined}
          >
            {t("marketing.promotion.priority")}
          </Typography>
          <Typography fontWeight={600} color={"error.main"}>
            *
          </Typography>
        </Box>
        <CustomizedTooltip title={t("marketing.promotion.tooltip.priority")}>
          <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
        </CustomizedTooltip>
      </Box>
      <Grid
        container
        spacing={1.5}
        sx={
          disabled
            ? {
                "& > .MuiGrid-item": {
                  paddingLeft: "0 !important",
                },
              }
            : {}
        }
      >
        <Grid item xs={12} sm={12} md={6}>
          {!disabled ? (
            <Controller
              control={control}
              name="priority"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={priorityOptions()}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                  error={Boolean(errors.priority)}
                  helperText={errors.priority?.message}
                  disabled={isApplied}
                />
              )}
            />
          ) : (
            <LabelInput label="" value={getValues("priority")} noDecimal />
          )}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default PromotionPriority;
