import dayjs from "dayjs";
import { PromotionSettingFindUniqueQuery } from "../../../generated/marketing";
import { IUser } from "../../../types/Auth/user";
import { IPromotion } from "../../../types/Marketing/Promotion";

export const promotionCreatePayloadFormatter = (
  data: IPromotion,
  user: IUser | null
) => {
  const {
    item_condition,
    redeem_item,
    is_item_condition,
    is_item_group,
    is_limit_amount,
    sales_channel_list,
    branch_list,
    group,
    coupon_code,
    customer_specific,
    customer_eligibility_snapshot,
    sales_channel_type_snapshot,
    coupon_type_snapshot,
    is_applied,
    coupon_count,
    coupon_available_count,
    total_coupon_usage,
    end_date_prev,
    ...otherData
  } = data;

  const { sales_channel_type } = otherData;

  let formatSalesChannelDetails: any[] = [];

  if (sales_channel_type === "specific_channels") {
    sales_channel_list.forEach((channel) =>
      formatSalesChannelDetails.push({ unique_id: channel.id })
    );
  } else if (sales_channel_type === "specific_sales") {
    branch_list.forEach((branch) =>
      formatSalesChannelDetails.push({ unique_id: branch.id })
    );
  } else {
    formatSalesChannelDetails = [];
  }

  let formatItemCondition: any[] = [];

  if (item_condition && item_condition.length > 0) {
    item_condition.forEach((item) => {
      formatItemCondition.push({
        item_id: item.item_id,
        qty: item.qty,
        unit: item.unit,
      });
    });
  }

  let formatRedeemItem: any[] = [];

  if (redeem_item && redeem_item.length > 0) {
    redeem_item.forEach((item) => {
      formatRedeemItem.push({
        item_id: item.item_id,
        qty: item.qty,
        unit: item.unit,
      });
    });
  }

  const formattedCustomerSpecific = customer_specific.map((customer) => ({
    customer_id: customer.id,
  }));

  const formatPayload = {
    ...otherData,
    created_by: user,
    sales_channel_details: formatSalesChannelDetails,
    item_condition: formatItemCondition,
    redeem_item: formatRedeemItem,
    coupon_code: coupon_code ? coupon_code : undefined,
    customer_specific: formattedCustomerSpecific,
  };
  return formatPayload;
};

export const promotionUpdatePayloadFormatter = (
  data: IPromotion,
  user: IUser | null
) => {
  const {
    unique_id,
    item_condition,
    redeem_item,
    is_item_condition,
    is_item_group,
    is_limit_amount,
    sales_channel_list,
    branch_list,
    group,
    created_by,
    state_uuid,
    customer_specific,
    customer_eligibility_snapshot,
    sales_channel_type_snapshot,
    coupon_type_snapshot,
    is_applied,
    coupon_count,
    coupon_available_count,
    total_coupon_usage,
    end_date_prev,
    ...otherData
  } = data;

  const { sales_channel_type } = otherData;

  let formatSalesChannelDetails: any[] = [];

  if (sales_channel_type === "specific_channels") {
    sales_channel_list.forEach((channel) =>
      formatSalesChannelDetails.push({ unique_id: channel.id })
    );
  } else if (sales_channel_type === "specific_sales") {
    branch_list.forEach((branch) =>
      formatSalesChannelDetails.push({ unique_id: branch.id })
    );
  } else {
    formatSalesChannelDetails = [];
  }

  let formatItemCondition: any[] = [];

  if (item_condition && item_condition.length > 0) {
    item_condition.forEach((item) => {
      formatItemCondition.push({
        item_id: item.item_id,
        qty: item.qty,
        unit: item.unit,
      });
    });
  }

  let formatRedeemItem: any[] = [];

  if (redeem_item && redeem_item.length > 0) {
    redeem_item.forEach((item) => {
      formatRedeemItem.push({
        item_id: item.item_id,
        qty: item.qty,
        unit: item.unit,
      });
    });
  }

  const formattedCustomerSpecific = customer_specific.map((customer) => ({
    customer_id: customer.id,
  }));

  const formatPayload = {
    ...otherData,
    sales_channel_details: formatSalesChannelDetails,
    item_condition: formatItemCondition,
    redeem_item: formatRedeemItem,
    last_updated_by: user,
    customer_specific: formattedCustomerSpecific,
  };
  return formatPayload;
};

export const promotionQueryFormatter = (
  data: PromotionSettingFindUniqueQuery["PromotionSettingFindUnique"]
) => {
  if (data) {
    const { sales_channel_details, priority, ...otherData } = data;
    const {
      limit_amount,
      item_group,
      item_condition,
      sales_channel_type,
      customer_eligibility,
      coupon_type,
    } = otherData;

    const end_date = data.end_date ? dayjs(data.end_date) : undefined;

    let is_limit_amount: boolean = false;
    let is_item_group: boolean = false;
    let is_item_condition: boolean = false;
    let branch_list: any[] = [];
    let sales_channel_list: any[] = [];
    let group: string = "";

    if (typeof limit_amount === "number") {
      is_limit_amount = true;
    }

    if (item_group && item_group.length > 0 && item_group[0]?.name) {
      is_item_group = true;
    }

    if (item_condition && item_condition.length > 0) {
      is_item_condition = true;
    }

    if (sales_channel_details && sales_channel_details.length > 0) {
      if (sales_channel_type === "specific_sales") {
        branch_list = sales_channel_details;
      } else if (sales_channel_type === "specific_channels") {
        sales_channel_list = sales_channel_details;
      }
    }

    if (customer_eligibility && customer_eligibility === "specific_groups") {
      group = "customer_group";
    }

    const formatData = {
      ...data,
      is_limit_amount,
      is_item_group,
      is_item_condition,
      branch_list,
      sales_channel_list,
      group,
      coupon_type,
      start_date: data.start_date ? dayjs(data.start_date) : undefined,
      end_date,
      end_date_prev: end_date,
      customer_eligibility_snapshot: customer_eligibility,
      sales_channel_type_snapshot: sales_channel_type,
      priority: priority?.toString(),
      coupon_type_snapshot: coupon_type,
    } as IPromotion;

    return formatData;
  }
};
