import dayjs from "dayjs";
import {
  BranchesFindAllQuery,
  BranchUserGetByUserIdQuery,
  UserCreateInput,
  UserQuery,
  UserUpdateInput,
} from "../../generated/general";
import { IBranchPermission, IUser } from "../../types/Auth/user";
import { uploadFileToS3 } from "../s3";
import { mergeAllPermissions, transformPermissions } from "./Role";

export const userCreateFormatter = async (
  data: IUser,
  id: string | undefined
) => {
  const {
    created_date,
    last_updated_date,
    confirm_password,
    password,
    status,
    branch_permission,
    original_branch,
    original_branch_object,
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
    role_id,
    is_custom,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const merged_permission = [
    ...inventory_permission,
    ...contact_permission,
    ...sales_permission,
    ...purchase_permission,
    ...user_permission,
    ...setting_permission,
    ...marketing_permission,
    ...logistic_permission,
  ];

  const role_permission = transformPermissions(merged_permission);

  let role_id_list: number[] = [];
  if (role_id) {
    role_id_list.push(role_id);
  }

  const formatUserPermission = {
    ...role_permission,
    is_custom,
  };

  const formatData: UserCreateInput = {
    ...otherData,
    original_branch: original_branch ? parseInt(original_branch.toString()) : 1,
    img_url,
    user_password: confirm_password || "",
    status: status ? 1 : 0,
    role_id_list,
    user_permission: formatUserPermission,
  };

  return formatData;
};

export const userUpdateFormatter = async (data: IUser) => {
  const {
    id,
    created_date,
    last_updated_date,
    last_login_branch,
    confirm_password,
    password,
    status,
    branch_permission,
    original_branch,
    original_branch_object,
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
    role_id,
    is_custom,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id?.toString() || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const merged_permission = [
    ...inventory_permission,
    ...contact_permission,
    ...sales_permission,
    ...purchase_permission,
    ...user_permission,
    ...setting_permission,
    ...marketing_permission,
    ...logistic_permission,
  ];

  const role_permission = transformPermissions(merged_permission);

  let role_id_list: number[] = [];
  if (role_id) {
    role_id_list.push(role_id);
  }

  const formatUserPermission = {
    ...role_permission,
    is_custom,
  };

  const formatData: UserUpdateInput = {
    ...otherData,
    original_branch: original_branch ? parseInt(original_branch.toString()) : 1,
    img_url,
    status: status ? 1 : 0,
    role_id_list,
    user_permission: formatUserPermission,
  };

  return formatData;
};

export const userQueryFormatter = (
  data: UserQuery["UserFindUnique"],
  branches?: BranchesFindAllQuery["BranchesFindAll"],
  branchUser?: BranchUserGetByUserIdQuery["BranchUserGetByUserId"]
): IUser | null => {
  if (data) {
    const {
      start_date,
      inactive_date,
      user_permission: user_permission_query,
      role_id_list,
      ...otherData
    } = data;
    let formattedBranches: IBranchPermission[] = [];

    if (branches && branches.length > 0) {
      const mappedBranchId =
        branchUser?.map((branch) => branch.branch_id) || [];
      const allBranches: IBranchPermission[] = branches.map((branch) => ({
        id: branch.id,
        unique_id: branch.unique_id,
        name: branch.name || "",
        is_hq: branch.is_hq,
        access: "close",
      }));

      const updateAccessStatus = (
        allBranches: IBranchPermission[],
        mappedBranchId: number[]
      ) => {
        return allBranches.map((branch) => ({
          ...branch,
          access: mappedBranchId.includes(branch.id) ? "open" : branch.access,
        }));
      };

      formattedBranches = updateAccessStatus(
        allBranches,
        mappedBranchId as number[]
      );
    }

    const sortByUniqueId = (a: IBranchPermission, b: IBranchPermission) => {
      // Use localeCompare for case-insensitive sorting
      return a.unique_id.localeCompare(b.unique_id);
    };

    const sortedBranches = formattedBranches.sort(sortByUniqueId);

    let formatData: IUser | null = null;

    if (user_permission_query) {
      const { is_custom, ...rest } = user_permission_query;

      const mergedPermissions = mergeAllPermissions(rest);

      const {
        inventory_permission,
        contact_permission,
        sales_permission,
        purchase_permission,
        user_permission,
        setting_permission,
        marketing_permission,
        logistic_permission,
      } = mergedPermissions;

      formatData = {
        ...otherData,
        start_date: start_date ? dayjs(start_date) : null,
        inactive_date: inactive_date ? dayjs(inactive_date) : null,
        branch_permission: sortedBranches,
        inventory_permission,
        contact_permission,
        sales_permission,
        purchase_permission,
        user_permission,
        setting_permission,
        marketing_permission,
        logistic_permission,
        is_custom,
        role_id:
          role_id_list && role_id_list.length > 0 ? role_id_list[0] : undefined,
      } as IUser;
    }

    return formatData;
  }
  return null;
};

export const formatAuthToUserPayload = (authUser: IUser | null) => {
  if (authUser)
    return {
      user_unique_id: authUser?.unique_id,
      email: authUser?.email,
      first_name: authUser?.first_name,
      last_name: authUser?.last_name,
      img_url: authUser?.img_url,
    };
  else return undefined;
};
