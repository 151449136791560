import DeliveryOrderTable from "../../../components/Table/Logistic/DeliveryOrder";

type Props = {
  referenceUniqueId?: string;
};

const DeliveryOrderTab = ({ referenceUniqueId }: Props) => {
  return <DeliveryOrderTable referenceUniqueId={referenceUniqueId} />;
};

export default DeliveryOrderTab;
