import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import { useStateContext } from "../../contexts/auth-context";
import { hasPermission } from "../../utils/Global";

const Sales = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
      permissionKey: "quotation",
    },
    {
      name: t("sales.order.index"),
      to: "/sales/order",
      permissionKey: "sales_order",
    },
    {
      name: t("sales.cash_sales.index"),
      to: "/sales/cash-sales",
      permissionKey: "cash_sales",
    },
    {
      name: t("sales.invoice.index"),
      to: "/sales/invoice",
      permissionKey: "sales_invoice",
    },
    {
      name: t("sales.return.index"),
      to: "/sales/return",
      permissionKey: "sales_return",
    },
    {
      name: t("sales.customer_deposit.index"),
      to: "/sales/customer-deposit",
      permissionKey: "customer_deposit",
    },
    {
      name: t("sales.report.index"),
      to: "/sales/report",
      permissionKey: "sales_report",
    },
  ].filter((item) => hasPermission(item.permissionKey, permissions));

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Sales;
