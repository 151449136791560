import { IPermission } from "../../types/Auth/role";

export const marketingPermissionValue: IPermission[] = [
  {
    variableName: "marketing",
    name: "การตลาด",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "promotion",
    name: "โปรโมชั่น",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "marketing_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
