import { IPermission } from "../../types/Auth/role";

export const settingPermissionValue: IPermission[] = [
  {
    variableName: "setting",
    name: "ตั้งค่า",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "zone",
    name: "โซน",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "branch",
    name: "ข้อมูลสาขา",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "company",
    name: "ข้อมูลกิจการ",
    view: "close",
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "approval",
    name: "การอนุมัติ",
    view: "close",
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "contact_setting",
    name: "ผู้ติดต่อ",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "inventory_setting",
    name: "คลังสินค้า",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "sales_setting",
    name: "การขาย",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "employee",
    name: "พนักงาน",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "logistic_setting",
    name: "ขนส่ง",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
];
