import {
  FieldError,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { Box, Grid } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";
import ControlledSelect from "../../../../Controller/ControlledSelect";
import { Fragment } from "react";
import LabelInput from "../../../../UI/LabelInput";
import CustomizedButton from "../../../../Custom/CustomizedButton";
// import { ISelectOption } from "../../../../../types/global";
import ItemGroupName from "./ItemGroupName";
import { useItemGroupOption } from "../../../../../hooks/Marketing/use-item-group-option";
const PromotionItemGroup = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);
  //   {
  //     label: t("setting.inventory.category"),
  //     value: "main_category",
  //   },
  //   {
  //     label: t("setting.inventory.sub_category"),
  //     value: "sub_category",
  //   },
  //   {
  //     label: t("setting.inventory.brand"),
  //     value: "brand",
  //   },
  // ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "item_group",
  });

  const filteredOptions = useItemGroupOption();

  const addItemGroupHandler = () => {
    append({
      type: "",
      name: "",
    });
  };

  const removeItemGroupHandler = (index: number) => {
    remove(index);
  };

  return (
    <Box
      bgcolor={"#F0F1FA80"}
      p={2}
      mb={1}
      sx={{
        borderRadius: "0.5rem",
      }}
    >
      <Grid container spacing={1.5}>
        {fields.map((item, index) => (
          <Fragment key={`${item.type}-${index}`}>
            <Grid item xs={12} sm={12} md={6}>
              {!disabled ? (
                <ControlledSelect
                  label={t("marketing.promotion.item_group.type")}
                  control={control}
                  name={`item_group.${index}.type`}
                  options={filteredOptions}
                  error={Boolean(
                    errors?.item_group && errors.item_group[index]?.type
                  )}
                  helperText={
                    errors?.item_group &&
                    (errors?.item_group[index]?.type as FieldError)?.message
                  }
                  onChange={(e: any) => {
                    setValue(`item_group.${index}.name`, "");
                  }}
                  required
                  disabled={isApplied}
                />
              ) : (
                <LabelInput
                  label={t("marketing.promotion.item_group.type")}
                  value={
                    filteredOptions.find((type) => type.value === item.type)
                      ?.label
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ItemGroupName
                removeItemGroupHandler={removeItemGroupHandler}
                index={index}
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
      {!disabled && !isApplied && fields.length < 3 && (
        <CustomizedButton
          sx={{ mt: 2 }}
          title={t("marketing.promotion.item_group.add")}
          onClick={addItemGroupHandler}
          addIcon
        />
      )}
    </Box>
  );
};

export default PromotionItemGroup;
