import { useEffect, useState } from "react";
import { useStateContext } from "../../../../contexts/auth-context";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Avatar, Box, Divider, IconButton } from "@mui/material";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { useDisable } from "../../../../hooks/use-disable";
import RelatedEmployeeIcon from "../../EmployeeList/RelatedEmployeeIcon";
import { ICreatedBy } from "../../../../types/global";
import CheckedUserModal from "../../../UI/Modal/CheckedUserModal";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
// import {
//   CustomerFindUniqueQuery,
//   useCustomerFindUniqueQuery,
// } from "../../../../generated/contact";
// import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

type FieldArrayType = {
  related_user_list: ICreatedBy[];
};

const SalesPersonHeader = () => {
  const { control, setValue } = useFormContext<any>();
  const [showSelectEmployees, setShowSelectEmployees] =
    useState<boolean>(false);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState<string[]>([]);
  const [disabled] = useDisable();

  const {
    state: { authUser },
  } = useStateContext();

  // const contactUniqueId = useWatch({
  //   control,
  //   name: "customer_unique_id",
  // });

  const { fields, remove, replace } = useFieldArray<
    FieldArrayType,
    "related_user_list",
    "id"
  >({
    control,
    name: "related_user_list",
  });

  const removeEmployee = (index: number) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  useEffect(() => {
    if (!watchCreatedBy && authUser) {
      setValue("created_by", {
        user_id: authUser?.id,
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, setValue, watchCreatedBy]);

  // const graphQLClientWithHeaderContact =
  //   createGraphQLClientWithMiddleware("crm");

  // const { data: customerData } =
  //   useCustomerFindUniqueQuery<CustomerFindUniqueQuery>(
  //     graphQLClientWithHeaderContact,
  //     {
  //       uniqueInput: {
  //         unique_id: contactUniqueId,
  //       },
  //     },
  //     {
  //       staleTime: Infinity,
  //       cacheTime: 0,
  //       enabled: Boolean(contactUniqueId),
  //     }
  //   );

  const finishEmployeesSelect = (data: any) => {
    if (data) {
      const { unique_id, email, first_name, last_name, img_url } = data;
      const formatEmployee: ICreatedBy = {
        user_unique_id: unique_id,
        email,
        first_name,
        last_name,
        img_url,
      };
      replace([formatEmployee]);
      setEmployeeIds([unique_id]);
      setEmployeeIdsSnapshot([unique_id]);
    }
    setShowSelectEmployees(false);
  };

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  // const selectedUserId =
  //   customerData?.CustomerFindUnique?.sales_person_list &&
  //   customerData?.CustomerFindUnique?.sales_person_list.length > 0 &&
  //   customerData.CustomerFindUnique.sales_person_list[0].unique_id;

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <CustomizedTooltip
        title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
        enterNextDelay={200}
      >
        {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
          <Avatar
            alt={`avatar`}
            src={watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""}
          />
        ) : (
          <CustomizedLetterAvatar
            name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
          />
        )}
      </CustomizedTooltip>

      {(!disabled || fields.length > 0) && (
        <Divider orientation="vertical" flexItem />
      )}

      {!disabled && fields.length === 0 && (
        <CustomizedTooltip title="เพิ่มผู้เกี่ยวข้อง" enterNextDelay={200}>
          <IconButton onClick={openEmployeeTable} color="primary">
            <ControlPointOutlinedIcon />
          </IconButton>
        </CustomizedTooltip>
      )}
      {fields.map((field, index) => (
        <RelatedEmployeeIcon
          isMain={false}
          key={field.id}
          first_name={field.first_name || ""}
          last_name={field.last_name}
          index={index}
          img_url={field.img_url ? field?.img_url[0] : ""}
          remove={removeEmployee}
          disabled={disabled}
        />
      ))}
      <CheckedUserModal
        showSelectUser={showSelectEmployees}
        closeUserTable={closeEmployeeTable}
        finishUsersSelect={finishEmployeesSelect}
        userIds={employeeIds}
        setUserIds={setEmployeeIds}
        userIdsSnapshot={employeeIdsSnapshot}
        setUserIdsSnapshot={setEmployeeIdsSnapshot}
        rowSelection="single"
        isSalesPerson
        // selectedUserId={selectedUserId}
      />
    </Box>
  );
};

export default SalesPersonHeader;
