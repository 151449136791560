import { styled } from "@mui/system";
import { Box, BoxProps, Grid, Typography } from "@mui/material";
import { numberFormatter } from "../../../utils/Formatter/Global";
import { AdditionalDiscountType } from "../../../generated/inventory";

const CustomizedSummary = styled(Box)<BoxProps>(({ bgcolor, paddingY }) => ({
  "&": {
    backgroundColor: bgcolor,
    display: "grid",
    justifyContent: "flex-end",
    textAlign: "right",
    gridGap: "10px",
    gap: "10px",
    gridTemplateColumns:
      "minmax(min-content,max-content) minmax(7rem,max-content) 24px",
    paddingY: paddingY,
    paddingRight: "24px",
  },
}));

interface Props {
  data: any;
  documentType?: string;
}

const LogisticPdfFooter = ({ data, documentType }: Props) => {
  const fontSize = 10.66;

  return (
    <Grid container spacing={1.5} sx={{ breakInside: "avoid" }}>
      <Grid item xs={12}>
        <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
          หมายเหตุ
        </Typography>
        <Typography
          fontSize={fontSize}
          sx={{
            whiteSpace: "pre-line",
            wordBreak: "break-word",
          }}
          lineHeight="11.96px"
        >
          {data.remark || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LogisticPdfFooter;
