import { Box, Divider, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { formatDate } from "../../utils/Date";

type Props = {
  title?: string;
  title2?: string;
  name?: string;
  name2?: string;
  date?: Date | Dayjs;
  documentType?: string;
};

const DeliverySignerBox = ({ title, title2, name, name2, date }: Props) => {
  const fontSize = 10.66;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: fontSize,
          lineHeight: 2,
          textAlign: "-webkit-center",
          maxWidth: "170px",
        }}
      >
        {title}
      </Typography>
      {title2 && (
        <Typography
          sx={{
            fontSize: fontSize,
          }}
        >
          {title2}
        </Typography>
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            height: title2 ? 39.69 : title ? 55 : 76.31,
            gap: 1,
          }}
        >
          <Typography
            sx={{
              mt: 0,
              fontSize: 8.9,
              fontWeight: 600,
              lineHeight: 1.2,
              wordBreak: "break-word",
              width: "-webkit-fill-available",
              textAlign: "start",
            }}
          >
            {name}
          </Typography>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              width: name2 ? 90 : 120,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            height: 18,
            gap: 1,
          }}
        >
          {name2 ? (
            <>
              <Typography
                sx={{
                  mt: 0,
                  fontSize: 8.9,
                  fontWeight: 600,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                  width: "-webkit-fill-available",
                  textAlign: "start",
                }}
              >
                {name2}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 27.33,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 27.33,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 27.33,
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  mt: 0,
                  fontSize: 8.9,
                  fontWeight: 600,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                  width: "-webkit-fill-available",
                  textAlign: "center",
                }}
              >
                ผู้มีอำนาจลงนาม
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DeliverySignerBox;
