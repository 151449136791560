import { ISelectOption } from "../types/global";
import { GraphQLClient } from "graphql-request";
import {
  BranchesFindAllQuery,
  DepartmentsFindAllQuery,
  PositionsFindAllQuery,
  useBranchUserGetByUserIdQuery,
  useBranchesFindAllQuery,
  useDepartmentsFindAllQuery,
  usePositionsFindAllQuery,
} from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

export const useUserOption = (userId?: number) => {
  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: departments, isLoading: isDepartmentsLoading } =
    useDepartmentsFindAllQuery<DepartmentsFindAllQuery>(graphQLClientGeneral);

  const { data: positions, isLoading: isPositionsLoading } =
    usePositionsFindAllQuery<PositionsFindAllQuery>(graphQLClientGeneral);

  const { data: branches, isLoading: isBranchesLoading } =
    useBranchesFindAllQuery<BranchesFindAllQuery>(graphQLClientGeneral);

  const {
    data: branchUser,
    isLoading: isBranchUserLoading,
    refetch: refetchBranchUser,
  } = useBranchUserGetByUserIdQuery(
    graphQLClientGeneral,
    {
      userId,
    },
    {
      enabled: !!userId,
    }
  );

  const branchesOptions: ISelectOption[] =
    branches?.BranchesFindAll?.map((branch) => ({
      label: branch.is_hq ? `${branch.name} (สำนักงานใหญ่)` : branch.name,
      value: branch.id,
    })) || [];

  const departmentsOption: ISelectOption[] =
    departments?.DepartmentsFindAll.map((department) => ({
      label: department.name_th,
      value: department.name_th,
    })) || [];

  const positionsOption: ISelectOption[] =
    positions?.PositionsFindAll.map((position) => ({
      label: position.name_th,
      value: position.name_th,
    })) || [];

  return {
    departmentsOption,
    positionsOption,
    branchesOptions,
    branches: branches?.BranchesFindAll,
    branchUser: branchUser?.BranchUserGetByUserId,
    isBranchUserLoading,
    isOptionLoading:
      isDepartmentsLoading || isPositionsLoading || isBranchesLoading,
    refetchBranchUser,
  };
};
