import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { formatDate, formatDateTimeWithSeconds } from "../../../../utils/Date";
import AgGrid from "../../../UI/AgGrid";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { GOODS_ISSUE_REPORT_VIEW } from "../../../../services/AgGrid/Report/InventoryReportAggrid";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { goodsIssueTypeValueFormatter } from "../../../../utils/Formatter/Inventory/GoodsIssue";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const GoodsIssueReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("date.issued_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "unique_id",
      headerName: t("sentence.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "type",
      headerName: t("sentence.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["sales_order", "purchase_return", "other"],
        valueFormatter: (params: ValueFormatterParams) =>
          goodsIssueTypeValueFormatter(params.value),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        goodsIssueTypeValueFormatter(params.value),
      minWidth: 200,
    },
    {
      field: "reference_unique_id",
      headerName: t("sentence.reference_unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "branch_id",
      headerName: t("branch.unique_id"),
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "branch_unique_id",
      headerName: t("branch.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "warehouse_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "warehouse_unique_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "warehouse_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
    },
    {
      field: "remark",
      headerName: t("sentence.remark") + "หลัก",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "aggrid_status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams) => (
        <CustomizedStatus status={params.value} />
      ),
      filterParams: {
        values: [
          "draft",
          "wait_approve",
          "not_approved",
          "wait_issue",
          "finished",
          "cancelled",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      field: "scanned_date",
      headerName: "วันที่และเวลา",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDateTimeWithSeconds(params.value),
      hide: true,
    },
    {
      field: "item_name",
      headerName: "รหัสสินค้า-ชื่อสินค้า",
      filter: "agTextColumnFilter",
    },
    {
      field: "document_qty",
      headerName: "จำนวนจากเอกสาร",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "posted_qty",
      headerName: "จำนวนนำออกแล้ว",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "pending_qty",
      headerName: "จำนวนรอนำออก",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "qty",
      headerName: "จำนวนที่นำออก",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "uom",
      headerName: t("inventory.unit"),
      filter: "agTextColumnFilter",
    },
    {
      field: "serial_no",
      headerName: "Batch/SN",
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      filter: "agTextColumnFilter",
    },
    {
      field: "source_bin_location_id",
      headerName: t("inventory.locationId"),
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "source_bin_location_name",
      headerName: t("inventory.location"),
      filter: "agTextColumnFilter",
    },
    {
      field: "scanned_by_name",
      headerName: "ผู้สแกน",
      filter: "agTextColumnFilter",
    },
    {
      field: "item_remark",
      headerName: t("sentence.remark") + "รายการ",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "lot_date",
      headerName: t("reports.lot_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "manufactured_date",
      headerName: t("date.manufactured_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      hide: true,
    },
    {
      field: "expired_date",
      headerName: t("date.expired_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      hide: true,
    },
    {
      field: "created_by_name",
      headerName: t("sentence.created_by"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_updated_date",
      headerName: t("date.last_updated_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      hide: true,
    },
  ];

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        lot_date,
        manufactured_date,
        expired_date,
        created_date,
        posted_date,
        scanned_date,
        last_updated_date,
        aggrid_status,
      } = filterModel;

      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        scanned_date: dateFilterModel(scanned_date),
        last_updated_date: dateFilterModel(last_updated_date),
        lot_date: dateFilterModel(lot_date),
        manufactured_date: dateFilterModel(manufactured_date),
        expired_date: dateFilterModel(expired_date),
        aggrid_status: aggrid_status && {
          ...aggrid_status,
          values: aggrid_status.values.some(
            (status: string) => status === "finished"
          )
            ? [
                ...aggrid_status.values,
                "issued",
                "delivering",
                "delivery_fail",
                "delivery_success",
              ]
            : aggrid_status.values,
        },
      };

      try {
        const { GoodsIssueReportView } = await graphQLClient.request(
          GOODS_ISSUE_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: GoodsIssueReportView.results,
          rowCount: GoodsIssueReportView.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default GoodsIssueReportTable;
