import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import useBranchColumnDefs from "./useBranchColumnDefs";
import { BRANCHES_AGGRIDS } from "../../../../services/AgGrid/BranchAgGrid";
import AgGrid from "../../../UI/AgGrid";

type Props = {
  branch_id_list?: number[];
};

const BranchSettingTable = ({ branch_id_list }: Props) => {
  const columnDefs = useBranchColumnDefs({});

  const gridRef = useRef();
  const navigate = useNavigate();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        id: branch_id_list
          ? {
              values: branch_id_list,
              filterType: "set",
            }
          : undefined,
      };
      try {
        const { BranchesFindManyAggrid } = await graphQLClientGeneral.request(
          BRANCHES_AGGRIDS,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: BranchesFindManyAggrid.results as any[],
          rowCount: BranchesFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/setting/branch/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/setting/branch`}
      disabledSidebar
    />
  );
};

export default BranchSettingTable;
