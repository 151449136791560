import { useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { Box, Grid } from "@mui/material";
import ControlledSelect from "../../../../Controller/ControlledSelect";
import { ISelectOption } from "../../../../../types/global";
import { useTranslation } from "react-i18next";
import { usePromotionSelectOption } from "../../../../../hooks/Marketing/use-promotion-select-option";
import { useDisable } from "../../../../../hooks/use-disable";
import LabelInput from "../../../../UI/LabelInput";

const PromotionSpecificGroup = () => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<IPromotion>();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const { t } = useTranslation();
  const [disabled] = useDisable();
  const groupOptions: ISelectOption[] = [
    {
      label: t("setting.contact.customer_group"),
      value: "customer_group",
    },
  ];

  const { customerGroupOptions } = usePromotionSelectOption(false, true);

  return (
    <Box mt={1}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          {!disabled ? (
            <ControlledSelect
              label={"กลุ่ม"}
              control={control}
              name="group"
              options={groupOptions}
              error={Boolean(errors.group)}
              helperText={errors.group?.message}
              disabled={isApplied}
              required
            />
          ) : (
            <LabelInput label={"กลุ่ม"} value={"กลุ่มลูกค้า"} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {!disabled ? (
            <ControlledSelect
              label={"ตัวเลือก"}
              control={control}
              name="customer_group_name"
              options={customerGroupOptions}
              error={Boolean(errors.customer_group_name)}
              helperText={errors.customer_group_name?.message}
              disabled={isApplied}
              required
            />
          ) : (
            <LabelInput
              label={"ตัวเลือก"}
              value={getValues("customer_group_name")}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromotionSpecificGroup;
