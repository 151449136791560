import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  ALL_BRANCHES,
  ALL_DEPARTMENT,
  ALL_POSITION,
} from "../services/AgGrid/UserAgGrid";
import { IGlobalSetting } from "../types/global";
import { IBranch } from "../types/Setting/Branch";

export const useUserAgGrid = () => {
  const departmentFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { DepartmentsFindAll } = await graphQLClient.request(
          ALL_DEPARTMENT
        );
        const salesTypeName = DepartmentsFindAll.map(
          (type: IGlobalSetting) => type.name_th
        );
        params.success(salesTypeName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const positionFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { PositionsFindAll } = await graphQLClient.request(ALL_POSITION);
        const positionName = PositionsFindAll.map(
          (type: IGlobalSetting) => type.name_th
        );
        params.success(positionName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const branchesFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { BranchesFindAll } = await graphQLClient.request(ALL_BRANCHES);
        const branchNames = BranchesFindAll.map(
          (branch: IBranch) => branch.name
        );
        params.success(branchNames);
      } catch (err) {
        params.fail();
      }
    },
  };

  return {
    positionFilterParams,
    departmentFilterParams,
    branchesFilterParams,
  };
};
