import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { FormProvider, useForm } from "react-hook-form";
import ContactInfo from "../../../components/Form/Contact/Info";
import { IContact, IContactAttribute } from "../../../types/Contact/contact";
import {
  contactSchema,
  contactValidation,
} from "../../../components/Form/Contact/schema";
import ContactChannel from "../../../components/Form/Contact/ContactChannel";
import ContactTag from "../../../components/Form/Contact/Tag";
import ContactRemark from "../../../components/Form/Contact/Remark";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useCallback, useEffect, useState } from "react";
import ContactAddress from "../../../components/Form/Contact/Address";
import ContactPersonList from "../../../components/Form/Contact/ContactPersonList";
import ContactFinance from "../../../components/Form/Contact/Finance";
import ContactAttribute from "../../../components/Form/Contact/Attribute";
import ContactAttachment from "../../../components/Form/Contact/Attachment";
import ContactDetail from "../../../components/Form/Contact/Detail";
import { Box, CircularProgress, Stack, IconButton } from "@mui/material";
import { useContactOption } from "../../../hooks/use-contact-option";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CrmModelType,
  CrmUniqueIdGenerateQuery,
  VendorFindUniqueQuery,
  useCrmUniqueIdGenerateQuery,
  useVendorCreateMutation,
  useVendorDeleteMutation,
  useVendorFindUniqueQuery,
  useVendorUpdateMutation,
} from "../../../generated/contact";
import { useSnackbar } from "notistack";
import { useDisable } from "../../../hooks/use-disable";
import {
  vendorCreateFormatter,
  vendorQueryFotmatter,
  vendorUpdateFormatter,
} from "../../../utils/Formatter/Contact";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { useModal } from "../../../hooks/use-modal";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Global";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

const VendorContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(`${pathname}?tab=address`);
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [disabled, setDisabled] = useDisable();

  const navigate = useNavigate();

  const { createActivityLog } = useActivityLog();

  const graphqlClientCrm = createGraphQLClientWithMiddleware("crm");

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
    {
      value: "ลบ",
      disabled: false,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("contact.address.index"),
      path: `${pathname}?tab=address`,
    },
    {
      label: t("contact.contact_person.index"),
      path: `${pathname}?tab=contact_person`,
    },
    {
      label: t("contact.financial.index"),
      path: `${pathname}?tab=financial`,
    },
    {
      label: "ข้อมูล" + t("contact.attribute.index"),
      path: `${pathname}?tab=attribute`,
    },
    {
      label: t("contact.attachment.index"),
      path: `${pathname}?tab=attachment`,
    },
    // {
    //   label: t("contact.member.index"),
    //   path: `${pathname}?tab=member`,
    // },
  ];

  useEffect(() => {
    switch (tab) {
      case "address":
        setCurrentTab(pathname + "?tab=address");
        break;
      case "contact_person":
        setCurrentTab(pathname + "?tab=contact_person");
        break;
      case "financial":
        setCurrentTab(pathname + "?tab=financial");
        break;
      case "attribute":
        setCurrentTab(pathname + "?tab=attribute");
        break;
      case "attachment":
        setCurrentTab(pathname + "?tab=attachment");
        break;
      // case "member":
      //   setCurrentTab(pathname + "?tab=member");
      //   break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const methods = useForm<IContact>({
    defaultValues: contactSchema,
    resolver: yupResolver<any>(contactValidation),
  });

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "address":
        return (
          <ContactAddress addressList={data?.VendorFindUnique?.address_list} />
        );
      case "contact_person":
        return <ContactPersonList />;
      case "financial":
        return <ContactFinance />;
      case "attribute":
        return <ContactAttribute type="vendor" />;
      case "attachment":
        return <ContactAttachment />;
      // case "member":
      //   return <ContactMember type="vendor" />;
      default:
        return null;
    }
  };

  const { handleSubmit, reset, setValue } = methods;

  const { isLoading: isOptionsLoading, attributes } =
    useContactOption("vendor");

  const { data, isLoading, isSuccess } =
    useVendorFindUniqueQuery<VendorFindUniqueQuery>(
      graphqlClientCrm,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.vendor"),
      to: "/contact/vendor",
    },
    {
      name:
        id && Boolean(id)
          ? data && data.VendorFindUnique?.unique_id
            ? data.VendorFindUnique.unique_id
            : ""
          : t(`${t("button.add")}${t("contact.contact_type.vendor")}`),
    },
  ];

  const { refetch: refetchUniqueId } =
    useCrmUniqueIdGenerateQuery<CrmUniqueIdGenerateQuery>(
      graphqlClientCrm,
      {
        modelType: CrmModelType.Vendor,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (!id || button) setValue("unique_id", data?.UniqueIdGenerate ?? "");
    },
    [id, refetchUniqueId, setValue]
  );

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { VendorFindUnique } = data;
      const vendorData = vendorQueryFotmatter({
        ...VendorFindUnique,
      } as IContact);
      if (attributes && attributes.length > 0) {
        const currentAttributes = vendorData.attribute_list;
        let formatAttributes: IContactAttribute[] = attributes.map(
          (attribute) => ({
            name: attribute.name || "",
            short_name: attribute.short_name || "",
            value:
              currentAttributes.find((curr) => curr.name === attribute.name)
                ?.value || "",
          })
        );
        reset({ ...vendorData, attribute_list: formatAttributes });
      } else {
        reset(vendorData);
      }
    }
  }, [attributes, data, isSuccess, reset]);

  useEffect(() => {
    generateUniqueId(false);
  }, [generateUniqueId]);

  const { mutateAsync: create, isLoading: isCreating } =
    useVendorCreateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: update, isLoading: isUpdating } =
    useVendorUpdateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: deleteVendor, isLoading: isDeleting } =
    useVendorDeleteMutation<Error>(graphqlClientCrm);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const vendorCreateHandler = async (data: IContact) => {
    try {
      const formatData = await vendorCreateFormatter(data, id);
      const { VendorCreate } = await create({
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.Vendor,
        reference_id: VendorCreate?.id,
        activity_detail: {},
      });
      enqueueSnackbar("สร้างผู้ขายสำเร็จ", {
        variant: "success",
      });
      navigate(`/contact/vendor/${VendorCreate?.id}?tab=address`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "สร้างผู้ขายไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const vendorUpdateHandler = async (inputData: IContact) => {
    try {
      const { id } = inputData;
      const formatData = await vendorUpdateFormatter(inputData);
      const { VendorUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.Vendor,
        reference_id: VendorUpdate?.id,
        activity_detail: {},
      });
      const updatedData = vendorQueryFotmatter({
        ...VendorUpdate,
      } as IContact);
      reset(updatedData);
      setDisabled(true);
      enqueueSnackbar("แก้ไขผู้ขายสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขผู้ขายไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const vendorDeleteHandler = async () => {
    try {
      const { VendorDelete } = await deleteVendor({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.Delete,
        document_type: ActivityLogDocumentType.Vendor,
        reference_id: VendorDelete?.id,
        activity_detail: {},
      });
      enqueueSnackbar(`ลบ${t("contact.contact_type.vendor")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/contact/Vendor");
    } catch (err) {
      enqueueSnackbar(`ลบ${t("contact.contact_type.vendor")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(vendorDeleteHandler);

  if (
    isOptionsLoading ||
    (id && (isLoading || isCreating || isUpdating || isDeleting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.Vendor}
        />
      )}
      <Box display="flex" maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box marginLeft="auto">
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setShowActivityLog(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              case "ลบ":
                openConfirmationHandler();
                break;

              default:
                break;
            }
          }}
          disabled={!id}
        />
      </Box>
      <FormProvider {...methods}>
        <ContactInfo type="vendor" generateUniqueId={generateUniqueId} />
        <ContactDetail type="vendor" />
        <ContactChannel />
        <ContactTag type="vendor" />
        <ContactRemark />
        <CustomizedBox>
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
          {renderTab(tab)}
        </CustomizedBox>
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? vendorUpdateHandler : vendorCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <ConfirmationModal
        title={`ยืนยันการลบ${t("contact.contact_type.vendor")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default VendorContainer;
