import { useEffect, useMemo, useState } from "react";
import { ISelectOption } from "../../types/global";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../types/Marketing/Promotion";

export const useItemGroupOption = () => {
  const { control } = useFormContext<IPromotion>();
  const fields = useWatch({
    control,
    name: "item_group",
  });
  const { t } = useTranslation();

  const defaultOptions: ISelectOption[] = useMemo(
    () => [
      {
        label: t("setting.inventory.category"),
        value: "main_category",
        disabled: false,
      },
      {
        label: t("setting.inventory.sub_category"),
        value: "sub_category",
        disabled: false,
      },
      {
        label: t("setting.inventory.brand"),
        value: "brand",
        disabled: false,
      },
    ],
    [t]
  );

  const [itemGroupTypeOptions, setItemGroupTypeOptions] =
    useState<ISelectOption[]>(defaultOptions);

  useEffect(() => {
    const allType = new Set(fields.map((item) => item.type));
    const filteredOptions = defaultOptions.map((item) => ({
      ...item,
      disabled: allType.has(String(item.value)) ? true : item.disabled,
    }));
    setItemGroupTypeOptions(filteredOptions);
  }, [defaultOptions, fields]);

  return itemGroupTypeOptions;
};
