import {
  BrandsFindAllQuery,
  CustomerGroupsFindAllQuery,
  MainCategorysFindAllQuery,
  SubCategorysFindAllQuery,
  useBrandsFindAllQuery,
  useCustomerGroupsFindAllQuery,
  useMainCategorysFindAllQuery,
  useSubCategorysFindAllQuery,
} from "../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISelectOption } from "../../types/global";

export const usePromotionSelectOption = (
  isItemGroup: boolean,
  isSpecificGroup: boolean
) => {
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const { data: customerGroupData, isLoading: isCustomerGroupLoading } =
    useCustomerGroupsFindAllQuery<CustomerGroupsFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: isSpecificGroup,
      }
    );

  const { data: categoryData, isLoading: isCategoryLoading } =
    useMainCategorysFindAllQuery<MainCategorysFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: isItemGroup,
      }
    );

  const { data: subCategoryData, isLoading: isSubCategoryLoading } =
    useSubCategorysFindAllQuery<SubCategorysFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: isItemGroup,
      }
    );

  const { data: brandData, isLoading: isBrandLoading } =
    useBrandsFindAllQuery<BrandsFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: isItemGroup,
      }
    );

  const isLoading =
    isCustomerGroupLoading ||
    isCategoryLoading ||
    isSubCategoryLoading ||
    isBrandLoading;

  const customerGroups = customerGroupData?.CustomerGroupsFindAll || [];
  const categories = categoryData?.MainCategorysFindAll || [];
  const subCategories = subCategoryData?.SubCategorysFindAll || [];
  const brands = brandData?.BrandsFindAll || [];

  const customerGroupOptions: ISelectOption[] = customerGroups.map((group) => ({
    label: group.name_th,
    value: group.name_th,
  }));

  const categoryOptions: ISelectOption[] = categories.map((cate) => ({
    label: cate.name_th,
    value: cate.name_th,
  }));

  const subCategoryOptions: ISelectOption[] = subCategories.map((sub) => ({
    label: sub.name_th,
    value: sub.name_th,
  }));

  const brandOptions: ISelectOption[] = brands.map((brand) => ({
    label: brand.name_th,
    value: brand.name_th,
  }));

  return {
    isLoading,
    customerGroupOptions,
    categoryOptions,
    subCategoryOptions,
    brandOptions,
  };
};
