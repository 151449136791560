import { forwardRef, ReactNode } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { SxProps } from "@mui/material";

type Props = {
  sx?: SxProps;
  value: boolean;
  isDisabled?: boolean;
  onChange?: CheckboxProps["onChange"];
  label?: ReactNode;
  error?: boolean;
  testId?: string;
  customIcon?: ReactNode;
};

const CustomizedCheckbox = forwardRef<HTMLInputElement, Props>(
  (
    { sx, value, isDisabled, onChange, label, error, testId, customIcon },
    ref
  ) => {
    return (
      <FormControlLabel
        sx={{ m: 0 }}
        ref={ref}
        control={
          <Checkbox
            data-test-id={testId}
            checked={value}
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            sx={{ ...sx, color: error ? "error.main" : undefined }}
            icon={isDisabled && customIcon ? customIcon : undefined}
          />
        }
        label={label}
        // componentsProps={{ typography: { color: "primary.main" } }}
      />
    );
  }
);

export default CustomizedCheckbox;
