import { gql } from "graphql-request";

export const ALL_POSITION = gql`
  query PositionsFindAll {
    PositionsFindAll {
      id
      unique_id
      name_th
      name_en
      description
    }
  }
`;

export const ALL_DEPARTMENT = gql`
  query DepartmentsFindAll {
    DepartmentsFindAll {
      id
      unique_id
      name_th
      name_en
      description
    }
  }
`;

export const ALL_BRANCHES = gql`
  query BranchesFindAll {
    BranchesFindAll {
      id
      unique_id
      name
      short_name
      zone
      phone
      fax
      status
      is_hq
      created_date
      last_updated_date
      address
    }
  }
`;

export const USERS_AGGRID = gql`
  query UsersView($aggridInput: AnyAggridInput) {
    UsersView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        img_url
        title_name
        first_name
        last_name
        email
        department
        position
        status
        branch_id
        branch_unique_id
        branch_name
        branch_is_hq
        is_sales_employee
        is_have_role
      }
    }
  }
`;

export const ROLES_AGGRID = gql`
  query RolesView($aggridInput: AnyAggridInput) {
    RolesView(aggridInput: $aggridInput) {
      count
      results {
        id
        name
        description
        user_count
      }
    }
  }
`;
