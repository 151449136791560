import React from "react";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../components/Custom/CustomizedButton";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <img src="/static/unauthorize.svg" alt="unauthorize" />
      <Box>
        <Typography variant="h5" color={"primary.main"} align="center">
          ขออภัย การเข้าถึงถูกปฏิเสธ
        </Typography>
        <Typography
          align="center"
          sx={{
            color: "#737373",
          }}
        >
          คุณไม่มีสิทธิ์เข้าถึงข้อมูลนี้
        </Typography>
      </Box>
      <CustomizedButton
        variant="contained"
        title="กลับหน้าหลัก"
        onClick={() => navigate("/landing")}
        size="medium"
      />
    </Box>
  );
};

export default Unauthorized;
