import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

export const useCouponColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "sequence",
        headerName: "",
        filter: false,
        width: 70,
        suppressMenu: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "code",
        headerName: `${t("marketing.coupon.code")}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "status",
        headerName: `${t("marketing.promotion.status")}`,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["active", "used", "inactive", "pending"],
          valueFormatter: (params: ValueFormatterParams) =>
            t(`status.coupon.${params.value}`),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          t(`status.coupon.${params.value}`),
        cellRenderer: ({ value }: { value: string }) => {
          return (
            <CustomizedStatus
              status={value}
              text={t(`status.coupon.${value}`)}
            />
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
        flex: 1,
        minWidth: 200,
      },
    ]);
  }, [t]);

  return columnDefs;
};
