import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import RadioBox from "../RadioBox";
import { useForm, useFormContext } from "react-hook-form";
import { ICouponImporter } from "../../../types/Marketing/Coupon";
import CustomizedButton from "../../Custom/CustomizedButton";
import DocumentDropzoneUI from "../DocumentDropzoneUI";
import {
  useCouponCreateImportMutation,
  useCouponUpdateImportMutation,
} from "../../../generated/marketing";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IPromotion } from "../../../types/Marketing/Promotion";
import { couponImportFormatter } from "../../../utils/Formatter/Marketing/Coupon";
import { useStateContext } from "../../../contexts/auth-context";
import { ICreatedBy, IImporterError } from "../../../types/global";
import { useSnackbar } from "notistack";
import { RefObject, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import ImporterErrorModal from "./ImporterErrorModal";
import { useModal } from "../../../hooks/use-modal";
import { addImporterErrorHandler } from "../../../utils/Importer";
import { couponImportErrorFormatter } from "../../../utils/Formatter/Marketing/Coupon";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  gridRef: RefObject<AgGridReact<any>>;
};

const CouponImporterModal = ({ open, closeModalHandler, gridRef }: Props) => {
  const [error, setError] = useState<IImporterError[]>([]);
  const {
    state: { authUser },
  } = useStateContext();
  const { getValues: getDefaultValues } = useFormContext<IPromotion>();
  const schema: ICouponImporter = {
    import_type: "create",
    files: [],
  };
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { control, setValue, handleSubmit, reset, watch } =
    useForm<ICouponImporter>({
      defaultValues: schema,
    });

  const onClose = () => {
    reset(schema);
    closeModalHandler();
  };

  const watchImportType = watch("import_type");

  const {
    modal: errorModal,
    closeModalHandler: closeErrorModalHandler,
    openModalHandler: openErrorModalHandler,
  } = useModal();

  const graphQLClient = createGraphQLClientWithMiddleware("marketing");

  const { mutateAsync: create, isLoading: isCreating } =
    useCouponCreateImportMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useCouponUpdateImportMutation<Error>(graphQLClient);

  const couponImportHandler = async (data: ICouponImporter) => {
    const createdBy: ICreatedBy = {
      user_unique_id: authUser?.unique_id,
      email: authUser?.email,
      first_name: authUser?.first_name,
      last_name: authUser?.last_name,
    };
    const formatData = await couponImportFormatter(
      data.files,
      createdBy,
      getDefaultValues("state_uuid") || "",
      getDefaultValues("id")
    );
    if (data.import_type === "create") {
      try {
        await create({
          importInput: formatData,
        });
        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
        gridRef?.current?.api.refreshServerSide({ route: [], purge: true });
        onClose();
      } catch (err: any) {
        err.response.errors.forEach((err: any) => {
          if (err.message === "Invalid create") {
            const errors = err.extensions.details;
            const formattedErrors: IImporterError[] = [];
            couponImportErrorFormatter(
              errors,
              formattedErrors,
              addImporterErrorHandler
            );
            setError(formattedErrors);
          } else if (err.message === "duplicate coupon code") {
            const errorValue = err.extensions.details;
            const errorData: IImporterError[] = [];
            addImporterErrorHandler(
              errorData,
              "ข้อมูลซ้ำกับในระบบ",
              `รหัสคูปอง`,
              errorValue
            );
            setError(errorData);
          } else if (err.message === "duplicate code") {
            const errorValue = err.extensions.details;
            const errorData: IImporterError[] = [];
            addImporterErrorHandler(
              errorData,
              "ข้อมูลในไฟล์ซ้ำกัน",
              `รหัสคูปอง`,
              errorValue
            );
            setError(errorData);
          } else if (err.message === "coupon code has reached its limit.") {
            const errorValue = err.extensions.details;
            const errorData: IImporterError[] = [];
            addImporterErrorHandler(
              errorData,
              "จำนวนข้อมูลในไฟล์เกินในระบบ",
              `จำนวนข้อมูลเกิน`,
              [errorValue?.excess]
            );
            setError(errorData);
          } else {
            setError([]);
          }
          openErrorModalHandler();
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        });
      }
    } else {
      try {
        await update({
          importInput: formatData,
        });
        enqueueSnackbar("แก้ไขสำเร็จ", {
          variant: "success",
        });
        gridRef?.current?.api.refreshServerSide({ route: [], purge: true });
        onClose();
      } catch (err: any) {
        err.response.errors.forEach((err: any) => {
          if (err.message === "Invalid update") {
            const errors = err.extensions.details;
            const formattedErrors: IImporterError[] = [];
            couponImportErrorFormatter(
              errors,
              formattedErrors,
              addImporterErrorHandler
            );
            setError(formattedErrors);
          } else if (err.message === "duplicate coupon code") {
            const errorValue = err.extensions.details;
            const errorData: IImporterError[] = [];
            addImporterErrorHandler(
              errorData,
              "ข้อมูลซ้ำกับในระบบ",
              `รหัสคูปอง`,
              errorValue
            );
            setError(errorData);
          } else if (err.message === "duplicate code") {
            const errorValue = err.extensions.details;
            const errorData: IImporterError[] = [];
            addImporterErrorHandler(
              errorData,
              "ข้อมูลในไฟล์ซ้ำกัน",
              `รหัสคูปอง`,
              errorValue
            );
            setError(errorData);
          } else {
            setError([]);
          }
          openErrorModalHandler();
          enqueueSnackbar("แก้ไขไม่สำเร็จ", {
            variant: "error",
          });
        });
      }
    }
  };

  const isMutating = isCreating || isUpdating;

  const downloadTemplateHandler = () => {
    let filePath: string = "";
    if (watchImportType === "create") {
      filePath = `/static/importer/coupon/template_สร้างรหัสคูปองโปรโมชัน.xlsx`;
    } else if (watchImportType === "update") {
      filePath = `/static/importer/coupon/template_แก้ไขรหัสคูปองโปรโมชัน.xlsx`;
    }
    window.open(filePath, "_blank");
  };

  return (
    <>
      <ModalUI
        title={`นำเข้า${t("marketing.coupon.code")}`}
        open={open}
        handleClose={onClose}
        maxWidth={"sm"}
        action={
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={onClose}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={handleSubmit(couponImportHandler)}
            />
          </Box>
        }
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={4}>
            <RadioBox
              label={t("button.create")}
              onClick={() => {
                setValue("import_type", "create");
                setValue("files", []);
              }}
              checked={watchImportType === "create"}
              small
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <RadioBox
              label={t("button.edit")}
              onClick={() => {
                setValue("import_type", "update");
                setValue("files", []);
              }}
              checked={watchImportType === "update"}
              small
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomizedButton
              fullWidth
              variant="outlined"
              title={t("button.download")}
              sx={{
                height: "100%",
              }}
              onClick={downloadTemplateHandler}
            />
          </Grid>
        </Grid>
        <Typography mt={1.5} fontWeight={600}>
          นำเข้าไฟล์
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <DocumentDropzoneUI
              control={control}
              name="files"
              setValue={setValue}
              isModal
              acceptedFileType="xlsx"
              maxSize={1}
              customStyle={{
                backgroundColor: "#F2F2F2",
                borderColor: "#BDBDBD",
                color: "##737373",
              }}
              attachmentCardStyle={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #BDBDBD",
                borderRadius: "4px",
              }}
              hideIcon
              hideCount
            />
          </Grid>
        </Grid>
      </ModalUI>
      <ImporterErrorModal
        open={errorModal}
        closeModalHandler={closeErrorModalHandler}
        data={error}
        type={"coupon"}
        altStyle
      />
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 9999 })}
        open={isMutating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CouponImporterModal;
