import { useCallback, useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";

export const useSalesOrderOption = (status?: string | null) => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      { value: "แก้ไข", disabled: false }, // Edit option
      { value: "คัดลอก", disabled: false }, // Copy option
      { value: "ยกเลิก", disabled: false }, // Cancel option
    ],
    []
  );

  const editOption = defaultOptions[0];
  const copyOption = defaultOptions[1];
  const cancelOption = defaultOptions[2];

  const externalRefOption: IMenuOption = useMemo(
    () => ({ value: t("sales.external_reference_no"), disabled: false }),
    [t]
  );

  // Helper function to check if both update and cancel permissions exist
  const canUpdateAndCancel = useCallback(() => {
    return permissions?.sales_order?.update && permissions?.sales_order?.cancel;
  }, [permissions]);

  // Helper function to get options based on permissions and status
  const getOptionsForStatus = useCallback(
    (status?: string | null) => {
      const isCreate = permissions?.sales_order?.create;

      if (["draft", "not_approved"].includes(status || "")) {
        if (canUpdateAndCancel()) {
          return isCreate ? [copyOption, cancelOption] : [cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (status === "cancelled") {
        return isCreate ? [copyOption] : [];
      }

      if (status === "approved") {
        if (canUpdateAndCancel()) {
          return isCreate
            ? [externalRefOption, ...defaultOptions]
            : [externalRefOption, editOption, cancelOption];
        }
        return isCreate ? [copyOption] : [];
      }

      if (status === "finished") {
        if (canUpdateAndCancel()) {
          return isCreate
            ? [externalRefOption, copyOption]
            : [externalRefOption];
        }
        return isCreate ? [copyOption] : [];
      }

      // Default case when no specific status matches
      return canUpdateAndCancel()
        ? isCreate
          ? defaultOptions
          : [editOption, cancelOption]
        : isCreate
        ? [copyOption]
        : [];
    },
    [
      canUpdateAndCancel,
      copyOption,
      cancelOption,
      externalRefOption,
      editOption,
      defaultOptions,
      permissions,
    ]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    const options = getOptionsForStatus(status);
    setSelectedModifyOptions(options);
  }, [status, getOptionsForStatus]);

  return selectModifyOptions;
};
