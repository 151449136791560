import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedTab from "../../Custom/CustomizedTab";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ISelectOption, ITab } from "../../../types/global";
import BranchPermissionTable from "../../Table/Permission/BranchPermissionTable";
import InventoryPermissionTable from "../../Table/Permission/InventoryPermissionTable";
import ContactPermissionTable from "../../Table/Permission/ContactPermissionTable";
import SalesPermissionTable from "../../Table/Permission/SalesPermissionTable";
import PurchasePermissionTable from "../../Table/Permission/PurchasePermissionTable";
import UserPermissionTable from "../../Table/Permission/UserPermissionTable";
import SettingPermissionTable from "../../Table/Permission/SettingPermissionTable";
import MarketingPermissionTable from "../../Table/Permission/MarketingPermissionTable";
import LogisticPermissionTable from "../../Table/Permission/LogisticPermissionTable";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { IBranchPermission, IUser } from "../../../types/Auth/user";
import {
  RolesFindAllQuery,
  useRolesFindAllQuery,
} from "../../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import {
  defaultPermissionValues,
  mergeAllPermissions,
} from "../../../utils/Formatter/Role";
import BuildIcon from "@mui/icons-material/Build";
import { IPermission } from "../../../types/Auth/role";
import useUserPermission from "../../../hooks/use-user-permission";
import { useStateContext } from "../../../contexts/auth-context";
import { useUserOption } from "../../../hooks/use-user-option";

interface Props {
  setRemoveIds: Dispatch<SetStateAction<number[]>>;
  isSetting: boolean;
}

const UserPermission = ({ setRemoveIds, isSetting }: Props) => {
  const [selectedPermission, setSelectedPermission] =
    useState<Record<string, IPermission[]>>();
  const { control, getValues, reset, watch } = useFormContext<IUser>();
  const { t } = useTranslation();

  const { id } = useParams();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const [disabled] = useDisable();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(
    pathname + "?tab=branch"
  );

  const isCustom = watch("is_custom");
  const roleId = watch("role_id");

  useUserPermission(selectedPermission);

  const { branches, branchUser } = useUserOption(
    isSetting ? authUser?.id : parseInt(id || "")
  );

  const original_branch = useWatch({
    control,
    name: "original_branch",
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "branch_permission",
    keyName: "genId",
  });

  useEffect(() => {
    if (branches && branches.length > 0) {
      const mappedBranchId =
        branchUser?.map((branch) => branch.branch_id) || [];
      const allBranches: IBranchPermission[] = branches.map((branch) => ({
        id: branch.id,
        unique_id: branch.unique_id,
        name: branch.name || "",
        is_hq: branch.is_hq,
        access: "close",
      }));

      const updateAccessStatus = (
        allBranches: IBranchPermission[],
        mappedBranchId: number[]
      ) => {
        return allBranches.map((branch) => {
          if (original_branch === -1) {
            return {
              ...branch,
              access: "open",
            };
          }
          return {
            ...branch,
            access:
              mappedBranchId.includes(branch.id) ||
              branch.id === original_branch
                ? "open"
                : branch.access,
          };
        });
      };

      const formattedBranches: IBranchPermission[] = updateAccessStatus(
        allBranches,
        mappedBranchId as number[]
      );

      const sortByUniqueId = (a: IBranchPermission, b: IBranchPermission) => {
        // Use localeCompare for case-insensitive sorting
        return a.unique_id.localeCompare(b.unique_id);
      };

      const sortedBranches = formattedBranches.sort(sortByUniqueId);

      replace(sortedBranches);
    }
  }, [branchUser, branches, original_branch, replace]);

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data: roleData, isSuccess } =
    useRolesFindAllQuery<RolesFindAllQuery>(graphQLClient);

  const hasRunRef = useRef<boolean>(false);

  useEffect(() => {
    // Run the effect only on the first mount
    if (!hasRunRef.current && isSuccess) {
      hasRunRef.current = true;

      if (roleId) {
        const selectedRole = roleData.RolesFindAll?.find(
          (role) => role.id === roleId
        );
        if (selectedRole) {
          const mergedPermissions = mergeAllPermissions(
            selectedRole.role_permission
          );
          setSelectedPermission(mergedPermissions);
        }
      } else {
        setSelectedPermission(defaultPermissionValues);
      }
    }

    return () => {
      hasRunRef.current = false;
    };
  }, [isSuccess, roleData, roleId]);

  const roleOptions: ISelectOption[] =
    roleData?.RolesFindAll?.map((role) => ({
      id: role.id,
      label: role.name,
      value: role.id,
    })) || [];

  const defaultRoleOptions: ISelectOption[] = [
    {
      id: -1,
      label: "กำหนดเอง",
      value: -1,
    },
    ...roleOptions,
  ];

  const mapRoleValue = (id?: number) => {
    if (id === -1) {
      return "กำหนดเอง";
    } else if (id) {
      return roleData?.RolesFindAll?.find((role) => role.id === id)?.name;
    }
  };

  const tabs: ITab[] = [
    {
      label: t("branch.index"),
      path: `${pathname}?tab=branch`,
    },
    {
      label: t("inventory.index"),
      path: `${pathname}?tab=inventory`,
    },
    {
      label: t("contact.index"),
      path: `${pathname}?tab=contact`,
    },
    {
      label: t("sales.index"),
      path: `${pathname}?tab=sales`,
    },
    {
      label: t("purchase.index"),
      path: `${pathname}?tab=purchase`,
    },
    {
      label: t("user.index"),
      path: `${pathname}?tab=user`,
    },
    {
      label: t("setting.index"),
      path: `${pathname}?tab=setting`,
    },
    {
      label: t("marketing.index"),
      path: `${pathname}?tab=marketing`,
    },
    {
      label: t("logistic.index"),
      path: `${pathname}?tab=logistic`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "branch":
        setCurrentTab(pathname + "?tab=branch");
        break;
      case "inventory":
        setCurrentTab(pathname + "?tab=inventory");
        break;
      case "contact":
        setCurrentTab(pathname + "?tab=contact");
        break;
      case "sales":
        setCurrentTab(pathname + "?tab=sales");
        break;
      case "purchase":
        setCurrentTab(pathname + "?tab=purchase");
        break;
      case "user":
        setCurrentTab(pathname + "?tab=user");
        break;
      case "setting":
        setCurrentTab(pathname + "?tab=setting");
        break;
      case "marketing":
        setCurrentTab(pathname + "?tab=marketing");
        break;
      case "logistic":
        setCurrentTab(pathname + "?tab=logistic");
        break;
      default:
        setCurrentTab(pathname + "?tab=branch");
        break;
    }
  }, [pathname, tab]);

  const renderTable = (tab: string | null) => {
    switch (tab) {
      case "branch":
        return (
          <BranchPermissionTable
            setRemoveIds={setRemoveIds}
            branch_permission={fields}
            replace={replace}
          />
        );
      case "inventory":
        return <InventoryPermissionTable />;
      case "contact":
        return <ContactPermissionTable />;
      case "sales":
        return <SalesPermissionTable />;
      case "purchase":
        return <PurchasePermissionTable />;
      case "user":
        return <UserPermissionTable />;
      case "setting":
        return <SettingPermissionTable />;
      case "marketing":
        return <MarketingPermissionTable />;
      case "logistic":
        return <LogisticPermissionTable />;
      default:
        return (
          <BranchPermissionTable
            setRemoveIds={setRemoveIds}
            branch_permission={fields}
            replace={replace}
          />
        );
    }
  };

  const disabledPermission = disabled || !permissions?.role?.update;

  return (
    <CustomizedBox margin={0}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("user.role.access")}
      </Typography>
      <Grid container spacing={1.5} alignItems={"center"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={disabled ? 12 : 4}
          lg={disabled ? 12 : 4}
          xl={disabled ? 12 : 4}
        >
          {!disabledPermission ? (
            <Controller
              control={control}
              name={"role_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("user.role.permission")}
                  options={defaultRoleOptions}
                  onChange={(_, newValue) => {
                    if (newValue) {
                      field.onChange(newValue.value);
                      const selectedRole = roleData?.RolesFindAll?.find(
                        (role) => role.id === newValue.value
                      );
                      if (selectedRole) {
                        const mergedPermissions = mergeAllPermissions(
                          selectedRole.role_permission
                        );
                        const {
                          inventory_permission,
                          contact_permission,
                          sales_permission,
                          purchase_permission,
                          user_permission,
                          setting_permission,
                          marketing_permission,
                          logistic_permission,
                        } = mergedPermissions;
                        setSelectedPermission(mergedPermissions);
                        reset((prev) => ({
                          ...prev,
                          is_custom: false,
                          inventory_permission,
                          contact_permission,
                          sales_permission,
                          purchase_permission,
                          user_permission,
                          setting_permission,
                          marketing_permission,
                          logistic_permission,
                        }));
                      } else {
                        const {
                          inventory_permission,
                          contact_permission,
                          sales_permission,
                          purchase_permission,
                          user_permission,
                          setting_permission,
                          marketing_permission,
                          logistic_permission,
                        } = defaultPermissionValues;
                        setSelectedPermission(defaultPermissionValues);
                        reset((prev) => ({
                          ...prev,
                          is_custom: false,
                          inventory_permission,
                          contact_permission,
                          sales_permission,
                          purchase_permission,
                          user_permission,
                          setting_permission,
                          marketing_permission,
                          logistic_permission,
                        }));
                      }
                    } else {
                      field.onChange("");
                      const {
                        inventory_permission,
                        contact_permission,
                        sales_permission,
                        purchase_permission,
                        user_permission,
                        setting_permission,
                        marketing_permission,
                        logistic_permission,
                      } = defaultPermissionValues;
                      setSelectedPermission(defaultPermissionValues);
                      reset((prev) => ({
                        ...prev,
                        is_custom: false,
                        inventory_permission,
                        contact_permission,
                        sales_permission,
                        purchase_permission,
                        user_permission,
                        setting_permission,
                        marketing_permission,
                        logistic_permission,
                      }));
                    }
                  }}
                  value={mapRoleValue(field.value)}
                />
              )}
            />
          ) : (
            <Box display={"flex"} gap={0.5} alignItems={"flex-end"}>
              <LabelInput
                label={t("user.role.permission")}
                value={mapRoleValue(getValues("role_id")) || "-"}
              />
              {isCustom && (
                <Box
                  bgcolor={"warning.light"}
                  borderRadius={1}
                  px={1}
                  display={"flex"}
                  gap={0.5}
                  alignItems={"center"}
                  width={"fit-content"}
                  height={"fit-content"}
                >
                  <BuildIcon sx={{ fontSize: 14 }} color="warning" />
                  <Typography color={"warning.main"}>
                    ปรับแต่งเพิ่มเติม
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Grid>
        {isCustom && !disabledPermission && (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box
              bgcolor={"warning.light"}
              borderRadius={1}
              px={1}
              display={"flex"}
              gap={0.5}
              alignItems={"center"}
              width={"fit-content"}
            >
              <BuildIcon sx={{ fontSize: 14 }} color="warning" />
              <Typography color={"warning.main"}>ปรับแต่งเพิ่มเติม</Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomizedTab tabs={tabs} currentTab={currentTab} table />
      {renderTable(tab)}
    </CustomizedBox>
  );
};

export default UserPermission;
