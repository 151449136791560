import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISelectOption } from "../../types/global";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  BranchesFindAllQuery,
  PaymentChannelsFindAllQuery,
  SalesChannelsFindAllQuery,
  SalesTypesFindAllQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
  useBranchesFindAllQuery,
  usePaymentChannelsFindAllQuery,
  useSalesChannelsFindAllQuery,
  useSalesTypesFindAllQuery,
} from "../../generated/general";
import {
  ItemPriceListFindAllQuery,
  useItemPriceListFindAllQuery,
} from "../../generated/inventory";

export const useSalesOption = (type?: string) => {
  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: type || "",
      },
      {
        enabled: !!type,
      }
    );

  const { data: saleTypes, isLoading: isSaleTypesLoading } =
    useSalesTypesFindAllQuery<SalesTypesFindAllQuery>(graphQLClientGeneral);

  const { data: saleChannels, isLoading: isSaleChannelsLoading } =
    useSalesChannelsFindAllQuery<SalesChannelsFindAllQuery>(
      graphQLClientGeneral
    );

  const { data: itemPriceList, isLoading: isItemPriceListLoading } =
    useItemPriceListFindAllQuery<ItemPriceListFindAllQuery>(
      graphQLClientWms,
      {
        filterInput: {
          sales_channel_id: null,
        },
      },
      {
        retry: 1,
      }
    );

  const { data: branches, isLoading: isBranchesLoading } =
    useBranchesFindAllQuery<BranchesFindAllQuery>(graphQLClientGeneral);

  const saleTypesOption: ISelectOption[] =
    saleTypes?.SalesTypesFindAll.map((sale) => ({
      label: sale.name_th,
      value: sale.name_th,
    })) || [];

  const saleChannelsOption: (ISelectOption & {
    item_price_list?: any[] | null;
  })[] =
    saleChannels?.SalesChannelsFindAll.map((sale) => ({
      label: sale.name_th,
      value: sale.id,
      item_price_list: sale.item_price_list,
    })) || [];

  const itemPriceListOption: (ISelectOption & {
    actual_value?: any;
  })[] = [
    {
      label: "ไม่ระบุ",
      value: 0,
    },
    ...(itemPriceList?.ItemPriceListFindAll?.filter(
      (priceList) => priceList?.is_active
    ).map((price) => ({
      label: price?.name || "",
      value: price?.id || 0,
      actual_value: price,
    })) || []),
  ];

  const branchesOptions: ISelectOption[] =
    branches?.BranchesFindAll?.map((branch) => ({
      label: branch.is_hq ? `${branch.name} (สำนักงานใหญ่)` : branch.name,
      value: branch.id,
    })) || [];

  const isPayment = [
    "cash_sales",
    "customer_deposit",
    "sales_invoice",
  ].includes(type || "");

  const { data: paymentChannel, isLoading: isPaymentChannelLoading } =
    usePaymentChannelsFindAllQuery<PaymentChannelsFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: isPayment,
      }
    );

  function uniqueArrayByKey(arr: any[], key: string) {
    const uniqueMap = new Map();

    arr.forEach((item) => {
      const keyValue = item[key];
      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, item);
      }
    });

    return Array.from(uniqueMap.values());
  }

  const paymentChannelPaymentName = uniqueArrayByKey(
    paymentChannel?.PaymentChannelsFindAll.filter((ch) =>
      ch.payment_type?.includes("is_salable")
    ).map((channel) => ({
      label: channel.payment_name,
      value: channel.payment_name,
      branch_id_list: channel.branch_id_list,
    })) || [],
    "value"
  );

  const paymentChannelAccount =
    paymentChannel?.PaymentChannelsFindAll.filter((ch) =>
      ch.payment_type?.includes("is_salable")
    ).map((channel) => ({
      id: channel.id,
      label: channel.unique_id + " - " + channel.name,
      value: channel.id,
      unique_id: channel.unique_id,
      name: channel.name,
      branch_id_list: channel.branch_id_list,
      payment_name: channel.payment_name,
    })) || [];

  return {
    saleChannelsOption,
    saleTypesOption,
    branchesOptions,
    itemPriceListOption,
    paymentChannelPaymentName,
    approvalTemplates:
      approvalTemplates?.ApprovalTemplateFindByDocumentType || [],
    paymentChannelAccount,
    isLoading:
      isSaleChannelsLoading ||
      isSaleTypesLoading ||
      isBranchesLoading ||
      isItemPriceListLoading ||
      (type && isApprovalLoading) ||
      (isPayment && isPaymentChannelLoading),
  };
};
