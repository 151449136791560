import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const useDeliveryOrderOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1]]); // ยกเลิก, คัดลอก
        break;
      case "issued":
        setSelectedModifyOptions([defaultOptions[0]]); // ยกเลิก, คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
