import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { IContactChannel } from "../../../types/global";
import { Box } from "@mui/material";
import CustomizedChips from "../../Custom/CustomizedChips";
import {
  businessTypeFormatter,
  contactChannelFormatter,
} from "../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  TagDocumentType,
  TagsQuery,
  useTagsQuery,
} from "../../../generated/general";

const useContactColumnDefs = (type: "customer" | "vendor") => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useTagsQuery<TagsQuery>(
    graphQLClient,
    {
      findManyInput: {
        document_type: type as TagDocumentType,
      },
    },
    {
      enabled: false,
    }
  );

  const contactChannelAction = (type: string, info: string) => {
    switch (type) {
      case "phone":
      case "fax":
        window.open(`tel:${info}`, "_self");
        return;
      case "email":
        window.open("mailto:" + info, "_self");
        return;
      case "line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: `รหัส${t(`contact.contact_type.${type}`)}`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "business_type",
        headerName: t("contact.info.business_type"),
        filter: "agSetColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return businessTypeFormatter(params.value) || "-";
          else return "-";
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "full_name",
        headerName: `${t("contact.info.brand_name")}/ชื่อ นามสกุล`,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "contact_channel_list",
        headerName: t("contact.contact_channel.index"),
        filter: false,
        cellRenderer: ({ value }: { value: IContactChannel[] }) => {
          return value.map((channel, index) => (
            <Box
              sx={{ display: "inline-block", mr: 1 }}
              key={`${channel.contact_channel_info}-${index}`}
            >
              <CustomizedChips
                onClick={() =>
                  contactChannelAction(
                    channel.contact_channel_type,
                    channel.contact_channel_info || "-"
                  )
                }
                value={`${contactChannelFormatter(
                  channel.contact_channel_type
                )}: ${channel.contact_channel_info || "-"}`}
              />
            </Box>
          ));
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "tag_list",
        headerName: t("contact.tag_list"),
        filter: "agSetColumnFilter",
        sortable: false,
        filterParams: {
          values: async (params: any) => {
            // fetch values from server
            const { data: tagListResult } = await refetch();
            const values = tagListResult?.Tags;
            const formatValues = values?.map((tag) => tag.name);
            params.success(formatValues);
          },
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value)
            return params.value?.map((tag: string) => (
              <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                <CustomizedChips value={`${tag}`} />
              </Box>
            ));
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "status",
        headerName: t("inventory.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ]);
  }, [refetch, t, type]);

  return columnDefs;
};

export default useContactColumnDefs;
