import { GraphQLClient } from "graphql-request";
import {
  SalesInvoiceFindUniqueQuery,
  SalesReferenceDocumentType,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ISalesInvoice } from "../../../types/Sales/salesInvoice";
import { IApprovalTemplate, IReviewedBy } from "../../../types/global";
import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { ISalesItemList } from "../../../types/Sales";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { IUser } from "../../../types/Auth/user";
import { CUSTOMERS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";

export const salesInvoiceCreatePayloadFormatter = (
  data: ISalesInvoice,
  status: string,
  approval_step?: number,
  reviewed_by?: IReviewedBy
) => {
  const {
    item_list,
    customer_details,
    tag_list,
    created_date,
    item_price_list,
    sales_channel,
    payment_list,
    deduction_document_list,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({
      id,
      payment_channel_unique_id_name,
      payment_pre_vat_amount,
      ...otherItem
    }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const formatDeductionList = deduction_document_list.map(
    ({ id, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const { unique_id_name, ...customer } = customer_details;

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    approval_step: approval_step ? 1 : 0,
    payment_list: formatPaymentList,
    deduction_document_list: formatDeductionList,
    reviewer_list: reviewed_by ? [reviewed_by] : [],
  };
  return formatPayload;
};

export const salesInvoiceUpdatePayloadFormatter = (
  data: ISalesInvoice,
  status: string,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  isNotApprove?: boolean,
  reviewed_by?: IReviewedBy
) => {
  const {
    id,
    main_status,
    aggrid_status,
    flag_status,
    customer_details,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    branch,
    sales_channel,
    item_price_list,
    payment_list,
    reviewer_list,
    deduction_document_list,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_details;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({
      id,
      payment_channel_unique_id_name,
      payment_pre_vat_amount,
      ...otherItem
    }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const formatDeductionList = deduction_document_list.map(
    ({ id, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.SalesInvoice,
      reference_unique_id: data.unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatFlag =
    !isNotApprove && (status === "wait_payment" || status === "wait_approve")
      ? flag_status?.filter((flag) => flag !== "not_approved")
      : flag_status;

  const formatPayload = {
    ...otherData,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? data.aggrid_status === "wait_approve" && reviewed_by
        ? !isNotApprove
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
    customer_details: customer,
    item_list: formatItemList,
    payment_list: formatPaymentList,
    deduction_document_list: formatDeductionList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    flag_status: formatFlag,
  };
  return formatPayload;
};

export const salesInvoiceQueryFormatter = async (
  data: SalesInvoiceFindUniqueQuery["SalesInvoiceFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");
  const allItemListUniqueId =
    data.item_list && data.item_list.length > 0
      ? data.item_list.map((item) => item?.item_sku_name)
      : [];

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as ISalesItemList;

      formatItemList.push({
        ...itemType,
        uom_name: itemSkuDetails[foundItemIndex]?.item.stock_uom.name_th,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.total_sku_qty,
      });
    });
  }

  const formatCustomer = {
    ...data.customer_details,
    unique_id_name: `${data.customer_unique_id} - ${data.customer_details.name}`,
  };

  const formatPaymentChannel = data.payment_list?.map(
    ({ payment_date, cheque_date, ...other }) => ({
      ...other,
      payment_channel_unique_id_name: `${other.payment_channel_unique_id} - ${other.payment_channel_name}`,
      payment_date: payment_date ? dayjs(payment_date) : undefined,
      cheque_date: cheque_date ? dayjs(cheque_date) : undefined,
    })
  );

  const formatDeduction = data.deduction_document_list?.map(
    ({ deduction_issue_date, ...other }) => ({
      ...other,
      deduction_issue_date: deduction_issue_date
        ? dayjs(deduction_issue_date)
        : undefined,
    })
  );

  const formatPayload = {
    ...data,
    item_price_list_id: data.item_price_list_id || 0,
    customer_details: formatCustomer,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    due_date: data.due_date ? dayjs(data.due_date) : undefined,
    payment_list: formatPaymentChannel,
    deduction_document_list: formatDeduction,
  } as ISalesInvoice;

  return formatPayload;
};

export const copySalesInvoiceFormatter = async (
  data: ISalesInvoice,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      created_by,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      reference_no,
      branch,
      sales_channel,
      approval_step,
      approver_list,
      reviewer_list,
      deduction_document_list,
      payment_list,
      paid_net_amount,
      paid_total_amount,
      payment_total_amount,
      deduction_total_amount,
      // related_user_list,
      credit_day,
      external_reference_no,
      is_original_print,
      tax_invoice_no,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({ id, unique_id, reference_unique_id, ...otherItemList }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    const graphQLClientContact: GraphQLClient =
      createGraphQLClientWithMiddleware("crm");

    const { CustomersView } = await graphQLClientContact.request(
      CUSTOMERS_AGGRID,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            unique_id: {
              filterType: "text",
              type: "equals",
              filter: otherData.customer_unique_id,
            },
          },
          sortModel: [],
        },
      }
    );

    const contactCreditDay = CustomersView.results[0].credit_day;

    // const formatRelatedUserList =
    //   created_by?.user_unique_id !== currentUser?.unique_id
    //     ? [created_by, ...related_user_list]
    //     : related_user_list;

    return {
      ...otherData,
      item_list: formatItemList,
      copied_id: id,
      copied_unique_id: unique_id,
      payment_list: [],
      deduction_document_list: [],
      paid_total_amount: 0,
      paid_net_amount: 0,
      // related_user_list: formatRelatedUserList,
      credit_day: contactCreditDay,
    };
  }
};
