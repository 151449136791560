import React, { forwardRef } from "react";
import Box from "@mui/material/Box";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomizedTooltip } from "./CustomizedTooltip";
// import CreditTable from "../Table/DocumentTable/CreditTable";
import { IDefaultForm, IRadio } from "../../types/global";

type ExtendedProps = RadioProps &
  RadioGroupProps & {
    radioList: IRadio[];
    control?: IDefaultForm["control"];
    errors?: IDefaultForm["errors"];
  };

const CustomizedRadioGroup = forwardRef<HTMLInputElement, ExtendedProps>(
  ({ onChange, value, row, radioList, name, disabled, sx, ...props }, ref) => {
    return (
      <RadioGroup
        {...props}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={value}
        onChange={onChange}
        row={row}
        ref={ref}
      >
        {radioList &&
          radioList.map((item: IRadio, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: item.tooltip ? "row" : "column",
                alignItems: item.tooltip ? "center" : "flex-start",
              }}
            >
              <FormControlLabel
                value={item.value}
                control={
                  <Radio
                    data-test-id={item.testId}
                    disabled={item.disabled || disabled}
                    sx={sx}
                  />
                }
                label={item.label}
                disabled={item.disabled || disabled}
              />
              {item.tooltip && (
                <CustomizedTooltip title={item.tooltip} isNoMaxWidth>
                  <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
                </CustomizedTooltip>
              )}
            </Box>
          ))}
      </RadioGroup>
    );
  }
);

export default CustomizedRadioGroup;
