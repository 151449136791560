import dayjs from "dayjs";
import { IImporterError } from "../types/global";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { faxReqExp, phoneRegExp } from "./Global";

dayjs.extend(customParseFormat);

export type AddImporterErrorHandler = (
  errorArray: IImporterError[],
  caseString: string,
  nameString: string,
  dataArray: any[],
  isObject?: boolean
) => void;

const isAllNull = (item: any) => {
  if (typeof item === "object" && item !== null) {
    for (const val of Object.values(item)) {
      if (!isAllNull(val)) {
        return false;
      }
    }
  } else if (item !== null) {
    return false;
  }
  return true;
};

// function for traversing nested objects
const getNestedObj = (objKeyStr: string, obj: any, objType: string) => {
  let indexFromLast = objType === "working" ? 2 : 1;
  const objKeySplit = objKeyStr.split(".");
  let objPointer;
  objKeySplit.reduce((acc, curr) => {
    if (objKeySplit.indexOf(curr) === objKeySplit.length - indexFromLast) {
      objPointer = acc[curr];
    }
    return acc[curr];
  }, obj);
  return objPointer;
};

// get object 1 level before the deepest
const nestedWorkingObj = (objKeyStr: string, workingObj: any) => {
  return getNestedObj(objKeyStr, workingObj, "working");
};

// get deepest level object
const nestedCurrentObj = (objKeyStr: string, currentObj: any) => {
  return getNestedObj(objKeyStr, currentObj, "current");
};

export const mapNestedData = (
  keys: string[],
  getValues: any,
  enqueueSnackbar: any
) => {
  const inputData = getValues("file");

  // store keys for nested array
  const arrayKeys = keys.filter((key) => Array.isArray(key));

  const tempData = [...inputData];

  if (tempData && tempData.length > 0) {
    const mainId = Object.keys(tempData[0])[0];

    // loops through everything to convert first elements of each specified keys into array
    for (let i = 0; i < tempData.length; i++) {
      // picking object with id to assign other values to it, skip if there's no ID
      let workingObject = { ...tempData[i] };
      // if (workingObject[mainId] === null && i === 0) {
      //   let errorMessage = `กรุณาระบุ ${mainId}`;
      //   enqueueSnackbar(errorMessage, {
      //     variant: "error",
      //   });
      //   // setLoading(false);
      //   return;
      // }
      if (workingObject[mainId] === null) {
        continue;
      }
      // convert first elements of each keys to array
      for (let j = 0; j < keys.length; j++) {
        if (Array.isArray(keys[j])) {
          continue;
        }
        // if key is for nested objects, call function to traverse the levels
        if (keys[j].includes(".")) {
          const lastLevelKey = keys[j].split(".").pop() as string;
          const nestedWorkObj = nestedWorkingObj(keys[j], workingObject);
          if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
            if (typeof (nestedWorkObj as any)[lastLevelKey] === "object") {
              (nestedWorkObj as any)[lastLevelKey] = [
                { ...(nestedWorkObj as any)[lastLevelKey] },
              ];
            } else {
              (nestedWorkObj as any)[lastLevelKey] = [
                (nestedWorkObj as any)[lastLevelKey],
              ];
            }
          }
        } else {
          // if (workingObject[keys[j]] === null) {
          //   continue;
          // }
          if (!Array.isArray(workingObject[keys[j]])) {
            workingObject[keys[j]] = [workingObject[keys[j]]];
          }
        }
        tempData[i] = workingObject;
      }
    }

    // loops through everything to assign values into their respective arrays
    for (let i = 0; i < tempData.length; i++) {
      let workingObject = { ...tempData[i] };
      let currentObject;
      if (workingObject[mainId] === null) {
        continue;
      }
      for (let j = i + 1; j < tempData.length; j++) {
        currentObject = { ...tempData[j] };
        if (currentObject[mainId] !== null) {
          break;
        }
        for (let k = 0; k < keys.length; k++) {
          if (Array.isArray(keys[k])) {
            continue;
          }
          // assign values for nested objects
          if (keys[k].includes(".")) {
            const lastLevelKey = keys[k].split(".").pop() as string;
            const nestedWorkObj = nestedWorkingObj(keys[k], workingObject);
            const nestedCurrObj = nestedCurrentObj(keys[k], currentObject);
            if (typeof nestedCurrObj === "object") {
              //skips current key for current row if it's value including nested objects are null
              if (isAllNull(nestedCurrObj)) {
                continue;
              }
              if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
                (nestedWorkObj as any)[lastLevelKey] = [
                  { ...(nestedWorkObj as any)[lastLevelKey] },
                ];
              }
              (nestedWorkObj as any)[lastLevelKey] = [
                ...(nestedWorkObj as any)[lastLevelKey],
                nestedCurrObj,
              ];
            } else {
              if (!Array.isArray((nestedWorkObj as any)[lastLevelKey])) {
                (nestedWorkObj as any)[lastLevelKey] = [
                  (nestedWorkObj as any)[lastLevelKey],
                ];
              }
              if (nestedCurrObj === null) {
                continue;
              }
              (nestedWorkObj as any)[lastLevelKey] = [
                ...(nestedWorkObj as any)[lastLevelKey],
                nestedCurrObj,
              ];
            }
            continue;
          }

          if (currentObject[keys[k]] === null) {
            continue;
          }

          if (typeof currentObject[keys[k]] === "object") {
            if (isAllNull(currentObject[keys[k]])) {
              continue;
            }
            if (!Array.isArray(workingObject[keys[k]])) {
              workingObject[keys[k]] = [{ ...workingObject[keys[k]] }];
            } else {
              workingObject[keys[k]] = [
                ...workingObject[keys[k]],
                { ...currentObject[keys[k]] },
              ];
            }
          } else {
            if (currentObject[keys[k]] === null) {
              continue;
            }
            if (!Array.isArray(workingObject[keys[k]])) {
              workingObject[keys[k]] = [workingObject[keys[k]]];
            } else {
              workingObject[keys[k]] = [
                ...workingObject[keys[k]],
                currentObject[keys[k]],
              ];
            }
          }
        }

        tempData[i] = workingObject;
      }
    }
    // map for data with nested array
    for (let i = 0; i < tempData.length; i++) {
      let workingObject = { ...tempData[i] };
      if (workingObject[mainId] === null) {
        continue;
      }
      for (let j = 0; j < arrayKeys.length; j++) {
        let nestedWorkObj = nestedCurrentObj(
          `${arrayKeys[j][0]}`,
          workingObject
        ) as any;
        if (!Array.isArray(nestedWorkObj)) {
          continue;
        }
        let indexToAssignTo = 0;
        if (!Array.isArray(nestedWorkObj[indexToAssignTo][arrayKeys[j][1]])) {
          if (
            typeof nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] === "object"
          ) {
            nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
              { ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] },
            ];
          } else {
            nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
              nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
            ];
          }
        }
        for (let k = 0; k < nestedWorkObj.length; k++) {
          if (
            nestedWorkObj[k] &&
            Object.values(nestedWorkObj[k]).every(
              (item) => item === null || typeof item === "object"
            ) &&
            !isAllNull(nestedWorkObj[k][arrayKeys[j][1]])
          ) {
            if (typeof nestedWorkObj[k][arrayKeys[j][1]] === "object") {
              nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
                { ...nestedWorkObj[k][arrayKeys[j][1]] },
              ];
            } else {
              nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
                nestedWorkObj[k][arrayKeys[j][1]],
              ];
            }
            nestedWorkObj.splice(k, 1);
            // step back 1 index as current index is gone now
            k--;
          } else {
            if (!Array.isArray(nestedWorkObj[k][arrayKeys[j][1]])) {
              nestedWorkObj[k][arrayKeys[j][1]] = [
                { ...nestedWorkObj[k][arrayKeys[j][1]] },
              ];
            }
            indexToAssignTo = k;
          }
        }
      }
    }
    const filteredTempData = tempData.filter((data) => data[mainId] !== null);
    return filteredTempData;
  } else {
    return [];
  }
};

//extract data from data
interface Lists<T> {
  [key: string]: T[];
}

export const extractLists = <T>(data: T[], keys: string[]): Lists<T> => {
  return data.reduce((lists: Lists<T>, item: T) => {
    keys.forEach((key) => {
      const value = (item as any)[key];
      if (value !== undefined) {
        if (!lists[key]) {
          lists[key] = [];
        }
        lists[key].push(value);
      }
    });
    return lists;
  }, {} as Lists<T>);
};

//Duplicates Unique ID
export const findDuplicatesWithList = (array: any[]) => {
  const seen = new Set();
  const duplicates = [];

  for (const item of array) {
    if (seen.has(item)) {
      duplicates.push(item); // Add duplicate to the list
    } else {
      seen.add(item);
    }
  }

  return duplicates;
};

//None business type
export const findNoneBusinessType = (array: any[]) => {
  const businessType = ["นิติบุคคล", "บุคคลธรรมดา"];
  const resultArray = array.filter((value) => !businessType.includes(value));
  return resultArray;
};

//None business type detail
export const findNoneBusinessTypeDetail = (array: any[]) => {
  const businessTypeDetail = [
    "บริษัท",
    "บริษัทมหาชนจำกัด",
    "ห้างหุ้นส่วนจำกัด",
    "ห้างหุ้นส่วนสามัญนิติบุคคล",
    "สมาคม",
    "อื่นๆ",
    "บุคคลธรรมดา",
    "ห้างหุ้นส่วนสามัญ",
    "ร้านค้า",
    "คณะบุคคล",
  ];
  const resultArray = array.filter(
    (value) => !businessTypeDetail.includes(value)
  );
  return resultArray;
};

export const findNoneContactChannel = (array: any[]) => {
  const contactChannel = [
    "เบอร์โทรศัพท์",
    "Email",
    "โทรสาร",
    "Line",
    "Facebook",
    "Website",
    "Instagram",
    "มือถือ",
    "อื่นๆ",
  ];
  const resultArray = array.filter((value) => !contactChannel.includes(value));
  return resultArray;
};

export const findMissing = (array: any[], array_value: any[]) => {
  const resultArray = array.filter((value) => !array_value.includes(value));
  return resultArray;
};

export const findNonePrice = (array: any[]) => {
  const resultArray = array.filter((value) => {
    const formatValue =
      typeof value === "string"
        ? parseFloat(value.replace(",", ""))
        : parseFloat(parseFloat(value).toFixed(2));
    if (isNaN(formatValue) || formatValue < 0 || value.split(".").length > 2)
      return true;
    return false;
  });
  return resultArray;
};

export const findNoneTitleName = (array: any[]) => {
  const titleName = ["นาย", "นาง", "นางสาว", "คุณ", "ไม่มี"];
  const resultArray = array.filter((value) => !titleName.includes(value));
  return resultArray;
};

export const findNoneGender = (array: any[]) => {
  const gender = ["ชาย", "หญิง", "อื่นๆ"];
  const resultArray = array.filter((value) => !gender.includes(value));
  return resultArray;
};

export const findNoneStatus = (array: any[]) => {
  const status = ["ใช้งาน", "หยุดใช้งาน"];
  const resultArray = array.filter((value) => !status.includes(value));
  return resultArray;
};

export const findNoneAble = (array: any[]) => {
  const able = ["ได้", "ไม่ได้"];
  const resultArray = array.filter((value) => !able.includes(value));
  return resultArray;
};

export const findNoneNumber = (array: any[]) => {
  const resultArray = array.filter(
    (value) => typeof value !== "number" && isNaN(parseFloat(value))
  );
  return resultArray;
};

export const findNoneVatType = (array: any[]) => {
  const vatType = ["ไม่มี", "0%", "7%"];
  const resultArray = array.filter((value) => !vatType.includes(value));
  return resultArray;
};

export const findNoneSourceCountry = (array: any[]) => {
  const sourceCountry = ["ประเทศไทย", "ประเทศอื่นๆ"];
  const resultArray = array.filter((value) => !sourceCountry.includes(value));
  return resultArray;
};

export const findNoneBoolean = (array: any[]) => {
  const boolean = ["ใช่", "ไม่ใช่"];
  const resultArray = array.filter((value) => !boolean.includes(value));
  return resultArray;
};

export const findNoneTracability = (array: any[]) => {
  const tracability = [
    "ติดตามLot/Serial Number",
    "ติดตาม Lot/Serial Number",
    "ติดตามแบบทั่วไป",
  ];
  const resultArray = array.filter((value) => !tracability.includes(value));
  return resultArray;
};

export const findNotPositiveNumber = (array: any[]) => {
  const resultArray = array.filter((value) => parseFloat(value) < 0);
  return resultArray;
};

export const isNotValidDate = (array: any[]) => {
  const isNotValidArray = array.filter(
    (value) => !dayjs(value, "YYYY-MM-DD", true).isValid()
  );
  return isNotValidArray;
};

export const isNotValidPhone = (array: any[]) => {
  const isNotValidArray = array.filter((value) => !phoneRegExp.test(value));
  return isNotValidArray;
};

export const isNotValidFax = (array: any[]) => {
  const isNotValidFax = array.filter((value) => !faxReqExp.test(value));
  return isNotValidFax;
};

export const addImporterErrorHandler = (
  errorArray: IImporterError[],
  caseString: string,
  nameString: string,
  dataArray: any[],
  isObject?: boolean
): void => {
  if (dataArray.length > 0) {
    dataArray.forEach((item) => {
      errorArray.push({
        case: caseString,
        name: isObject ? item.name : nameString,
        value: isObject ? item.value : item,
        description: isObject ? item.description : "",
      });
    });
  }
};

interface MismatchedValue {
  key: string;
  actualValue: any;
}

export const findMismatchedValues = (
  objectsArray: Record<string, any>[],
  keyDataTypeMap: Record<string, string>
): MismatchedValue[] => {
  const mismatchedValues: MismatchedValue[] = [];

  // Check if the array is not empty
  if (objectsArray.length === 0) {
    return mismatchedValues;
  }

  // Iterate over each object in the array
  objectsArray.forEach((obj) => {
    // Check each key in the object
    Object.keys(obj).forEach((key) => {
      const expectedDataType = keyDataTypeMap[key] || "string";
      const actualValue = obj[key];

      // Check if the actual value does not match the expected data type
      if (
        (expectedDataType !== "string" || actualValue !== "") &&
        typeof actualValue !== expectedDataType
      ) {
        mismatchedValues.push({ key, actualValue });
      }
    });
  });

  return mismatchedValues;
};
