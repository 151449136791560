import { useFieldArray, useFormContext } from "react-hook-form";
import { IUser } from "../../../types/Auth/user";
import PermissionTable from "./PermissionTable";
import { purchasePermissionValue } from "../../../utils/Permission/Purchase";

const PurchasePermissionTable = () => {
  const { control, watch } = useFormContext<IUser>();
  const { fields, replace } = useFieldArray({
    control,
    name: "purchase_permission",
    keyName: "genId",
  });

  const watchFieldArray = watch("purchase_permission");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...(watchFieldArray &&
        watchFieldArray.length > 0 &&
        watchFieldArray[index]),
    };
  });

  const resetArrayHandler = () => {
    replace(purchasePermissionValue);
  };

  return (
    <PermissionTable
      module={"purchase_permission"}
      controlledFields={controlledFields}
      resetArrayHandler={resetArrayHandler}
    />
  );
};

export default PurchasePermissionTable;
