import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePromotionItemListHeader = (disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        width: 30,
        align: "center",
      },
      {
        thaiLabel: "ชื่อสินค้า",
        width: 520,
        align: "left",
      },
      {
        thaiLabel: "จำนวน",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 150,
        align: "left",
      },
    ];

    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 30,
          align: "left",
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled]);

  return headers;
};
