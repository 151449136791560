import { Box, Typography } from "@mui/material";
import {
  BranchesFindAllQuery,
  BranchFindUniqueQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
  useBranchesFindAllQuery,
  useBranchFindUniqueQuery,
  useWarehousesFindAllQuery,
} from "../../generated/general";
import {
  usePurchaseOrderUniqueIdQueryQuery,
  usePurchaseReturnUniqueIdQueryQuery,
} from "../../generated/purchase";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISelectOption } from "../../types/global";

const useInventoryControl = (
  branch_id?: number,
  warehouse_id?: number,
  type?: string
) => {
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");
  const graphQLClientPurchase = createGraphQLClientWithMiddleware("purchase");
  const { data: branches, isLoading: isBranchesLoading } =
    useBranchesFindAllQuery<BranchesFindAllQuery>(graphQLClientGeneral);

  const { data: branch, isLoading: isBranchLoading } =
    useBranchFindUniqueQuery<BranchFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: branch_id,
        },
      },
      {
        enabled: !!branch_id,
      }
    );

  const { data: warehouses, isLoading: isWarehousesLoading } =
    useWarehousesFindAllQuery(
      graphQLClientGeneral,
      {
        whereInput: {
          branch_id,
        },
      },
      {
        enabled: !!branch_id,
      }
    );

  const { data: purchaseOrder, isLoading: isPurchaseOrderLoading } =
    usePurchaseOrderUniqueIdQueryQuery(
      graphQLClientPurchase,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            status: {
              filterType: "set",
              values: ["approved", "partially_imported"],
            },
          },
          sortModel: [
            {
              sort: "desc",
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: type === "purchase_order",
      }
    );

  const { data: purchaseReturn, isLoading: isPurchaseReturnLoading } =
    usePurchaseReturnUniqueIdQueryQuery(
      graphQLClientPurchase,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            status: {
              filterType: "set",
              values: ["approved"],
            },
          },
          sortModel: [
            {
              sort: "desc",
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: type === "purchase_return",
      }
    );

  const branchesOptions: ISelectOption[] =
    branches?.BranchesFindAll?.map((branch) => ({
      id: branch.id,
      label: branch.is_hq ? `${branch.name} (สำนักงานใหญ่)` : branch.name,
      value: branch.id,
    })) || [];

  const warehousesOptions: ISelectOption[] =
    warehouses?.WarehousesFindAll.map((warehouse) => ({
      id: warehouse.id,
      label: warehouse.name,
      value: warehouse.id,
      badge: warehouse.is_consignment ? (
        <Box bgcolor={"primary.light"} borderRadius={1} px={1}>
          <Typography color={"primary.main"}>ฝากขาย</Typography>
        </Box>
      ) : undefined,
    })) || [];

  const renderBranchOptions = (id: number) =>
    branches?.BranchesFindAll?.find((branch) => branch.id === id)?.name;

  const renderWarehousesOptions = (id: number) =>
    warehouses?.WarehousesFindAll.find((warehouse) => warehouse.id === id)
      ?.name;

  const foundWarehouse = warehouses?.WarehousesFindAll.find(
    (warehouse) =>
      warehouse?.id ===
      (typeof warehouse_id === "string" ? parseInt(warehouse_id) : warehouse_id)
  );

  const isConsignment = foundWarehouse?.is_consignment;

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.warehouse_level_2_list?.forEach((level2) => {
      level2?.warehouse_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = allBinLocation.map((bin) => ({
    label: bin.name,
    value: bin.id,
  }));

  const purchaseOrderOptions: ISelectOption[] =
    purchaseOrder?.PurchaseOrderViewsAggrid?.results.map((po) => ({
      id: po.id,
      label: po.unique_id,
      value: po.unique_id,
    })) || [];

  const purchaseReturnOptions: ISelectOption[] =
    purchaseReturn?.PurchaseReturnViewsAggrid?.results
      .filter((rs) =>
        rs.item_list?.some((item) => item.qty > (item.qty_issued || 0))
      )
      .map((rs) => ({
        id: rs.id,
        label: rs.unique_id,
        value: rs.unique_id,
      })) || [];

  const { data: approvalTemplates } =
    useApprovalTemplateFindByDocumentTypeQuery(
      graphQLClientGeneral,
      {
        documentType: type || "",
      },
      {
        enabled: !!type,
      }
    );

  return {
    branch,
    warehousesOptions,
    branchesOptions,
    warehouses: warehouses?.WarehousesFindAll,
    renderBranchOptions,
    renderWarehousesOptions,
    purchaseOrderOptions,
    isPurchaseOrderLoading,
    purchaseReturnOptions,
    isPurchaseReturnLoading,
    allBinLocation,
    binLocationOptions,
    isOptionsLoading:
      branch_id &&
      (isBranchesLoading || isBranchLoading || isWarehousesLoading
        ? true
        : false),
    approvalTemplates:
      approvalTemplates?.ApprovalTemplateFindByDocumentType || [],
    isConsignment,
  };
};

export default useInventoryControl;
