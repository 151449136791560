import { IPermission } from "../../types/Auth/role";

export const inventoryPermissionValue: IPermission[] = [
  {
    variableName: "inventory",
    name: "คลังสินค้า",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "item",
    name: "สินค้า",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "goods_receive",
    name: "นำเข้า",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "goods_return",
    name: "รับคืน",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "goods_issue",
    name: "นำออก",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "goods_transfer",
    name: "โอนย้าย",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "goods_adjust",
    name: "ปรับปรุง",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "price_list",
    name: "รายการราคา",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "inventory_report",
    name: "รายงาน​",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "inventory_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
