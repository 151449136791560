import * as Yup from "yup";
import dayjs from "dayjs";
import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";
import { Tracability } from "../../../../generated/general";

export const goodsTransferSchema: IGoodsTransfer = {
  unique_id: "",
  source_branch_id: undefined,
  source_warehouse_id: undefined,
  destination_branch_id: undefined,
  destination_warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: null,
  flag_status: [],
  main_status: "",
  consignment_bin_location_id: undefined,
  is_consignment: false,
};

const goodsTransferTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่โอนย้าย")
    .required("กรุณาระบุจำนวนที่โอนย้าย")
    .when(["stock_qty", "destination_scanned_by"], (value: any, schema) => {
      if (value[1]) return schema;

      return (
        (value[0] || value[0] === 0) &&
        schema.max(value[0], "กรุณาระบุจำนวนโอนย้ายไม่เกินจำนวนในคลัง")
      );
    }),
});

const goodsTransferSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .when("tracability", (tracability: any) =>
      tracability && tracability[0] === Tracability.Normal
        ? Yup.number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .min(1, "กรุณาระบุจำนวนที่โอนย้าย")
            .required("กรุณาระบุจำนวนที่โอนย้าย")
        : Yup.number().nullable()
    )
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนโอนย้ายไม่เกินจำนวนในคลัง")
    ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsTransferTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsTransferValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  source_branch_id: Yup.string().required("กรุณาระบุสาขาต้นทาง"),
  source_warehouse_id: Yup.string().required("กรุณาระบุคลังต้นทาง"),
  destination_branch_id: Yup.string().required("กรุณาระบุสาขาปลายทาง"),
  destination_warehouse_id: Yup.string().required("กรุณาระบุคลังปลายทาง"),
  trace_entry_list: Yup.array()
    .of(goodsTransferSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
  is_consignment: Yup.boolean(),
  consignment_bin_location_id: Yup.string().when("is_consignment", {
    is: true,
    then: (schema) => schema.required("กรุณาระบุ"),
    otherwise: (schema) => schema.nullable(),
  }),
});
