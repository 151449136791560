import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const useSalesItemListHeader = (
  documentType: string,
  disabled: boolean
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 320,
        align: "left",
        required: true,
      },
      {
        thaiLabel: documentType === "sales_return" ? "จำนวนคืน" : "จำนวน",
        label: documentType === "sales_return" ? "Return Qty" : "Qty",
        width: 100,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        required: true,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
        align: "right",
      },

      {
        thaiLabel: "ภาษี (%)",
        label: "Vat",
        width: 100,
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
        align: "right",
      },
      // {
      //   thaiLabel: "ภาษีหัก ณ ที่จ่าย (%)",
      //   label: "Withholding Tax (%)",
      //   width: 150,
      // },
      {
        thaiLabel: "จำนวนคงคลัง",
        label: "Stock Qty",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "หมายเหตุสินค้า",
        label: "Product Remark",
        width: 150,
        align: "left",
      },
    ];

    if (!disabled) {
      if (documentType === "sales_return") {
        const salesReturnHeaders = [...defaultHeaders];
        salesReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนขาย",
          label: "Sales Qty",
          width: 100,
          align: "right",
        });
        // arrayMove(salesReturnHeaders, 10, 5);
        setHeaders([
          ...salesReturnHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else if (documentType === "cash_sales") {
        setHeaders([
          ...defaultHeaders.slice(0, 5),
          {
            thaiLabel: "ส่วนลด/หน่วย",
            label: "Discount",
            width: 150,
            align: "right",
          },
          {
            thaiLabel: "ส่วนลดโปรโมชัน",
            label: "Promotion Discount",
            width: 150,
            align: "right",
          },
          ...defaultHeaders.slice(6),
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (documentType === "sales_return") {
        const salesReturnHeaders = [...defaultHeaders];
        salesReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนขาย",
          label: "Sales Qty",
          width: 100,
          align: "right",
        });
        // arrayMove(salesReturnHeaders, 10, 5);
        setHeaders(salesReturnHeaders);
      } else if (documentType === "sales_order") {
        setHeaders([
          ...defaultHeaders,
          {
            thaiLabel: "รอส่ง",
            label: "Qty. to Ship",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "ส่งแล้ว",
            label: "Qty. Shipped",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "แจ้งหนี้แล้ว",
            label: "Qty. Invoiced",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "คืนของ",
            label: "Qty. Returned",
            width: 100,
            align: "right",
          },
        ]);
      } else if (documentType === "cash_sales") {
        setHeaders([
          ...defaultHeaders.slice(0, 5),
          {
            thaiLabel: "ส่วนลด/หน่วย",
            label: "Discount",
            width: 150,
            align: "right",
          },
          {
            thaiLabel: "ส่วนลดโปรโมชัน",
            label: "Promotion Discount",
            width: 150,
            align: "right",
          },
          ...defaultHeaders.slice(6),
          {
            thaiLabel: "รอส่ง",
            label: "Qty. to Ship",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "ส่งแล้ว",
            label: "Qty. Shipped",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "คืนของ",
            label: "Qty. Returned",
            width: 100,
            align: "right",
          },
        ]);
      } else {
        setHeaders(defaultHeaders);
      }
    }
  }, [disabled, documentType]);

  return headers;
};
