import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import { useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import { Box, Typography } from "@mui/material";
import { IRadio } from "../../../../types/global";
import { useDisable } from "../../../../hooks/use-disable";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

const PromotionSetting = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });

  const isApplied = Boolean(watchIsApplied);

  const settingTypeList: IRadio[] = [
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.setting_type.all_promotions")}
          </Typography>
          <CustomizedTooltip
            title={t("marketing.promotion.tooltip.setting_type.all_promotions")}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "all_promotions",
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.setting_type.single_promotion")}
          </Typography>
          <CustomizedTooltip
            title={t(
              "marketing.promotion.tooltip.setting_type.single_promotion"
            )}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "single_promotion",
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.setting_type.single_category_promotion")}
          </Typography>
          <CustomizedTooltip
            title={t(
              "marketing.promotion.tooltip.setting_type.single_category_promotion"
            )}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "single_category_promotion",
    },
  ];
  return (
    <CustomizedBox margin={0}>
      <Box display={"flex"} mb={1} gap={0.3}>
        <Typography fontWeight={600}>
          {t("marketing.promotion.setting_type.index")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>
      <ControlledRadioGroup
        control={control}
        name="type"
        radioLists={settingTypeList}
        row
        disabled={disabled || isApplied}
      />
      <Typography fontWeight={600} my={1}>
        {t("marketing.promotion.use_auto_sales.index")}
      </Typography>
      <ControlledCheckbox
        control={control}
        name="use_auto"
        label={t("marketing.promotion.use_auto_sales.select")}
        disabled={disabled || isApplied}
      />
    </CustomizedBox>
  );
};

export default PromotionSetting;
