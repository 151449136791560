import { Box, Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDisable } from "../../../../hooks/use-disable";
import { IRadio } from "../../../../types/global";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PromotionUsage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const usageLimitTypeList: IRadio[] = [
    {
      label: t("marketing.promotion.usage_limit.no_limit"),
      value: "no_limit",
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.usage_limit.limited")}
          </Typography>
          <CustomizedTooltip
            title={t("marketing.promotion.tooltip.usage_limit")}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "limited",
    },
  ];

  const usageLimitType = useWatch({ control, name: "usage_limit_type" });
  const totalCouponusage = useWatch({ control, name: "total_coupon_usage" });

  return (
    <CustomizedBox margin={0}>
      <Box display="flex" mb={1} gap={0.3}>
        <Typography
          fontWeight={600}
          color={Boolean(errors.usage_limit) ? "error.main" : undefined}
        >
          {t("marketing.promotion.usage_limit.type")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>

      <ControlledRadioGroup
        control={control}
        name="usage_limit_type"
        radioLists={usageLimitTypeList}
        row
        disabled={disabled || isApplied}
        onChange={(_, value) => {
          if (value === "no_limit") {
            setValue("usage_limit", "");
          }
        }}
      />
      {usageLimitType && usageLimitType === "limited" && (
        <Box my={1}>
          <Grid
            container
            spacing={1.5}
            sx={
              disabled
                ? {
                    "& > .MuiGrid-item": {
                      paddingLeft: "0 !important",
                    },
                  }
                : {}
            }
          >
            <Grid item xs={12} sm={12} md={6}>
              <ControlledNumberTextField
                fullWidth
                label={t("marketing.promotion.usage_limit.qty")}
                control={control}
                name="usage_limit"
                error={Boolean(errors.usage_limit)}
                helperText={errors.usage_limit?.message}
                textAlign="left"
                decimalScale={0}
                required
                viewMode={disabled}
                disabled={isApplied}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {id && (
        <Typography color={"#737373"}>{`จำนวนคูปองที่ใช้ไปแล้ว ${
          totalCouponusage ?? 0
        } คูปอง`}</Typography>
      )}
    </CustomizedBox>
  );
};

export default PromotionUsage;
