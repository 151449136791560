import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../utils/Date";
import {
  numberFormatter,
  statusValueFormatter,
} from "../../../../utils/Formatter/Global";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

import { usePurchaseAgGrid } from "../../../../hooks/Purchase/use-purchase-aggrid";

export const usePurchaseReturnColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  const { purchaseUserFilterParams, branchFilterParams } =
    usePurchaseAgGrid("request");

  useEffect(() => {
    setColumnDef([
      {
        field: "unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "delivery_date",
        headerName: t("date.delivery_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: [
            "draft",
            "wait_approve",
            "not_approved",
            "approved",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "branch_unique_id_name",
        headerName: t("branch.index"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: branchFilterParams,
      },
      {
        field: "vendor_unique_id",
        headerName: t("purchase.vendor_unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vendor_name",
        headerName: t("purchase.vendor_name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_by_object",
        headerName: t("sentence.created_by"),
        filter: "agSetColumnFilter",
        filterParams: purchaseUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "related_user_object_list",
        headerName: t("sales.employee_list"),
        filter: "agSetColumnFilter",
        filterParams: purchaseUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "total_amount",
        headerName: t("purchase.total_amount_table"),
        filter: "agNumberColumnFilter",
        hide: true,
        valueFormatter: (params: ValueFormatterParams<any, any>) =>
          numberFormatter(params.value),
        minWidth: 200,
        flex: 1,
      },
      {
        field: "reference_document_list",
        headerName: t("purchase.reference_unique_id"),
        filter: "agTextColumnFilter",
        hide: true,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "reference_no",
        headerName: t("purchase.reference_no"),
        filter: "agTextColumnFilter",
        hide: true,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "reason_to_return",
        headerName: t("purchase.return.reason_to_return"),
        filter: "agTextColumnFilter",
        hide: true,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_date",
        headerName: t("date.created_date"),
        sort: "desc",
        hide: true,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "last_updated_date",
        headerName: t("date.last_updated_date"),
        hide: true,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return columnDef;
};
