import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import { useFormContext } from "react-hook-form";
import {
  defaultPermissionHeader,
  defaultViewOption,
} from "../../../utils/Global";
import { IPermission } from "../../../types/Auth/role";
import { ISelectOption } from "../../../types/global";
import { useDisable } from "../../../hooks/use-disable";
import { useStateContext } from "../../../contexts/auth-context";
import { useLocation } from "react-router-dom";

type Props = {
  module:
    | "inventory_permission"
    | "contact_permission"
    | "sales_permission"
    | "purchase_permission"
    | "user_permission"
    | "setting_permission"
    | "marketing_permission"
    | "logistic_permission";
  controlledFields: (IPermission & {
    genId: string;
  })[];
  resetArrayHandler: () => void;
};

const CheckBoxBlankIcon = () => (
  <SvgIcon>
    <svg focusable="false" aria-hidden="true" data-testid="CheckBoxBlankIcon">
      <path
        d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
        fill="#E2E2E2"
      ></path>
    </svg>
  </SvgIcon>
);

const PermissionTable = ({
  module,
  controlledFields,
  resetArrayHandler,
}: Props) => {
  const {
    state: { permissions },
  } = useStateContext();

  const { pathname } = useLocation();
  const { control, setValue, getValues, watch } = useFormContext();
  const [disabled] = useDisable();
  const roleId = watch("role_id");

  const disabledPermission =
    disabled ||
    !permissions?.role?.update ||
    (!pathname.includes("role") && !roleId);

  const defaultViewSelfOption: ISelectOption[] = [
    defaultViewOption[0],
    {
      label: "เฉพาะของตนเอง",
      value: "open_self",
    },
    defaultViewOption[1],
  ];
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{
            bgcolor: (theme) => theme.palette.primary.light,
          }}
        >
          <TableRow>
            {defaultPermissionHeader.map((header, index) => (
              <TableCell
                align={header.align ? header.align : "center"}
                key={index}
                sx={{
                  p: 1.5,
                }}
                width={header.width}
              >
                <Typography fontWeight={600} color={"primary.main"}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {controlledFields.map((permission, index) => (
            <TableRow sx={{ height: 56 }} key={permission.genId}>
              <TableCell sx={{ p: 1 }}>
                <Typography>{permission.name}</Typography>
              </TableCell>
              <TableCell sx={{ p: 1 }} align="center">
                {!disabledPermission ? (
                  <Box width={150} justifySelf={"center"}>
                    <ControlledSelect
                      control={control}
                      name={`${module}.${index}.view`}
                      options={
                        permission.variableName === "quotation" ||
                        permission.variableName === "sales_order"
                          ? defaultViewSelfOption
                          : defaultViewOption
                      }
                      disabled={Boolean(
                        controlledFields.length > 0 &&
                          controlledFields[0].view === "close" &&
                          permission.variableName !== "role" &&
                          permission.variableName !== "user_export" &&
                          index !== 0
                      )}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (value === "close") {
                          if (
                            index === 0 &&
                            permission.variableName !== "user_account"
                          ) {
                            resetArrayHandler();
                          } else {
                            setValue(`${module}.${index}.create`, false);
                            setValue(`${module}.${index}.update`, false);
                            setValue(`${module}.${index}.cancel`, false);
                            setValue(`${module}.${index}.delete`, false);
                          }
                        }
                      }}
                    />
                  </Box>
                ) : (
                  <Typography>
                    {
                      defaultViewSelfOption.find(
                        (option) => option.value === permission.view
                      )?.label
                    }
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ p: 1 }} align="center">
                {permission.isCreatable ? (
                  <ControlledCheckbox
                    control={control}
                    name={`${module}.${index}.create`}
                    disabled={permission.view === "close" || disabledPermission}
                    customIcon={<CheckBoxBlankIcon />}
                  />
                ) : undefined}
              </TableCell>
              <TableCell sx={{ p: 1 }} align="center">
                {permission.isUpdatable ? (
                  <ControlledCheckbox
                    control={control}
                    name={`${module}.${index}.update`}
                    disabled={permission.view === "close" || disabledPermission}
                    onChange={(_, checked) => {
                      const isLockCancel = getValues(
                        `${module}.${index}.isLockCancel`
                      );
                      if (isLockCancel) {
                        setValue(`${module}.${index}.cancel`, checked);
                      }

                      const isLockDelete = getValues(
                        `${module}.${index}.isLockDelete`
                      );
                      if (isLockDelete) {
                        setValue(`${module}.${index}.delete`, checked);
                      }
                    }}
                    customIcon={<CheckBoxBlankIcon />}
                  />
                ) : undefined}
              </TableCell>
              <TableCell sx={{ p: 1 }} align="center">
                {permission.isCancelable ? (
                  <ControlledCheckbox
                    control={control}
                    name={`${module}.${index}.cancel`}
                    disabled={
                      permission.view === "close" ||
                      permission.isLockCancel ||
                      disabledPermission
                    }
                    customIcon={<CheckBoxBlankIcon />}
                  />
                ) : undefined}
              </TableCell>
              <TableCell sx={{ p: 1 }} align="center">
                {permission.isDeletable ? (
                  <ControlledCheckbox
                    control={control}
                    name={`${module}.${index}.delete`}
                    disabled={
                      permission.view === "close" ||
                      permission.isLockDelete ||
                      disabledPermission
                    }
                    customIcon={<CheckBoxBlankIcon />}
                  />
                ) : undefined}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionTable;
