import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import { IRadio } from "../../../types/global";
import ControlledRadioGroup from "../../Controller/ControlledRadioGroup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  updateHandler: (data: any) => void;
  roleName: string;
};

type UpdateMode = {
  mode: "all" | "some";
};

const UpdateRoleModal = ({
  open,
  closeModalHandler,
  updateHandler,
  roleName,
}: Props) => {
  const { t } = useTranslation();

  const defaultValues: UpdateMode = {
    mode: "all",
  };

  const { control, setValue, handleSubmit } = useForm<UpdateMode>({
    defaultValues,
  });

  const onClose = () => {
    setValue("mode", "all");
    closeModalHandler();
  };

  const updateModeList: IRadio[] = [
    {
      label: t("user.role.modal.all") + ` “${roleName}”`,
      value: "all",
    },
    {
      label: (
        <Box>
          <Typography display={"inline"}>
            {t("user.role.modal.all") + ` “${roleName}”`}
          </Typography>{" "}
          <Typography display={"inline"} fontWeight={600}>
            {t("user.role.modal.not_custom")}
          </Typography>
        </Box>
      ),
      value: "some",
    },
  ];

  return (
    <ModalUI
      title={t("user.role.modal.index")}
      open={open}
      handleClose={closeModalHandler}
      maxWidth={"xs"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={() => {
              handleSubmit(updateHandler)();
              onClose();
            }}
          />
        </Box>
      }
    >
      <Typography color="#737373" mb={1}>
        {t("user.role.modal.message")}
      </Typography>
      <ControlledRadioGroup
        control={control}
        name="mode"
        radioLists={updateModeList}
        row={false}
        disabled={false}
        sx={{
          py: 0.5,
        }}
      />
    </ModalUI>
  );
};

export default UpdateRoleModal;
