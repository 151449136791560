import { Box, CircularProgress, Stack } from "@mui/material";
import CustomerInfo from "../../../components/Form/Sales/CustomerInfo";
import AddressInfo from "../../../components/Form/Sales/CustomerInfo/AddressInfo";
import SalesFooter from "../../../components/Form/Sales/Footer";
import SalesOrderHeader from "../../../components/Form/Sales/Order/Header";
import SalesInfo from "../../../components/Form/Sales/SalesInfo";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useDisable } from "../../../hooks/use-disable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  SalesOrderCreateInput,
  SalesOrderFindUniqueQuery,
  SalesOrderUpdateInput,
  useSalesOrderCreateMutation,
  useSalesOrderFindUniqueQuery,
  useSalesOrderUpdateMutation,
} from "../../../generated/sales";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useSnackbar } from "notistack";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import {
  salesOrderCreatePayloadFormatter,
  salesOrderQueryFormatter,
  salesOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/Sales/SalesOrder";
import {
  ActivityLogDocumentType,
  ActivityType,
} from "../../../generated/general";
import { IExternalProps } from "../../../types/Sales";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { IApprovalTemplate } from "../../../types/global";
import { useStateContext } from "../../../contexts/auth-context";
import { errorMessageFormatter } from "../../../utils/Global";
import dayjs from "dayjs";

const DocumentInfoTab = () => {
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext<ISalesOrder>();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { t } = useTranslation();
  const [disabled, setDisabled] = useDisable();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { id } = useParams();

  const {
    state: { permissions },
  } = useStateContext();

  const {
    state: { authUser },
  } = useStateContext();

  const { state } = useLocation();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { createActivityLog } = useActivityLog();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isLoading: isOptionLoading, approvalTemplates } =
    useSalesOption("sales_order");
  const [approverList, setApproverList] = useState<IApprovalTemplate[]>([]);
  const [approvalStep, setApprovalStep] = useState<number>(0);
  const [isHaveApproval, setIsHaveApproval] = useState<boolean>(false);
  const isApproval = approvalTemplates && approvalTemplates.length > 0;

  const [netAmount, setNetAmount] = useState<number | null | undefined>(0);

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch } =
    useSalesOrderFindUniqueQuery<SalesOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { SalesOrderFindUnique } = data;
      setNetAmount(SalesOrderFindUnique.net_amount);

      const getSalesOrderData = async () => {
        const formattedSalesOrder = await salesOrderQueryFormatter(
          SalesOrderFindUnique
        );
        reset(formattedSalesOrder);
        setIsLoadingData(false);
      };
      getSalesOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (isSuccess) {
      const { SalesOrderFindUnique } = data;
      const currentStep = SalesOrderFindUnique?.approval_step as number;
      setApprovalStep(currentStep);
      if (SalesOrderFindUnique?.main_status === "wait_approve") {
        setApproverList(approvalTemplates as IApprovalTemplate[]);
      } else {
        const allApproval = SalesOrderFindUnique?.approver_list || [];
        setApproverList(allApproval);
      }

      const currentReviewerList =
        approvalTemplates.find(
          (template) => template?.step_number === currentStep
        )?.approver_list || [];

      const isHaveApproval = currentReviewerList.some(
        (reviewer) => reviewer.id === authUser?.id
      );

      setIsHaveApproval(isHaveApproval);
    }
  }, [approvalTemplates, authUser?.id, data, isSuccess]);

  useEffect(() => {
    if (
      id &&
      ((status !== "draft" && status !== "not_approved") ||
        ((status === "draft" || status === "not_approved") &&
          !permissions?.sales_order?.update))
    ) {
      setDisabled(true);
    }

    return () => {
      setDisabled(false);
    };
  }, [id, permissions?.sales_order?.update, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        issue_date: dayjs(),
        due_date: dayjs().add(state.credit_day || 0, "days"),
        delivery_date: dayjs(),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useSalesOrderCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useSalesOrderUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: ISalesOrder) => {
    if (!id) {
      try {
        const formatData = salesOrderCreatePayloadFormatter(data, "draft");
        const { SalesOrderCreate } = await create({
          input: formatData as SalesOrderCreateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/order/${SalesOrderCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesOrder,
            reference_id: SalesOrderCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesOrderCreate.id,
                unique_id: SalesOrderCreate.unique_id,
              },
            },
          });
        }
        const reference_document_list = getValues("reference_document_list");
        if (reference_document_list && reference_document_list.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: reference_document_list[0].document_id,
            activity_detail: {
              prev_status: "accepted",
              curr_status: "finished",
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: SalesOrderCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
            updated_fields: {
              net_amount: SalesOrderCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = salesOrderUpdatePayloadFormatter(data, "draft");
        const { SalesOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesOrderUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);

        if (formattedDirtyFields.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.SalesOrder,
            reference_id: SalesOrderUpdate?.id,
            activity_detail: {
              updated_fields: {
                net_amount:
                  netAmount?.toFixed() ===
                  SalesOrderUpdate.net_amount?.toFixed()
                    ? undefined
                    : SalesOrderUpdate.net_amount,
              },
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitApproveHandler = async (data: ISalesOrder) => {
    if (!id) {
      try {
        const formatData = salesOrderCreatePayloadFormatter(
          data,
          "wait_approve",
          isApproval ? 1 : 0
        );
        const { SalesOrderCreate } = await create({
          input: formatData as SalesOrderCreateInput,
        });
        enqueueSnackbar(`${t("button.send") + t("button.approve")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/order/${SalesOrderCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesOrder,
            reference_id: SalesOrderCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesOrderCreate.id,
                unique_id: SalesOrderCreate.unique_id,
              },
            },
          });
        }
        const reference_document_list = getValues("reference_document_list");
        if (reference_document_list && reference_document_list.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: reference_document_list[0].document_id,
            activity_detail: {
              prev_status: "accepted",
              curr_status: "finished",
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: SalesOrderCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_approve",
            updated_fields: {
              net_amount: SalesOrderCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(
          formatError || `${t("button.send") + t("button.approve")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        const formatData = salesOrderUpdatePayloadFormatter(
          data,
          "wait_approve",
          isApproval ? 1 : 0
        );
        const { SalesOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesOrderUpdateInput,
        });
        await refetch();
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: SalesOrderUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "wait_approve",
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                SalesOrderUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : SalesOrderUpdate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(
          formatError || `${t("button.send") + t("button.approve")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const approveHandler = async (data: ISalesOrder) => {
    if (!id) {
      try {
        const reviewed_by = {
          user_id: authUser?.id,
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          reviewed_date: dayjs(),
        };
        const formatData = salesOrderCreatePayloadFormatter(
          data,
          "approved",
          undefined,
          reviewed_by
        );
        const { SalesOrderCreate } = await create({
          input: formatData as SalesOrderCreateInput,
        });
        enqueueSnackbar(`${t("button.approve")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/order/${SalesOrderCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.SalesOrder,
            reference_id: SalesOrderCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: SalesOrderCreate.id,
                unique_id: SalesOrderCreate.unique_id,
              },
            },
          });
        }
        const reference_document_list = getValues("reference_document_list");
        if (reference_document_list && reference_document_list.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: reference_document_list[0].document_id,
            activity_detail: {
              prev_status: "accepted",
              curr_status: "finished",
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: SalesOrderCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "approved",
            updated_fields: {
              net_amount: SalesOrderCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        let changingStatus = "wait_approve";
        let currentApprovalList: IApprovalTemplate[] = [];
        if (approvalStep && approvalStep > approvalTemplates.length - 1) {
          changingStatus = "approved";
          currentApprovalList = approvalTemplates as IApprovalTemplate[];
        }

        if (!isApproval) {
          changingStatus = "approved";
        }

        let currentApprovalStep = (approvalStep || 0) + 1;

        if (data.aggrid_status === "not_approved") {
          currentApprovalStep = approvalStep || 0;
        }

        const reviewed_by = {
          user_id: authUser?.id,
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          reviewed_date: dayjs(),
        };

        const formatData = salesOrderUpdatePayloadFormatter(
          data,
          changingStatus,
          currentApprovalStep,
          currentApprovalList,
          false,
          reviewed_by
        );

        const { SalesOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as SalesOrderUpdateInput,
        });
        enqueueSnackbar(`${t("button.approve")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        if (isApproval) {
          if (changingStatus === "approved") {
            await createActivityLog({
              activity_type: ActivityType.StatusChange,
              document_type: ActivityLogDocumentType.SalesOrder,
              reference_id: SalesOrderUpdate?.id,
              activity_detail: {
                secondary_operation: ActivityType.Approve,
                message: [`ลำดับที่ ${approvalStep}`],
                prev_status: "wait_approve",
                curr_status: "approved",
              },
            });
          } else {
            await createActivityLog({
              activity_type: ActivityType.Approve,
              document_type: ActivityLogDocumentType.SalesOrder,
              reference_id: SalesOrderUpdate?.id,
              activity_detail: {
                message: [`ลำดับที่ ${approvalStep}`],
              },
            });
          }
        } else {
          const formattedDirtyFields = Object.keys(dirtyFields);
          await createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: ActivityLogDocumentType.SalesOrder,
            reference_id: SalesOrderUpdate?.id,
            activity_detail: {
              secondary_operation:
                formattedDirtyFields?.length > 0
                  ? ActivityType.Edit
                  : undefined,
              prev_status: data.aggrid_status,
              curr_status: "approved",
              updated_fields:
                formattedDirtyFields?.length > 0
                  ? formattedDirtyFields
                  : undefined,
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.approve")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const notApproveHandler = async (data: ISalesOrder) => {
    try {
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("not_approved")
            ? [...data.flag_status]
            : [...data.flag_status, "not_approved"]
          : ["not_approved"];

      const { SalesOrderUpdate } = await update({
        input: {
          item_price_list_id: data.item_price_list_id as number,
          flag_status: flag_status,
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesOrder,
        reference_id: SalesOrderUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "not_approved",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });

      enqueueSnackbar(`${t("button.not_approve")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.not_approve")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);
    reset();
  };

  const editHandler = async (data: ISalesOrder, isNotApprove?: boolean) => {
    try {
      const formatData = salesOrderUpdatePayloadFormatter(
        data,
        data?.main_status || "",
        approvalStep,
        approverList,
        isNotApprove
      );
      const { SalesOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: formatData as SalesOrderUpdateInput,
      });
      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      if (formattedDirtyFields.length > 0) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: SalesOrderUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                SalesOrderUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : SalesOrderUpdate.net_amount,
            },
          },
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const externalReferenceHandler = async (data: IExternalProps) => {
    try {
      await update({
        input: {
          external_reference_no: data.external_reference_no,
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { SalesOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: {
          item_price_list_id: data.item_price_list_id as number,
          flag_status: flag_status,
        },
      });

      enqueueSnackbar(`${t("button.cancel")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesOrder,
        reference_id: SalesOrderUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
    } catch (err: any) {
      if (
        err.message.includes(
          "Cannot cancel sales order because it has related document"
        )
      ) {
        enqueueSnackbar(
          `ไม่สามารถยกเลิกเอกสารได้ กรุณายกเลิกเอกสารที่เกี่ยวข้อง`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(`${t("button.cancel")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            {isApproval ? (
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(waitApproveHandler)}
              />
            ) : (
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(approveHandler)}
              />
            )}
          </>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => {
                  handleSubmit((data) => editHandler(data))();
                  setIsEdit(false);
                  setDisabled(true);
                }}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          if (isHaveApproval) {
            return (
              <>
                <CustomizedButton
                  variant="outlined"
                  title={t("button.not_approve")}
                  disabled={isUpdating}
                  onClick={handleSubmit(notApproveHandler)}
                />
                <CustomizedButton
                  title={t("button.approve")}
                  variant="contained"
                  onClick={handleSubmit(approveHandler)}
                  disabled={isUpdating}
                />
              </>
            );
          } else {
            return;
          }
        }
      case "not_approved":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              disabled={isUpdating}
              onClick={handleSubmit((data) => editHandler(data, true))}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(waitApproveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "approved":
      case "cancelled":
      case "finished":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => {
                  handleSubmit((data) => editHandler(data))();
                  setIsEdit(false);
                  setDisabled(true);
                }}
                disabled={isUpdating}
              />
            </>
          );
        }
        return;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            {isApproval ? (
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(waitApproveHandler)}
              />
            ) : (
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                disabled={isCreating || isUpdating}
                onClick={handleSubmit(approveHandler)}
              />
            )}
          </>
        );
    }
  };

  useSalesError(errors);

  if (isOptionLoading || (id && (isLoadingData || isLoading))) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <SalesOrderHeader
        editClickHandler={editClickHandler}
        externalReferenceHandler={externalReferenceHandler}
        cancelHandler={cancelHandler}
        approverList={approverList as IApprovalTemplate[]}
        approvalStep={approvalStep}
      />
      <CustomerInfo />
      <AddressInfo />
      <SalesInfo />
      <SalesItemList documentType="sales_order" />
      {/* <Promotion /> */}
      <SalesFooter documentType="sales_order" />
      <BottomNavbar>
        {(!disabled || status === "wait_approve") && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
