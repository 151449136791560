import { Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import InventoryPermissionTable from "../../Table/Permission/InventoryPermissionTable";
import ContactPermissionTable from "../../Table/Permission/ContactPermissionTable";
import SalesPermissionTable from "../../Table/Permission/SalesPermissionTable";
import PurchasePermissionTable from "../../Table/Permission/PurchasePermissionTable";
import UserPermissionTable from "../../Table/Permission/UserPermissionTable";
import SettingPermissionTable from "../../Table/Permission/SettingPermissionTable";
import MarketingPermissionTable from "../../Table/Permission/MarketingPermissionTable";
import LogisticPermissionTable from "../../Table/Permission/LogisticPermissionTable";
import { useEffect, useState } from "react";
import { ITab } from "../../../types/global";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../Custom/CustomizedTab";

const RoleAccess = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(
    pathname + "?tab=inventory"
  );

  const tabs: ITab[] = [
    {
      label: t("inventory.index"),
      path: `${pathname}?tab=inventory`,
    },
    {
      label: t("contact.index"),
      path: `${pathname}?tab=contact`,
    },
    {
      label: t("sales.index"),
      path: `${pathname}?tab=sales`,
    },
    {
      label: t("purchase.index"),
      path: `${pathname}?tab=purchase`,
    },
    {
      label: t("user.index"),
      path: `${pathname}?tab=user`,
    },
    {
      label: t("setting.index"),
      path: `${pathname}?tab=setting`,
    },
    {
      label: t("marketing.index"),
      path: `${pathname}?tab=marketing`,
    },
    {
      label: t("logistic.index"),
      path: `${pathname}?tab=logistic`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "branch":
        setCurrentTab(pathname + "?tab=branch");
        break;
      case "inventory":
        setCurrentTab(pathname + "?tab=inventory");
        break;
      case "contact":
        setCurrentTab(pathname + "?tab=contact");
        break;
      case "sales":
        setCurrentTab(pathname + "?tab=sales");
        break;
      case "purchase":
        setCurrentTab(pathname + "?tab=purchase");
        break;
      case "user":
        setCurrentTab(pathname + "?tab=user");
        break;
      case "setting":
        setCurrentTab(pathname + "?tab=setting");
        break;
      case "marketing":
        setCurrentTab(pathname + "?tab=marketing");
        break;
      case "logistic":
        setCurrentTab(pathname + "?tab=logistic");
        break;
      default:
        setCurrentTab(pathname + "?tab=branch");
        break;
    }
  }, [pathname, tab]);

  const renderTable = (tab: string | null) => {
    switch (tab) {
      case "inventory":
        return <InventoryPermissionTable />;
      case "contact":
        return <ContactPermissionTable />;
      case "sales":
        return <SalesPermissionTable />;
      case "purchase":
        return <PurchasePermissionTable />;
      case "user":
        return <UserPermissionTable />;
      case "setting":
        return <SettingPermissionTable />;
      case "marketing":
        return <MarketingPermissionTable />;
      case "logistic":
        return <LogisticPermissionTable />;
      default:
        return <InventoryPermissionTable />;
    }
  };

  return (
    <CustomizedBox margin={0}>
      <Typography fontWeight={600} color={"primary.main"}>
        {t("user.role.permission")}
      </Typography>
      <CustomizedTab tabs={tabs} currentTab={currentTab} table />
      {renderTable(tab)}
    </CustomizedBox>
  );
};

export default RoleAccess;
