import { gql } from "graphql-request";

export const DELIVERY_ORDER_VIEW_AGGRID = gql`
  query DeliveryOrderViewsAggrid($aggridInput: AnyAggridInput) {
    DeliveryOrderViewsAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        issue_date
        exported_date
        delivery_date
        status
        reference_document_list
        reference_document_object_list
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        branch_id
        branch_unique_id
        branch_name
        branch_unique_id_name
        customer_unique_id
        customer_name
        created_by
        created_by_object
        created_date
        related_user_list
        related_user_object_list
        remark
        delivery_address
        delivery_address_sub_district
        delivery_address_district
        delivery_address_province
        delivery_address_postal_code
        delivery_address_country
        last_updated_date
      }
      count
    }
  }
`;
