import { Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import {
  useForm,
  useFormContext,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import { useState, useEffect } from "react";
import { useDisable } from "../../../../hooks/use-disable";
import CustomizedButton from "../../../Custom/CustomizedButton";
import SalesPromotionTable from "../../../Table/Sales/Promotion";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PROMOTION_SETTINGS_FIND_BY_COUPON } from "../../../../services/Marketing/Promotion/PromotionSettings";
import { GraphQLClient } from "graphql-request";
import CheckedAvailablePromotionSettingsModal from "../../../UI/Modal/CheckedAvailablePromotionSettingsModal";
import { useModal } from "../../../../hooks/use-modal";
import { usePromotionApply } from "../../../../hooks/Marketing/use-promotion-apply";

type Props = {
  documentType: string;
};

const SalesPromotion = ({ documentType }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [promotionUniqueIds, setPromotionUniqueIds] = useState<string[]>([]);
  const [promotionUniqueIdsSnapshot, setPromotionUniqueIdsSnapshot] = useState<
    string[]
  >([]);

  const { recalculatePromotionDiscounts } = usePromotionApply({
    documentType: documentType,
  });

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const {
    control: couponControl,
    getValues: couponGetValues,
    resetField,
  } = useForm({
    defaultValues: {
      coupon_code: "",
    },
  });

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const { control, getValues } = useFormContext();

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const watchItemList = useWatch({
    control,
    name: "item_list",
  });

  const watchCustomerDetails = useWatch({
    control,
    name: "customer_details",
  });

  const watchSalesChannelId = useWatch({
    control,
    name: "sales_channel_id",
  });

  const totalPrice = Math.round(
    watchItemList.reduce((acc: number, item: any) => {
      const itemPrice = item.price_per_unit ?? 0;
      const discount = item.discount ?? 0;
      const qty = item.qty ?? 0;

      const adjustedPrice = Math.max(itemPrice - discount);
      return acc + adjustedPrice * qty;
    }, 0)
  );

  const formattedItemList = watchItemList
    .map((item: any) => ({
      item_id: item.item_id,
      amount: item.qty,
    }))
    .filter((item: any) => !isNaN(item.item_id));

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "promotion_list",
  });

  const watchFieldArray = useWatch({
    control: control,
    name: "promotion_list",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    const promotionUniqueIds = fields.map(
      (field: any) => field.promotion_unique_id
    );
    setPromotionUniqueIds(promotionUniqueIds);
    setPromotionUniqueIdsSnapshot(promotionUniqueIds);
  }, [fields]);

  const [disabled] = useDisable();

  const addCouponHandler = async () => {
    const couponCode = couponGetValues("coupon_code");
    const formValues = getValues();
    try {
      const { PromotionSettingsFindByCoupon: data } =
        await graphQLClientWithHeader.request(
          PROMOTION_SETTINGS_FIND_BY_COUPON,
          {
            findByCouponInput: {
              coupon_code: couponCode,
              customer_id: formValues.customer_details?.id ?? 0,
              total_price: totalPrice,
              branch_id: watchBranchId ?? -1,
              item_list: formattedItemList,
              sales_channel_id: watchSalesChannelId,
            },
          }
        );
      const hasPromotionId = fields.some(
        (promotion: any) => promotion.promotion_id === data.id
      );
      if (!hasPromotionId) {
        let promotionDiscountSum = 0;

        append({
          promotion_id: data.id,
          promotion_unique_id: data.unique_id,
          name: data.name,
          coupon_code: couponCode,
          discount_rate: data.amount_per_item,
          total_discount_amount: promotionDiscountSum,
          receive_type: data.receive_type,
          redeem_item_list: data.redeem_item,
          priority: data.priority,
          conditional_item_id_list: data.redeem_item?.map(
            (item: any) => item.item_id
          ),
        });

        recalculatePromotionDiscounts();

        enqueueSnackbar(`${couponCode} ใช้งานสำเร็จ`, { variant: "success" });
      }
    } catch (err) {
      const errorMessage = (err as any)?.response?.errors?.[0]?.message;
      let formattedErrorMessage = "";

      switch (errorMessage) {
        case "The coupon has been fully redeemed":
          formattedErrorMessage = `${couponCode} ถูกใช้งานแล้ว กรุณาเลือกใช้งานใหม่`;
          break;
        case "not found coupon":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.not_found"
          );
          break;
        case "The promotion has expired":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.promotion_expired"
          );
          break;
        case "The promotion is not active yet":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.promotion_not_active"
          );
          break;
        case "The total price is below the required minimum":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.total_price_below_minimum"
          );
          break;
        case "The category does not match for some items":
        case "item not match condition":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.item_condition"
          );
          break;
        case "customer is not match promotion":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.customer_condition"
          );
          break;
        case "sale channel not match":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.sales_channel_condition"
          );
          break;
        case "item amount not match condition":
          formattedErrorMessage = t(
            "marketing.promotion.sentence.add_coupon.item_amount_condition"
          );
          break;
      }

      enqueueSnackbar(formattedErrorMessage, { variant: "error" });
    }
    resetField("coupon_code");
  };

  const removeCouponHandler = (index: number) => {
    remove(index);
    recalculatePromotionDiscounts();
  };

  const addPromotionCouponHandler = (selectedPromotions: any[]) => {
    if (selectedPromotions?.length) {
      let filteredPromotions: any[] = fields.filter((p: any) =>
        promotionUniqueIds.includes(p.promotion_unique_id)
      );

      const filteredPromotionUniqueIds = filteredPromotions.map(
        (p: any) => p.promotion_unique_id
      );

      selectedPromotions.forEach((promotion) => {
        if (!filteredPromotionUniqueIds.includes(promotion.unique_id)) {
          filteredPromotions.push({
            promotion_id: promotion.id,
            promotion_unique_id: promotion.unique_id,
            name: promotion.name,
            coupon_code: promotion.coupon_code,
            discount_rate:
              typeof promotion.amount_per_item === "number"
                ? promotion.amount_per_item
                : parseFloat(promotion.amount_per_item) || 0,
            total_discount_amount: 0,
            receive_type: promotion.receive_type,
            redeem_item_list: promotion.redeem_item,
            priority: promotion.priority,
          });
        }
      });
      replace(filteredPromotions);
    } else if (selectedPromotions.length === 0) {
      replace([]);
    }
    recalculatePromotionDiscounts();
    closeModalHandler();
  };

  return (
    <CustomizedBox margin={"1.5rem 0 0 0"} maxWidth={1650}>
      <Typography fontWeight={600} color={"primary.main"} mb={2}>
        {t("marketing.promotion.index")}
      </Typography>
      {!disabled && (
        <Grid container alignItems={"center"} mb={2}>
          <Grid item xs={10} sm={10} md={4}>
            <ControlledTextField
              control={couponControl}
              name="coupon_code"
              placeholder={t("marketing.coupon.code")}
              //   error={Boolean(errors.coupon_code)}
              //   helperText={errors.coupon_code?.message}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  await addCouponHandler();
                }
              }}
              disabled={!watchItemList || watchItemList?.length === 0}
            />
          </Grid>
          {watchItemList?.length > 0 && (
            <Grid item xs={2} sm={2} md={4}>
              <CustomizedButton
                title={t("button.add")}
                onClick={addCouponHandler}
              />
            </Grid>
          )}
        </Grid>
      )}
      <SalesPromotionTable
        fields={controlledFields}
        remove={removeCouponHandler}
      />
      <Box
        sx={{
          display: "flex",
          alignitems: "center",
          columnGap: "1rem",
          mt: "1rem",
        }}
      >
        {/* <CustomizedButton
          title={t("marketing.promotion.sentence.auto_add_promotion")}
          onClick={() => {}}
          variant="contained"
          disabled
        /> */}
        {!disabled && (
          <CustomizedButton
            title={t("marketing.promotion.sentence.add_promotion")}
            onClick={openModalHandler}
            variant="outlined"
            addIcon
            disabled={!watchItemList || watchItemList?.length === 0}
          />
        )}
      </Box>
      <CheckedAvailablePromotionSettingsModal
        keyName="unique_id"
        promotionUniqueIds={promotionUniqueIds}
        setPromotionUniqueIds={setPromotionUniqueIds}
        promotionUniqueIdsSnapshot={promotionUniqueIdsSnapshot}
        setPromotionUniqueIdsSnapshot={setPromotionUniqueIdsSnapshot}
        showSelectPromotion={modal}
        closePromotionTable={closeModalHandler}
        finishPromotionSelect={addPromotionCouponHandler}
        branchId={watchBranchId ?? -1}
        salesChannelId={watchSalesChannelId}
        customerId={watchCustomerDetails?.id ?? 0}
        totalPrice={totalPrice}
        itemList={formattedItemList}
      />
    </CustomizedBox>
  );
};

export default SalesPromotion;
