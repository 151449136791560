import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISelectOption } from "../../types/global";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  BranchesFindAllQuery,
  WarehousesFindAllQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
  useBranchesFindAllQuery,
  useWarehousesFindAllQuery,
} from "../../generated/general";

export const usePurchaseOption = (type?: string) => {
  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: type || "",
      },
      {
        enabled: !!type,
      }
    );

  const { data: branches, isLoading: isBranchesLoading } =
    useBranchesFindAllQuery<BranchesFindAllQuery>(graphQLClientGeneral);

  const { data: warehouse, isLoading: isWarehouseLoading } =
    useWarehousesFindAllQuery<WarehousesFindAllQuery>(graphQLClientGeneral);

  const branchesOptions: ISelectOption[] =
    branches?.BranchesFindAll?.map((branch) => ({
      label: branch.is_hq
        ? `${branch.unique_id} - ${branch.name} (สำนักงานใหญ่)`
        : `${branch.unique_id} - ${branch.name}`,
      value: branch.id,
      id: branch.id,
    })) || [];

  const warehouseOptions: ISelectOption[] =
    warehouse?.WarehousesFindAll.map((warehouse) => ({
      label: `${warehouse.unique_id} - ${warehouse.name}`,
      value: warehouse.id,
      id: warehouse.id,
    })) || [];

  const renderBranchOptions = (id: number) =>
    branchesOptions.find((option) => option.id === id)?.label;

  const renderWarehousesOptions = (id: number) =>
    warehouseOptions.find((option) => option.id === id)?.label;

  return {
    branchesOptions,
    warehouseOptions,
    renderBranchOptions,
    renderWarehousesOptions,
    approvalTemplates:
      approvalTemplates?.ApprovalTemplateFindByDocumentType || [],
    isLoading:
      isBranchesLoading || isWarehouseLoading || (type && isApprovalLoading),
  };
};
