import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import { hasPermission } from "../../utils/Global";
import { useStateContext } from "../../contexts/auth-context";

const Setting = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.zone.index"),
      to: "/setting/zone",
      permissionKey: "zone",
    },
    {
      name: t("branch.info"),
      to: "/setting/branch",
      permissionKey: "branch",
    },
    {
      name: t("setting.company.index"),
      to: "/setting/company",
      permissionKey: "company",
    },
    {
      name: t("setting.approval.index"),
      to: "/setting/approval",
      permissionKey: "approval",
    },
    {
      name: t("setting.contact.index"),
      to: "/setting/contact",
      permissionKey: "contact_setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
      permissionKey: "inventory_setting",
    },
    {
      name: t("setting.sales.index"),
      to: "/setting/sales",
      permissionKey: "sales_setting",
    },
    {
      name: t("setting.employee.index"),
      to: "/setting/employee",
      permissionKey: "employee",
    },
    {
      name: t("setting.logistic.index"),
      to: "/setting/logistic",
      permissionKey: "logistic_setting",
    },
  ].filter((item) => hasPermission(item.permissionKey, permissions));

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Setting;
