import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { COUPON_AGGRID } from "../../../../services/AgGrid/MarketingAgGrid";
import { useCouponColumnDefs } from "./columnDefs";
import { useFormContext } from "react-hook-form";

type Props = {
  gridRef: RefObject<AgGridReact<any>>;
};

const CouponTable = ({ gridRef }: Props) => {
  const { watch, setValue } = useFormContext();

  const columnDef: ColDef[] | undefined = useCouponColumnDefs();

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, last_updated_date, ...otherFilterModel } =
        filterModel;

      const stateUUID = watch("state_uuid");
      const promotionID = watch("id");

      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        last_updated_date: dateFilterModel(last_updated_date),
        state_uuid: stateUUID
          ? {
              filterType: "text",
              type: "equals",
              filter: stateUUID,
            }
          : undefined,
        promotion_id: promotionID
          ? {
              filterType: "number",
              type: "equals",
              filter: promotionID,
            }
          : undefined,
        sequence: {
          filterType: "number",
          type: "notEqual",
          filter: 0,
        }
      };
      try {
        const { CouponViewsAggrid } =
          await graphQLClientWithHeaderSales.request(COUPON_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: CouponViewsAggrid.results as any[],
          rowCount: CouponViewsAggrid.count as number,
        });
        setValue("coupon_count", CouponViewsAggrid.count);
        setValue("coupon_available_count", CouponViewsAggrid.available);
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={450}
      onGridReady={onGridReady}
      disabledSidebar
    />
  );
};

export default CouponTable;
