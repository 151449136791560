import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDeliveryOrderListPdfHeader } from "./columnDef";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import { ITraceEntry } from "../../../../types/Inventory";

type Props = {
  itemList: ITraceEntry[];
  startNumber: number;
};

const DeliveryOrderListPdfTable = ({ itemList, startNumber }: Props) => {
  const headers = useDeliveryOrderListPdfHeader();

  const tablePadding = {
    px: 1,
    py: 1,
  };

  const fontSize = 10.66;

  const groupByUniqueIdAndSumQty = (items: ITraceEntry[]): ITraceEntry[] => {
    const groupedItems: { [key: string]: ITraceEntry } = {};

    items.forEach((item) => {
      const uniqueId = item.reference_line_item.line_item_unique_id;

      if (groupedItems[uniqueId]) {
        // If the item already exists in the groupedItems, sum the qty
        groupedItems[uniqueId].qty += item.qty;
      } else {
        // Otherwise, add the item to the groupedItems
        groupedItems[uniqueId] = { ...item }; // Clone the item to avoid mutations
      }
    });

    // Return the values (grouped and summed items) as an array
    return Object.values(groupedItems);
  };

  const newItemList = groupByUniqueIdAndSumQty(itemList);

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newItemList.map((item, index) => (
                <TableRow key={item.unique_id}>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>
                      {startNumber + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.sku_name}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_name}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        color: "#737373",
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.item_sku_desc}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.qty)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>
                      {item.uom?.name_th}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DeliveryOrderListPdfTable;
