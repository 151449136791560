import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactInstance, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Box, CircularProgress, Typography } from "@mui/material";

import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { IPdfInfo } from "../../../types/global";
import { ITraceEntry } from "../../../types/Inventory";
import {
  DeliveryOrderFindUniqueQuery,
  useDeliveryOrderFindUniqueQuery,
} from "../../../generated/logistic";

import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";

import CustomizedButton from "../../../components/Custom/CustomizedButton";
import DeliveryOrderListPdfTable from "../../../components/Table/Pdf/DeliveryOrder";
import LogisticPdfLayout from "../../../components/UI/LogisticPdf/LogisticPdfLayout";

const DeliveryOrderPdf = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("logistic");

  const { data, isLoading } =
    useDeliveryOrderFindUniqueQuery<DeliveryOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      }
    );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.DeliveryOrderFindUnique, //data
    "delivery_order", // document type
    136.47, // name and description row width
    640, // max height without footer
    500, // max height with footer
    226.484, // left header minimum height
    226.484, // right header minimum height
    66.531, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.922 // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.DeliveryOrderFindUnique.unique_id,
  });

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "วันกำหนดส่งของ"],
    info: [
      data?.DeliveryOrderFindUnique.unique_id || "-",
      data?.DeliveryOrderFindUnique.issue_date
        ? formatDate(data?.DeliveryOrderFindUnique.issue_date)
        : "-",
      data?.DeliveryOrderFindUnique.delivery_date
        ? formatDate(data?.DeliveryOrderFindUnique.delivery_date)
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่จัดส่ง",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
    ],
    info: [
      `${data?.DeliveryOrderFindUnique.customer_unique_id} - ${data?.DeliveryOrderFindUnique.customer_details.name}`,
      renderAddressString(
        data?.DeliveryOrderFindUnique.customer_details?.delivery_address
      ) || "-",
      data?.DeliveryOrderFindUnique.customer_details.identity_no || "-",
      data?.DeliveryOrderFindUnique.customer_details.phone +
        "@" +
        data?.DeliveryOrderFindUnique.customer_details.fax || "-",
      data?.DeliveryOrderFindUnique.customer_details.email || "-",
    ],
  };

  const rightSection: IPdfInfo = {
    headers: ["อ้างอิง", "สาขา", "คลัง", "ประเภทจัดส่ง", "Tracking No."],
    info: [
      data?.DeliveryOrderFindUnique.reference_document_list?.[0]
        .document_unique_id || "-",
      data?.DeliveryOrderFindUnique?.branch.unique_id +
        " - " +
        data?.DeliveryOrderFindUnique?.branch?.name,
      `${
        data?.DeliveryOrderFindUnique.destination_warehouse?.unique_id || ""
      } - ${data?.DeliveryOrderFindUnique.destination_warehouse?.name || ""}`,
      data?.DeliveryOrderFindUnique.delivery_type || "-",
      data?.DeliveryOrderFindUnique.tracking_no || "-",
    ],
  };

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">
          {t("logistic.delivery_order.index")}
        </Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <LogisticPdfLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบส่งของ"}
            data={data?.DeliveryOrderFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
          >
            <DeliveryOrderListPdfTable
              itemList={(item as ITraceEntry[]) || []}
              startNumber={startNumbers[index]}
            />
          </LogisticPdfLayout>
        ))}
      </Box>
    </Box>
  );
};

export default DeliveryOrderPdf;
