import { Box, Typography } from "@mui/material";
import { IPdfInfo } from "../../../types/global";

const LogisticPdfLeftInfo = ({ headers, info }: IPdfInfo) => {
  return (
    <Box px={1} py={0.5}>
      {headers.map((name, index) => (
        <Box sx={{ display: "flex", gap: 0.5 }} key={index}>
          {name === "เบอร์โทรศัพท์@โทรสาร" ? (
            <>
              <Typography
                sx={{
                  fontSize: 10.66,
                  fontWeight: 600,
                  color: "#333333",
                  width: 102.5,
                }}
                lineHeight={2}
              >
                เบอร์โทรศัพท์
              </Typography>
              <Typography
                sx={{
                  fontSize: 10.66,
                  width: 80,
                  wordBreak: "break-word",
                }}
                lineHeight={2}
              >
                {info[index].split("@")[0] || "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10.66,
                  fontWeight: 600,
                  color: "#333333",
                  width: 40,
                }}
                lineHeight={2}
              >
                โทรสาร
              </Typography>
              <Typography
                sx={{
                  fontSize: 10.66,
                  // flex: 2,
                  width: 80,
                  wordBreak: "break-word",
                }}
                lineHeight={2}
              >
                {info[index].split("@")[1] || "-"}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 10.66,
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                }}
                lineHeight={2}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10.66,
                  flex: 3,
                  width: "100%",
                  wordBreak: "break-word",
                }}
                lineHeight={2}
              >
                {info[index]}
              </Typography>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default LogisticPdfLeftInfo;
