import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../utils/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

import { usePurchaseAgGrid } from "../../../../hooks/Purchase/use-purchase-aggrid";
import { useSalesAgGrid } from "../../../../hooks/Sales/use-sales-aggrid";

export const useDeliveryOrderColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  const { salesUserFilterParams, branchFilterPararms } =
    useSalesAgGrid("request");

  useEffect(() => {
    setColumnDef([
      {
        field: "unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "exported_date",
        headerName: t("date.issued_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "delivery_date",
        headerName: t("date.delivery_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: [
            "draft",
            "issued",
            "delivering",
            "delivery_success",
            "delivery_fail",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "reference_document_list",
        headerName: t("sentence.reference_unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "destination_warehouse_name",
        headerName: t("warehouse.index"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: branchFilterPararms,
      },
      {
        field: "customer_name",
        headerName: t("logistic.delivery_order.customer.name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_by_object",
        headerName: t("sentence.created_by"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_date",
        headerName: t("date.created_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "related_user_object_list",
        headerName: t("sales.employee_list"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "remark",
        headerName: t("sentence.remark"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "delivery_address",
        headerName: t("address.index"),
        hide: true,
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "delivery_address_sub_district",
        headerName: t("address.sub_district"),
        hide: true,
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "delivery_address_district",
        headerName: t("address.district"),
        hide: true,
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "delivery_address_province",
        headerName: t("address.province"),
        hide: true,
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "delivery_address_postal_code",
        headerName: t("address.postal_code"),
        hide: true,
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return columnDef;
};
