import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IHeaderTable, ISelectOption } from "../../../types/global";
import ControlledSelect from "../../Controller/ControlledSelect";
import {
  useFormContext,
  useWatch,
  FieldArrayWithId,
  UseFieldArrayReplace,
} from "react-hook-form";
import { Dispatch, SetStateAction } from "react";
import { IUser } from "../../../types/Auth/user";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { accessFormatter } from "../../../utils/Formatter/Global";

const headers: IHeaderTable[] = [
  {
    thaiLabel: "รายการ",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "ดู",
    width: 200,
  },
];

const accessOption: ISelectOption[] = [
  {
    label: "เปิด",
    value: "open",
  },
  {
    label: "ปิด",
    value: "close",
  },
];

interface Props {
  setRemoveIds: Dispatch<SetStateAction<number[]>>;
  branch_permission: FieldArrayWithId<IUser, "branch_permission", "genId">[];
  replace: UseFieldArrayReplace<IUser, "branch_permission">;
}

const BranchPermissionTable = ({
  setRemoveIds,
  branch_permission,
  replace,
}: Props) => {
  const [disabled] = useDisable();
  const { control, getValues } = useFormContext<IUser>();

  const allBranchPermission =
    useWatch({
      control,
      name: "branch_permission",
    }) || [];

  const isHqOpen =
    allBranchPermission.filter(
      (permission) => permission.is_hq && permission.access === "open"
    ).length > 0;

  const original_branch = useWatch({
    control,
    name: "original_branch",
  });

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              bgcolor: (theme) => theme.palette.primary.light,
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    p: 1.5,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={"primary.main"}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {branch_permission.map((branch, index) => (
              <TableRow key={branch.genId}>
                <TableCell
                  width={
                    (headers && headers.length > 0
                      ? headers[0]?.width || 0
                      : 0) + 16
                  }
                  sx={{
                    p: 1.5,
                  }}
                >
                  <Typography>
                    {branch.name}{" "}
                    {branch.id === original_branch && "(ต้นสังกัด)"}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[1]?.width || 0
                      : 0) + 16
                  }
                  sx={{
                    p: 1.5,
                  }}
                >
                  {disabled ? (
                    <LabelInput
                      label=""
                      value={accessFormatter(
                        getValues(`branch_permission.${index}.access`)
                      )}
                    />
                  ) : (
                    <ControlledSelect
                      control={control}
                      name={`branch_permission.${index}.access`}
                      options={accessOption}
                      sx={{ width: 150, margin: "auto" }}
                      onChange={(e: any) => {
                        const currentId = getValues(
                          `branch_permission.${index}.id`
                        );
                        const isHqBranch = getValues(
                          `branch_permission.${index}.is_hq`
                        );

                        if (e.target.value === "close") {
                          setRemoveIds((prev) => [...prev, currentId]);
                        } else {
                          if (isHqBranch) {
                            const currentPermission =
                              getValues("branch_permission");

                            const newPermission =
                              currentPermission?.map((permission) => ({
                                ...permission,
                                access: "open",
                              })) || [];
                            replace(newPermission);
                          }
                          setRemoveIds((prev) =>
                            prev.filter((id) => id !== currentId)
                          );
                        }
                      }}
                      disabled={!branch.is_hq && isHqOpen}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BranchPermissionTable;
