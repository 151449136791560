import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid, InputAdornment } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../types/Sales";
import { salesPaymentChannelSchema } from "../../Form/Sales/CashSales/schema";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import ControlledSSCreatable from "../../Controller/ControlledSSCreatable";
import { bankNameOptions } from "../../../utils/Global";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { IOptionsCreatable } from "../../../types/global";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActivityType,
  SalesInvoiceFindUniqueQuery,
  SalesReferenceDocumentType,
  useSalesInvoiceFindUniqueQuery,
  useSalesInvoiceUpdateMutation,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import PaymentSummary from "../../Form/Sales/Payment/PaymentSummary";
import { paymentChannelInvoiceValidation } from "../../Form/Sales/Invoice/schema";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { ActivityLogDocumentType } from "../../../generated/inventory";
import { useStateContext } from "../../../contexts/auth-context";
// import PaymentSummary from "../../Form/Sales/Payment/PaymentSummary";

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { id: 0, value: "กำหนดเอง", label: "กำหนดเอง" },
  { id: 1, value: "0.75", label: "0.75" },
  { id: 2, value: "1", label: "1" },
  { id: 3, value: "1.5", label: "1.5" },
  { id: 4, value: "2", label: "2" },
  { id: 5, value: "3", label: "3" },
  { id: 6, value: "5", label: "5" },
  { id: 7, value: "10", label: "10" },
  { id: 8, value: "15", label: "15" },
];

type Props = {
  open: boolean;
  closeModalHandler: () => void;
};

const PaymentModal = ({ open, closeModalHandler }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { refetch } =
    useSalesInvoiceFindUniqueQuery<SalesInvoiceFindUniqueQuery>(
      graphqlClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const {
    state: { authUser },
  } = useStateContext();

  const { mutateAsync: update } =
    useSalesInvoiceUpdateMutation<Error>(graphqlClient);

  const { control: defaultControl, getValues: getDefaultValues } =
    useFormContext();

  const issueDate = useWatch({
    control: defaultControl,
    name: "issue_date",
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<ISalesPaymentChannel>({
    defaultValues: salesPaymentChannelSchema,
    resolver: yupResolver<any>(paymentChannelInvoiceValidation),
    context: {
      issue_date: issueDate,
    },
  });

  // const [withholdingTaxRate, setWithholdingTaxRate] = useState<string>("");
  // const [withholdingTaxAmount, setWithholdingTaxAmount] = useState<number>(0);
  // const [postDiscountWhtAmount, setPostDiscountWhtAmount] = useState<number>(0);
  // const [initialized, setInitialized] = useState<boolean>(false);

  const currentBranchId = useWatch({
    control: defaultControl,
    name: "branch_id",
  });

  const priceVatType = useWatch({
    control: defaultControl,
    name: "price_vat_type",
  });

  // const itemList: ISalesItemList[] = useWatch({
  //   control: defaultControl,
  //   name: "item_list",
  // });

  // const allWithholdingTaxAmount = useWatch({
  //   control: defaultControl,
  //   name: "withholding_tax_amount",
  // });

  // const allWithholdingTax = itemList.map((item) => item.withholding_tax_type);

  // const paymentList: ISalesPaymentChannel[] = useWatch({
  //   control: defaultControl,
  //   name: "payment_list",
  // });

  // const paymentWhtAmount: number = paymentList.reduce(
  //   (acc, channel) => acc + (channel.withholding_tax_amount || 0),
  //   0
  // );

  const paidTotalAmount =
    useWatch({
      control: defaultControl,
      name: "paid_total_amount",
    }) || 0;

  const netAmount =
    useWatch({
      control: defaultControl,
      name: "net_amount",
    }) || 0;

  const { paymentChannelPaymentName, paymentChannelAccount } =
    useSalesOption("sales_invoice");

  const currentPaymentChannelPaymentName = watch(
    "payment_channel_payment_name"
  );

  const { createActivityLog } = useActivityLog();

  const paymentChannelPaymentNameOptions = paymentChannelPaymentName.filter(
    (channel) => channel.branch_id_list?.includes(currentBranchId)
  );

  const paymentOrder = ["เงินสด", "บัญชีธนาคาร", "เช็ค"];

  const sortedPaymentChannelPaymentNameOptions =
    paymentChannelPaymentNameOptions.sort((a, b) => {
      const priorityA = paymentOrder.indexOf(a.label);
      const priorityB = paymentOrder.indexOf(b.label);

      // Handle missing priorities by treating them as having the lowest priority
      const priorityAIndex = priorityA === -1 ? paymentOrder.length : priorityA;
      const priorityBIndex = priorityB === -1 ? paymentOrder.length : priorityB;

      return priorityAIndex - priorityBIndex;
    });

  const paymentChannelAccountOptions = paymentChannelAccount.filter(
    (channel) =>
      channel.branch_id_list?.includes(currentBranchId || 0) &&
      channel.payment_name === currentPaymentChannelPaymentName
  );

  // const additionalDiscount: number = useWatch({
  //   control: defaultControl,
  //   name: "additional_discount",
  // });

  const isWithholdingTax = watch(`is_withholding_tax`);

  // useEffect(() => {
  //   if (open && !initialized) {
  //     const shouldActivateWithholdingTax = allWithholdingTax.every(
  //       (tax) => tax === "ยังไม่ระบุ" || tax === "ไม่มี"
  //     );
  //     if (shouldActivateWithholdingTax) {
  //       setValue("is_withholding_tax", false);
  //     } else {
  //       setValue("is_withholding_tax", true);
  //     }

  //     setInitialized(true);
  //   }
  // }, [open, allWithholdingTax, setValue, initialized]);

  // Effect for withholding tax rate and amount
  // useEffect(() => {
  //   // if (
  //   //   allWithholdingTax.every((tax) => tax === "ยังไม่ระบุ" || tax === "ไม่มี")
  //   // ) {
  //   //   setWithholdingTaxRate("กำหนดเอง");
  //   // } else if (allWithholdingTax.every((tax) => tax === allWithholdingTax[0])) {
  //   //   if (paymentWhtAmount && paymentWhtAmount > 0) {
  //   //     setWithholdingTaxRate("กำหนดเอง");
  //   //   } else {
  //   //     setWithholdingTaxRate(allWithholdingTax[0]);
  //   //   }
  //   // } else {
  //   //   setWithholdingTaxRate("กำหนดเอง");
  //   // }
  //   const remainingWhtAmount = paymentWhtAmount;
  //   if (remainingWhtAmount > 0) {
  //     setWithholdingTaxAmount(remainingWhtAmount);
  //   } else {
  //     setWithholdingTaxAmount(0);
  //   }
  // }, [allWithholdingTax, paymentWhtAmount]);

  // useEffect(() => {
  //   const total_pre_vat_amount = itemList.reduce(
  //     (acc, item) => acc + item.pre_vat_amount,
  //     0
  //   );

  //   const post_discount_amount = itemList.reduce((acc, item) => {
  //     const item_additional_discount =
  //       additionalDiscount * (item.pre_vat_amount / total_pre_vat_amount) || 0;

  //     if (
  //       item.withholding_tax_type !== "ยังไม่ระบุ" &&
  //       item.withholding_tax_type !== "ไม่มี"
  //     ) {
  //       if (
  //         item.vat_percentage === "7" &&
  //         priceVatType === PriceVatType.IncludeVat
  //       ) {
  //         return acc + (item.pre_vat_amount - item_additional_discount) / 1.07;
  //       }
  //       return acc + (item.pre_vat_amount - item_additional_discount);
  //     }

  //     return acc;
  //   }, 0);

  //   setPostDiscountWhtAmount(post_discount_amount);
  // }, [additionalDiscount, itemList, priceVatType]);

  const paymentWithholdingTaxRate = watch(`withholding_tax_rate`);
  const currentPaymentAmount = watch("payment_amount");
  const currentPaymentWht = watch("withholding_tax_amount");
  const paymentPreVatAmount = watch("payment_pre_vat_amount");
  // const postDiscountAmount = useWatch({
  //   control: defaultControl,
  //   name: "post_discount_amount",
  // });

  useEffect(() => {
    if (open) {
      const paid_net_amount = getDefaultValues("paid_net_amount") || 0;
      const net_amount = getDefaultValues("net_amount");
      const new_payment_amount = net_amount - paid_net_amount;
      let newPaymentPreVatAmount = 0;
      if (priceVatType === "include_vat") {
        newPaymentPreVatAmount = (new_payment_amount || 0) / 1.07;
      } else {
        newPaymentPreVatAmount = (new_payment_amount || 0) * (100 / 107);
      }

      setValue(`payment_pre_vat_amount`, newPaymentPreVatAmount);
      setValue("payment_date", issueDate);
      setValue("payment_amount", new_payment_amount);
    }
  }, [getDefaultValues, issueDate, open, priceVatType, setValue]);

  // useEffect(() => {
  //   if (open) {
  //     if (isWithholdingTax) {
  //       setValue(`withholding_tax_rate`, withholdingTaxRate);
  //       if (withholdingTaxRate !== "กำหนดเอง") {
  //         const newHolddingTax =
  //           postDiscountWhtAmount * (parseInt(withholdingTaxRate) / 100);
  //         setValue(`withholding_tax_amount`, newHolddingTax);
  //       } else {
  //         setValue(`withholding_tax_amount`, withholdingTaxAmount);
  //       }
  //     } else {
  //       setValue(`withholding_tax_rate`, "");
  //       setValue(`withholding_tax_amount`, 0);
  //     }
  //   }
  // }, [
  //   isWithholdingTax,
  //   open,
  //   postDiscountWhtAmount,
  //   setValue,
  //   withholdingTaxAmount,
  //   withholdingTaxRate,
  // ]);

  useEffect(() => {
    if (open && !isWithholdingTax) {
      setValue(`withholding_tax_rate`, "กำหนดเอง");
      setValue(`withholding_tax_amount`, 0);
    }
  }, [isWithholdingTax, open, setValue]);

  const addPaymentHandler = async (data: ISalesPaymentChannel) => {
    try {
      const {
        price_vat_type,
        currency,
        payment_list,
        deduction_document_list,
        net_amount,
        branch_id,
        unique_id,
        aggrid_status,
      } = getDefaultValues();
      const updatedPaymentList = [data, ...payment_list];

      const formatPaymentList = updatedPaymentList.map(
        ({
          id,
          payment_channel_unique_id_name,
          payment_pre_vat_amount,
          ...otherItem
        }: ISalesPaymentChannel) => ({
          ...otherItem,
          branch_id: branch_id,
          reference_document_type: SalesReferenceDocumentType.SalesInvoice,
          reference_unique_id: unique_id,
        })
      );

      const paymentAmount: number =
        formatPaymentList && formatPaymentList.length > 0
          ? formatPaymentList.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.payment_amount || 0),
              0
            )
          : 0;

      // const paymentWhtAmount: number =
      //   formatPaymentList && formatPaymentList.length > 0
      //     ? formatPaymentList.reduce(
      //         (acc: number, channel: ISalesPaymentChannel) =>
      //           acc + (channel.withholding_tax_amount || 0),
      //         0
      //       )
      //     : 0;

      const deductionAmount: number =
        deduction_document_list && deduction_document_list.length > 0
          ? deduction_document_list.reduce(
              (acc: number, deduct: ISalesDeduction) =>
                acc + (deduct.deduction_amount || 0),
              0
            )
          : 0;

      const paidNetAmount: number = paymentAmount + deductionAmount;

      const paidTotalAmount: number = paidNetAmount;

      if (isValid) {
        const updatedMainStatus =
          parseFloat(paidTotalAmount.toFixed(2)) <
          parseFloat(net_amount.toFixed(2))
            ? "partially_payment"
            : "fully_payment";

        const { SalesInvoiceUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: {
            currency,
            price_vat_type,
            main_status: updatedMainStatus,
            payment_list: formatPaymentList,
            paid_net_amount: paidNetAmount,
            payment_total_amount: paymentAmount,
            deduction_total_amount: deductionAmount,
            paid_total_amount: paidTotalAmount,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          },
        });
        enqueueSnackbar(`รับชำระสำเร็จ`, {
          variant: "success",
        });
        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesInvoice,
          reference_id: SalesInvoiceUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
            prev_status: aggrid_status,
            curr_status: updatedMainStatus,
          },
        });
        onClose();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`รับชำระไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onClose = () => {
    // setInitialized(false);
    reset(salesPaymentChannelSchema);
    closeModalHandler();
  };

  console.log("paidTotalAmount", paidTotalAmount);
  console.log("currentPaymentAmount", currentPaymentAmount);

  return (
    <ModalUI
      title={t("sales.payment.index")}
      open={open}
      handleClose={onClose}
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.add")}
            variant="contained"
            onClick={handleSubmit(addPaymentHandler)}
            disabled={
              parseFloat(paidTotalAmount.toFixed(2)) +
                parseFloat((currentPaymentAmount || 0).toFixed(2)) >
              parseFloat(netAmount.toFixed(2))
            }
          />
        </Box>
      }
    >
      <>
        <Grid container spacing={1.5} mt={0}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSelect
              control={control}
              name={`payment_channel_payment_name`}
              label={t("sales.payment.payment_channel")}
              options={sortedPaymentChannelPaymentNameOptions}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  setValue(`payment_channel_id`, parseInt(""));
                  setValue(`cheque_provider`, "");
                  setValue(`cheque_no`, "");
                  setValue(`cheque_date`, null);
                }
              }}
              error={Boolean(errors?.payment_channel_name)}
              helperText={errors?.payment_channel_name?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSelect
              control={control}
              name={`payment_channel_id`}
              label={t("sales.payment.payment_account")}
              options={paymentChannelAccountOptions}
              disabled={!currentPaymentChannelPaymentName}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  const foundPaymentChannel = paymentChannelAccountOptions.find(
                    (channel) => channel.id === value
                  );
                  setValue(
                    `payment_channel_unique_id`,
                    foundPaymentChannel?.unique_id || ""
                  );
                  setValue(
                    `payment_channel_name`,
                    foundPaymentChannel?.name || ""
                  );
                  setValue(
                    `payment_channel_unique_id_name`,
                    foundPaymentChannel?.label || ""
                  );
                }
              }}
              error={Boolean(errors?.payment_channel_id)}
              helperText={errors?.payment_channel_id?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledNumberTextField
              textAlign="right"
              fullWidth
              control={control}
              name={`payment_amount`}
              label={t("sales.payment.payment_amount")}
              required
              error={Boolean(errors?.payment_amount)}
              helperText={errors?.payment_amount?.message}
              onChange={(value) => {
                let newPaymentPreVatAmount = 0;
                if (priceVatType === "include_vat") {
                  newPaymentPreVatAmount = (value || 0) / 1.07;
                } else {
                  newPaymentPreVatAmount = (value || 0) * (100 / 107);
                }

                setValue(`payment_pre_vat_amount`, newPaymentPreVatAmount);

                if (paymentWithholdingTaxRate !== "กำหนดเอง") {
                  if (priceVatType === "include_vat") {
                    const withholding_tax_amount = parseFloat(
                      (
                        (((value || 0) / 1.07) *
                          parseFloat(paymentWithholdingTaxRate)) /
                        100
                      ).toFixed(2)
                    );
                    setValue(`withholding_tax_amount`, withholding_tax_amount);
                  } else {
                    const withholding_tax_amount = parseFloat(
                      (
                        ((newPaymentPreVatAmount || 0) *
                          parseFloat(paymentWithholdingTaxRate)) /
                        100
                      ).toFixed(2)
                    );
                    setValue(`withholding_tax_amount`, withholding_tax_amount);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledDatePicker
              control={control}
              name={`payment_date`}
              label={t("sales.payment.payment_date")}
              required
            />
          </Grid>
          {currentPaymentChannelPaymentName === "เช็ค" && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledSSCreatable
                  control={control}
                  label={t("sales.payment.cheque_provider")}
                  name={`cheque_provider`}
                  defaultOptions={bankNameOptions}
                  documentType="payment_channel"
                  dataName="cheque_provider"
                  setValue={setValue}
                  required
                  error={Boolean(errors?.cheque_provider)}
                  helperText={errors?.cheque_provider?.message}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  label={t("sales.payment.cheque_no")}
                  name={`cheque_no`}
                  required
                  error={Boolean(errors?.cheque_no)}
                  helperText={errors?.cheque_no?.message}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledDatePicker
                  control={control}
                  label={t("sales.payment.cheque_date")}
                  name={`cheque_date`}
                  required
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ControlledCheckbox
              control={control}
              name={`is_withholding_tax`}
              label={t("sales.payment.is_withholding_tax")}
            />
          </Grid>
          {isWithholdingTax && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  control={control}
                  name={`withholding_tax_rate`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      freeSolo
                      disableClearable
                      options={withholdingTaxTypeOption}
                      label={t("sales.payment.withholding_tax_rate")}
                      onChange={(_, newValue) => {
                        field.onChange(newValue ? newValue.value : "");
                      }}
                      onInputChange={(_, newValue) => {
                        if (newValue) {
                          field.onChange(newValue);
                          if (newValue === "กำหนดเอง") {
                            setValue(`withholding_tax_amount`, 0);
                          } else {
                            if (priceVatType === "include_vat") {
                              const withholding_tax_amount = parseFloat(
                                (
                                  ((currentPaymentAmount / 1.07) *
                                    parseFloat(newValue)) /
                                  100
                                ).toFixed(2)
                              );
                              setValue(
                                `withholding_tax_amount`,
                                withholding_tax_amount
                              );
                            } else {
                              const withholding_tax_amount = parseFloat(
                                (
                                  ((paymentPreVatAmount || 0) *
                                    parseFloat(newValue)) /
                                  100
                                ).toFixed(2)
                              );
                              setValue(
                                `withholding_tax_amount`,
                                withholding_tax_amount
                              );
                            }
                          }
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      error={Boolean(errors?.withholding_tax_rate)}
                      helperText={errors?.withholding_tax_rate?.message}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledNumberTextField
                  textAlign="right"
                  fullWidth
                  control={control}
                  name={`withholding_tax_amount`}
                  label={t("sales.payment.withholding_tax_amount")}
                  disabled={paymentWithholdingTaxRate !== "กำหนดเอง"}
                  helperTextAlign="right"
                  error={Boolean(errors?.withholding_tax_amount)}
                  helperText={errors?.withholding_tax_amount?.message}
                  required
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              label={t("sentence.remark")}
              control={control}
              name={`remark`}
              multiline
              minRows={1}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </>
      <Box mt={2}>
        <PaymentSummary
          isModal
          currentPaymentAmount={currentPaymentAmount}
          currentPaymentWht={currentPaymentWht}
        />
      </Box>
    </ModalUI>
  );
};

export default PaymentModal;
