import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IRole } from "../../../types/Auth/role";
import {
  roleSchema,
  roleValidation,
} from "../../../components/Form/Role/schema";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useEffect, useMemo, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import RoleDetail from "../../../components/Form/Role/Detail";
import RoleAccess from "../../../components/Form/Role/Access";
import RoleEmployee from "../../../components/Form/Role/Employee";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  mergeAllPermissions,
  roleCreateFormatter,
  roleQueryFormatter,
  roleUpdateFormatter,
} from "../../../utils/Formatter/Role";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  RoleFindUniqueQuery,
  useRoleCreateMutation,
  useRoleDeleteMutation,
  useRoleFindUniqueQuery,
  useRoleUpdateMutation,
} from "../../../generated/general";
import { useModal } from "../../../hooks/use-modal";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import UpdateRoleModal from "../../../components/UI/Modal/UpdateRoleModal";
import { useStateContext } from "../../../contexts/auth-context";
import {
  areArraysOfObjectsSame,
  errorMessageFormatter,
} from "../../../utils/Global";

const RoleContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IRole>({
    defaultValues: roleSchema,
    resolver: yupResolver<any>(roleValidation),
  });

  const { handleSubmit, reset, getValues } = methods;

  const selectModifyOptions = [
    ...(permissions?.role?.update
      ? [
          {
            value: "แก้ไข",
            disabled: isEdit,
          },
        ]
      : []),
    ...(permissions?.role?.delete
      ? [
          {
            value: "ลบ",
            disabled: false,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    useRoleFindUniqueQuery<RoleFindUniqueQuery>(
      graphqlClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  useEffect(() => {
    if (isSuccess) {
      const { RoleFindUnique } = data;
      const roleData = roleQueryFormatter(RoleFindUnique);
      reset(roleData);
    }
  }, [data, isSuccess, reset]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.role.index"),
      to: "/user/role",
    },
    {
      name:
        id && data && data.RoleFindUnique?.name
          ? data.RoleFindUnique?.name
          : t("user.role.add_new_role"),
    },
  ];

  const { mutateAsync: create, isLoading: isCreating } =
    useRoleCreateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: update, isLoading: isUpdating } =
    useRoleUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: deleteRole, isLoading: isDeleting } =
    useRoleDeleteMutation<Error>(graphqlClientGeneral);

  const roleCreateHandler = async (data: IRole) => {
    try {
      const formatData = roleCreateFormatter(data);
      const { RoleCreate } = await create({
        data: formatData,
      });
      enqueueSnackbar(
        `${t("button.create")}${t("user.role.permission")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      navigate(`/user/role/${RoleCreate?.id}?tab=inventory`);
    } catch (err) {
      const formatError = errorMessageFormatter(err, "role");
      enqueueSnackbar(
        formatError ||
          `${t("button.create")}${t("user.role.permission")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const permissionNames = useMemo(
    () => [
      "inventory_permission",
      "contact_permission",
      "sales_permission",
      "purchase_permission",
      "user_permission",
      "setting_permission",
      "marketing_permission",
      "logistic_permission",
    ],
    []
  );

  const roleUpdateHandler = async (submittedData: IRole) => {
    try {
      const { id, ...otherData } = submittedData;
      const existingData = data?.RoleFindUnique;

      if (existingData) {
        const { role_permission } = existingData;
        const mergedPermissions = mergeAllPermissions(role_permission || {});
        const {
          inventory_permission,
          contact_permission,
          sales_permission,
          purchase_permission,
          user_permission,
          setting_permission,
          marketing_permission,
          logistic_permission,
        } = submittedData;

        const currentPermissions = [
          inventory_permission,
          contact_permission,
          sales_permission,
          purchase_permission,
          user_permission,
          setting_permission,
          marketing_permission,
          logistic_permission,
        ];

        const isEdit = permissionNames.some(
          (name, index) =>
            !areArraysOfObjectsSame(
              mergedPermissions[name],
              currentPermissions[index]
            )
        );

        if (isEdit && otherData.user_id_list.length > 0) {
          openUpdateRoleModal();
        } else {
          const formatData = roleUpdateFormatter(otherData);
          await update({
            uniqueInput: {
              id,
            },
            data: formatData,
          });
          await refetch();
          enqueueSnackbar(
            `${t("button.edit")}${t("user.role.permission")}สำเร็จ`,
            {
              variant: "success",
            }
          );
          setDisabled(true);
        }
      }
    } catch (err) {
      const formatError = errorMessageFormatter(err, "role");
      enqueueSnackbar(
        formatError ||
          `${t("button.edit")}${t("user.role.permission")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
    setIsEdit(false);
  };

  const roleDeleteHandler = async () => {
    try {
      await deleteRole({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`ลบ${t("user.role.permission")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/user/role");
    } catch (err) {
      enqueueSnackbar(`ลบ${t("user.role.permission")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(roleDeleteHandler);

  const {
    modal: updateRoleModal,
    openModalHandler: openUpdateRoleModal,
    closeModalHandler: closeUpdateRoleHandler,
  } = useModal();

  const updateHandler = async (updateMode: any) => {
    try {
      const data = getValues();
      const { id, ...otherData } = data;
      const formatData = roleUpdateFormatter(otherData, updateMode.mode);
      await update({
        uniqueInput: {
          id,
        },
        data: formatData,
      });
      await refetch();
      enqueueSnackbar(`${t("button.edit")}${t("user.role.permission")}สำเร็จ`, {
        variant: "success",
      });
      setDisabled(true);
    } catch (err) {
      enqueueSnackbar(
        `${t("button.edit")}${t("user.role.permission")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const editCancelHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  if (id && (isLoading || isCreating || isUpdating || isDeleting)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"space-between"}
        maxWidth={1040}
        alignItems={"center"}
        mt={2}
      >
        <Typography variant="h5">{t("user.role.index")}</Typography>
        {(permissions?.role?.update || permissions?.role?.delete) && (
          <CustomizedMenuOptions
            size="small"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setDisabled(false);
                  setIsEdit(true);
                  break;
                case "ลบ":
                  openConfirmationHandler();
                  break;

                default:
                  break;
              }
            }}
            disabled={!id}
          />
        )}
      </Box>
      <FormProvider {...methods}>
        <RoleDetail />
        <RoleAccess />
        <RoleEmployee />
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={!id ? () => navigate("/user/role") : editCancelHandler}
                disabled={isCreating || isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={
                  id
                    ? handleSubmit(roleUpdateHandler)
                    : handleSubmit(roleCreateHandler)
                }
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
        <ConfirmationModal
          title={`ยืนยันการลบ${t("user.role.permission")}`}
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
        <UpdateRoleModal
          open={updateRoleModal}
          closeModalHandler={closeUpdateRoleHandler}
          updateHandler={updateHandler}
          roleName={data?.RoleFindUnique?.name || ""}
        />
      </FormProvider>
    </>
  );
};

export default RoleContainer;
