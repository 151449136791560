import { PaletteColorOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    icon?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    icon: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: { light: "#EFF2FF", main: "#324290", dark: "#000E58" },
    secondary: { light: "#C4CAE4", main: "#32418F", dark: "#1E276C" },
    error: { light: "#FEECEB", main: "#F44336", dark: "#621B16" },
    warning: { light: "#FFF4E5", main: "#ED6C02", dark: "#E65100" },
    info: { light: "#E9F5FE", main: "#2196F3", dark: "#0D3C61" },
    success: { light: "#EDF7ED", main: "#4CAF50", dark: "#1E4620" },
    icon: { main: "#2167D3" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ["Kanit", "sans-serif"].join(","),
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#333333",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
      color: "#333333",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
      color: "#333333",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
      color: "#333333",
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "none",
      color: "#333333",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375,
      color: "#333333",
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375,
      color: "#333333",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375,
      color: "#333333",
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
      color: "#333333",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.375,
      color: "#333333",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.375,
      color: "#333333",
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&": {
            // lineHeight: "34px",
            color: "rgba(0, 0, 0, 0.6)",
          },
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)",
          },
        },
        // shrink: {
        //   "&": {
        //     top: "-4px",
        //   },
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&": {
            // lineHeight: "34px",
            backgroundColor: "white",
            borderRadius: "8px",
          },
          "&.Mui-disabled": {
            backgroundColor: "#F2F2F2",
          },
        },
        input: {
          "&": {
            // lineHeight: "34px",
            // height: "34px",
          },
          "&.Mui-disabled": {
            WebkitTextFillColor: "#737373",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 9999 + "!important",
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     body: {
    //       "&": {
    //         padding: ".5rem",
    //       },
    //     },
    //   },
    // },
    // MuiIcon: {
    //   styleOverrides: {
    //     root: {
    //       color: "#2167D3 !important",
    //     },
    //   },
    // },
  },
});
