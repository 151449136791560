import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Box, Grid, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useSalesOption } from "../../../../hooks/Sales/use-sales-option";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSSCreatable from "../../../Controller/ControlledSSCreatable";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useDisable } from "../../../../hooks/use-disable";
import LabelInput from "../../../UI/LabelInput";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { useEffect } from "react";
import { IOptionsCreatable } from "../../../../types/global";
import { ICashSales } from "../../../../types/Sales/cashSales";
import { bankNameOptions } from "../../../../utils/Global";

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { id: 0, value: "กำหนดเอง", label: "กำหนดเอง" },
  { id: 1, value: "0.75", label: "0.75" },
  { id: 2, value: "1", label: "1" },
  { id: 3, value: "1.5", label: "1.5" },
  { id: 4, value: "2", label: "2" },
  { id: 5, value: "3", label: "3" },
  { id: 6, value: "5", label: "5" },
  { id: 7, value: "10", label: "10" },
  { id: 8, value: "15", label: "15" },
];

type Props = {
  index: number;
  documentType: string;
};

const PaymentInfo = ({ index, documentType }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ICashSales>();

  const { paymentChannelPaymentName, paymentChannelAccount } =
    useSalesOption(documentType);

  const currentBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const priceVatType = useWatch({
    control,
    name: "price_vat_type",
  });

  const currentPaymentChannelPaymentName = useWatch({
    control,
    name: `payment_list.${index}.payment_channel_payment_name`,
  });

  const paymentChannelPaymentNameOptions = paymentChannelPaymentName.filter(
    (channel) => channel.branch_id_list?.includes(currentBranchId)
  );

  const paymentOrder = ["เงินสด", "บัญชีธนาคาร", "เช็ค"];

  const sortedPaymentChannelPaymentNameOptions =
    paymentChannelPaymentNameOptions.sort((a, b) => {
      const priorityA = paymentOrder.indexOf(a.label);
      const priorityB = paymentOrder.indexOf(b.label);

      // Handle missing priorities by treating them as having the lowest priority
      const priorityAIndex = priorityA === -1 ? paymentOrder.length : priorityA;
      const priorityBIndex = priorityB === -1 ? paymentOrder.length : priorityB;

      return priorityAIndex - priorityBIndex;
    });

  const paymentChannelAccountOptions = paymentChannelAccount.filter(
    (channel) =>
      channel.branch_id_list?.includes(currentBranchId || 0) &&
      channel.payment_name === currentPaymentChannelPaymentName
  );

  const paymentAmount = useWatch({
    control,
    name: `payment_list.${index}.payment_amount`,
  });

  const paymentPreVatAmount = useWatch({
    control,
    name: `payment_list.${index}.payment_pre_vat_amount`,
  });

  const isWithholdingTax = useWatch({
    control,
    name: `payment_list.${index}.is_withholding_tax`,
  });

  const paymentWithholdingTaxRate = useWatch({
    control,
    name: `payment_list.${index}.withholding_tax_rate`,
  });

  const paymentWithholdingTaxAmount = useWatch({
    control,
    name: `payment_list.${index}.withholding_tax_amount`,
  });

  const issueDate = useWatch({
    control,
    name: "issue_date",
  });

  useEffect(() => {
    if (!isWithholdingTax) {
      setValue(`payment_list.${index}.withholding_tax_rate`, "กำหนดเอง");
      setValue(`payment_list.${index}.withholding_tax_amount`, 0);
    }
    setValue(`payment_list.${index}.payment_date`, issueDate);
    let newPaymentPreVatAmount = 0;
    if (priceVatType === "include_vat") {
      newPaymentPreVatAmount = (paymentAmount || 0) / 1.07;
    } else {
      newPaymentPreVatAmount = (paymentAmount || 0) * (100 / 107);
    }

    setValue(
      `payment_list.${index}.payment_pre_vat_amount`,
      newPaymentPreVatAmount
    );
    clearErrors(`payment_list.${index}.payment_date`);
  }, [
    clearErrors,
    index,
    isWithholdingTax,
    issueDate,
    paymentAmount,
    priceVatType,
    setValue,
  ]);

  const [disabled] = useDisable();

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          {disabled ? (
            <LabelInput
              label={t("sales.payment.payment_channel")}
              value={getValues(
                `payment_list.${index}.payment_channel_payment_name`
              )}
            />
          ) : (
            <ControlledSelect
              control={control}
              name={`payment_list.${index}.payment_channel_payment_name`}
              label={t("sales.payment.payment_channel")}
              options={sortedPaymentChannelPaymentNameOptions}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  setValue(
                    `payment_list.${index}.payment_channel_id`,
                    parseInt("")
                  );
                  setValue(`payment_list.${index}.cheque_provider`, "");
                  setValue(`payment_list.${index}.cheque_no`, "");
                  setValue(`payment_list.${index}.cheque_date`, null);
                }
              }}
              error={Boolean(
                errors?.payment_list &&
                  errors?.payment_list[index] &&
                  errors?.payment_list[index]?.payment_channel_payment_name
              )}
              helperText={
                errors.payment_list &&
                errors?.payment_list[index]?.payment_channel_payment_name
                  ?.message
              }
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {disabled ? (
            <LabelInput
              label={t("sales.payment.payment_account")}
              value={getValues(
                `payment_list.${index}.payment_channel_unique_id_name`
              )}
            />
          ) : (
            <ControlledSelect
              control={control}
              name={`payment_list.${index}.payment_channel_id`}
              label={t("sales.payment.payment_account")}
              options={paymentChannelAccountOptions}
              disabled={!currentPaymentChannelPaymentName}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  const foundPaymentChannel = paymentChannelAccountOptions.find(
                    (channel) => channel.id === value
                  );
                  setValue(
                    `payment_list.${index}.payment_channel_unique_id`,
                    foundPaymentChannel?.unique_id || ""
                  );
                  setValue(
                    `payment_list.${index}.payment_channel_name`,
                    foundPaymentChannel?.name || ""
                  );
                  setValue(
                    `payment_list.${index}.payment_channel_unique_id_name`,
                    foundPaymentChannel?.label || ""
                  );
                }
              }}
              error={Boolean(
                errors?.payment_list &&
                  errors?.payment_list[index] &&
                  errors?.payment_list[index]?.payment_channel_id
              )}
              helperText={
                errors.payment_list &&
                errors?.payment_list[index]?.payment_channel_id?.message
              }
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledNumberTextField
            textAlign="right"
            fullWidth
            control={control}
            name={`payment_list.${index}.payment_amount`}
            label={t("sales.payment.payment_amount")}
            viewMode={disabled}
            required
            error={Boolean(
              errors?.payment_list &&
                errors?.payment_list[index] &&
                errors?.payment_list[index]?.payment_amount
            )}
            helperText={
              errors.payment_list &&
              errors?.payment_list[index]?.payment_amount?.message
            }
            onChange={(value) => {
              const withholding_tax_rate = getValues(
                `payment_list.${index}.withholding_tax_rate`
              );

              let newPaymentPreVatAmount = 0;

              if (priceVatType === "include_vat") {
                newPaymentPreVatAmount = (value || 0) / 1.07;
              } else {
                newPaymentPreVatAmount = (value || 0) * (100 / 107);
              }

              setValue(
                `payment_list.${index}.payment_pre_vat_amount`,
                newPaymentPreVatAmount
              );

              if (withholding_tax_rate !== "กำหนดเอง") {
                if (priceVatType === "include_vat") {
                  const withholding_tax_amount = parseFloat(
                    (
                      (((value || 0) / 1.07) *
                        parseFloat(withholding_tax_rate)) /
                      100
                    ).toFixed(2)
                  );
                  setValue(
                    `payment_list.${index}.withholding_tax_amount`,
                    withholding_tax_amount
                  );
                } else {
                  const withholding_tax_amount = parseFloat(
                    (
                      ((newPaymentPreVatAmount || 0) *
                        parseFloat(withholding_tax_rate)) /
                      100
                    ).toFixed(2)
                  );
                  setValue(
                    `payment_list.${index}.withholding_tax_amount`,
                    withholding_tax_amount
                  );
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledDatePicker
            control={control}
            name={`payment_list.${index}.payment_date`}
            label={t("sales.payment.payment_date")}
            viewMode={disabled}
            required
          />
        </Grid>
        {currentPaymentChannelPaymentName === "เช็ค" && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledSSCreatable
                control={control}
                label={t("sales.payment.cheque_provider")}
                name={`payment_list.${index}.cheque_provider`}
                defaultOptions={bankNameOptions}
                documentType="payment_channel"
                dataName="cheque_provider"
                setValue={setValue}
                viewMode={disabled}
                required
                error={Boolean(
                  errors?.payment_list &&
                    errors?.payment_list[index] &&
                    errors?.payment_list[index]?.cheque_provider
                )}
                helperText={
                  errors.payment_list &&
                  errors?.payment_list[index]?.cheque_provider?.message
                }
              />
            </Grid>
            <Box width="100%" />
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                control={control}
                label={t("sales.payment.cheque_no")}
                name={`payment_list.${index}.cheque_no`}
                required
                viewMode={disabled}
                error={Boolean(
                  errors?.payment_list &&
                    errors?.payment_list[index] &&
                    errors?.payment_list[index]?.cheque_no
                )}
                helperText={
                  errors.payment_list &&
                  errors?.payment_list[index]?.cheque_no?.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledDatePicker
                control={control}
                label={t("sales.payment.cheque_date")}
                name={`payment_list.${index}.cheque_date`}
                required
                viewMode={disabled}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ControlledCheckbox
            control={control}
            name={`payment_list.${index}.is_withholding_tax`}
            label={t("sales.payment.is_withholding_tax")}
            disabled={disabled}
          />
        </Grid>
        {isWithholdingTax && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              {disabled ? (
                <LabelInput
                  label={t("sales.payment.withholding_tax_rate")}
                  value={getValues(
                    `payment_list.${index}.withholding_tax_rate`
                  )}
                  endAdornment="%"
                />
              ) : (
                <Controller
                  control={control}
                  name={`payment_list.${index}.withholding_tax_rate`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      freeSolo
                      disableClearable
                      options={withholdingTaxTypeOption}
                      label={t("sales.payment.withholding_tax_rate")}
                      onChange={(_, newValue) => {
                        field.onChange(newValue ? newValue.value : "");
                      }}
                      onInputChange={(_, newValue) => {
                        if (newValue) {
                          field.onChange(newValue);
                          if (newValue === "กำหนดเอง") {
                            setValue(
                              `payment_list.${index}.withholding_tax_amount`,
                              paymentWithholdingTaxAmount
                            );
                          } else {
                            if (priceVatType === "include_vat") {
                              const withholding_tax_amount = parseFloat(
                                (
                                  ((paymentAmount / 1.07) *
                                    parseFloat(newValue)) /
                                  100
                                ).toFixed(2)
                              );
                              setValue(
                                `payment_list.${index}.withholding_tax_amount`,
                                withholding_tax_amount
                              );
                            } else {
                              const withholding_tax_amount = parseFloat(
                                (
                                  ((paymentPreVatAmount || 0) *
                                    parseFloat(newValue)) /
                                  100
                                ).toFixed(2)
                              );
                              setValue(
                                `payment_list.${index}.withholding_tax_amount`,
                                withholding_tax_amount
                              );
                            }
                          }
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      error={Boolean(
                        errors?.payment_list &&
                          errors?.payment_list[index] &&
                          errors?.payment_list[index]?.withholding_tax_rate
                      )}
                      helperText={
                        errors.payment_list &&
                        errors?.payment_list[index]?.withholding_tax_rate
                          ?.message
                      }
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledNumberTextField
                textAlign="right"
                fullWidth
                control={control}
                name={`payment_list.${index}.withholding_tax_amount`}
                label={t("sales.payment.withholding_tax_amount")}
                disabled={paymentWithholdingTaxRate !== "กำหนดเอง"}
                helperTextAlign="right"
                viewMode={disabled}
                error={Boolean(
                  errors?.payment_list &&
                    errors?.payment_list[index] &&
                    errors?.payment_list[index]?.withholding_tax_amount
                )}
                helperText={
                  errors.payment_list &&
                  errors?.payment_list[index]?.withholding_tax_amount?.message
                }
                required
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name={`payment_list.${index}.remark`}
            multiline
            minRows={1}
            maxRows={5}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentInfo;
