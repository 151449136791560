import { Box, Divider, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { ICreatedBy } from "../../../types/global";
import DeliverySignerBox from "../DeliverySignerBox";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../../../generated/general";
import { GraphQLClient } from "graphql-request";
import { formatCompanyName } from "../../../utils/Global";

type Props = {
  created_by: ICreatedBy;
  issue_date: Date;
  status?: string;
  documentType?: string;
};

const LogisticPdfSigner = ({ created_by, issue_date }: Props) => {
  const fontSize = 10.66;

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: 1,
      },
    }
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexWrap: "nowrap",
          width: "197.3mm",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography
            sx={{ fontSize: fontSize, fontWeight: 600, lineHeight: 2 }}
          >
            สแกนดูเอกสาร
          </Typography>
          <QRCodeSVG value={window.location.href} size={75} />
        </Box>
        <DeliverySignerBox
          title={"ได้รับสินค้าตามรายการด้านบน"}
          title2={"ไว้เรียบร้อยแล้ว"}
          date={issue_date}
          name={"ผู้รับสินค้า"}
          name2={"ลงวันที่"}
        />
        <Divider orientation="vertical" flexItem />
        <DeliverySignerBox name={"ผู้ส่งสินค้า"} name2={"ลงวันที่"} />
        <Divider orientation="vertical" flexItem />
        <DeliverySignerBox
          title={`ในนาม ${formatCompanyName(
            data?.CompanyFindUnique?.type || "",
            data?.CompanyFindUnique?.name || ""
          )}`}
        />
      </Box>
    </>
  );
};

export default LogisticPdfSigner;
