import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

type Props = {
  isPromotion?: boolean;
};

const useBranchColumnDefs = ({ isPromotion }: Props) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(
      [
        {
          field: "unique_id",
          headerName: `รหัส${t("branch.index")}`,
          filter: "agTextColumnFilter",
        },
        {
          field: "name",
          headerName: t("branch.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "zone",
          headerName: t("branch.zone"),
          filter: "agTextColumnFilter",
        },
        {
          field: "address",
          headerName: t("address.index"),
          filter: "agTextColumnFilter",
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
              const {
                address_name,
                sub_district,
                district,
                province,
                postal_code,
              } = params.value;
              return `${address_name} ${sub_district} ${district} ${province} ${postal_code}`;
            } else {
              return "-";
            }
          },
        },
        !isPromotion && {
          field: "phone",
          headerName: t("branch.phone"),
          filter: "agTextColumnFilter",
        },
        !isPromotion && {
          field: "fax",
          headerName: t("branch.fax"),
          filter: "agTextColumnFilter",
        },
        {
          field: "status",
          headerName: t("branch.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) =>
              value ? "ใช้งาน" : "หยุดใช้งาน",
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ].filter(Boolean) as ColDef[]
    );
  }, [t, isPromotion]);

  return columnDefs;
};

export default useBranchColumnDefs;
