import { useFormContext, useWatch } from "react-hook-form";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../../types/Sales";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import { useEffect } from "react";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

type Props = {
  isModal?: boolean;
  currentPaymentWht?: number;
  currentPaymentAmount?: number;
  currentDeductionAmount?: number;
};

const PaymentSummary = ({
  isModal,
  currentPaymentAmount,
  currentPaymentWht,
  currentDeductionAmount,
}: Props) => {
  const { control, setValue } = useFormContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // const priceVatType = useWatch({
  //   control,
  //   name: "price_vat_type",
  // });
  const paymentChannelList: ISalesPaymentChannel[] =
    useWatch({
      control,
      name: "payment_list",
    }) || [];
  const deductionDocumentList: ISalesDeduction[] =
    useWatch({
      control,
      name: "deduction_document_list",
    }) || [];

  const netAmount: number = useWatch({
    control,
    name: "net_amount",
  });

  const paymentAmount: number =
    (paymentChannelList && paymentChannelList.length > 0
      ? paymentChannelList.reduce(
          (acc, channel) => acc + (channel.payment_amount || 0),
          0
        )
      : 0) + (currentPaymentAmount || 0);

  const deductionAmount: number =
    (deductionDocumentList && deductionDocumentList.length > 0
      ? deductionDocumentList.reduce(
          (acc, deduct) => acc + (deduct.deduction_amount || 0),
          0
        )
      : 0) + (currentDeductionAmount || 0);

  const withholdingTaxAmount: number =
    (paymentChannelList && paymentChannelList.length > 0
      ? paymentChannelList.reduce(
          (acc, channel) => acc + (channel.withholding_tax_amount || 0),
          0
        )
      : 0) + (currentPaymentWht || 0);

  // let paidNetAmount: number = 0;

  const paidNetAmount: number =
    paymentAmount + deductionAmount - withholdingTaxAmount;

  const paidTotalAmount = paidNetAmount + withholdingTaxAmount;

  const remainingPayment =
    parseFloat(netAmount.toFixed(2)) - parseFloat(paidTotalAmount.toFixed(2));

  const summaryList: ISummary[] = [
    {
      label: "มูลค่าชำระ",
      amount: paidNetAmount,
    },
    {
      label: "มูลค่าถูกหัก ณ ที่จ่าย",
      amount: withholdingTaxAmount,
    },
    {
      name: "paid_total_amount",
      label: "ชำระรวมทั้งสิ้น",
      amount: paidTotalAmount,
    },
    {
      label: "ต้องชำระอีก",
      amount: remainingPayment,
    },
  ];

  useEffect(() => {
    if (!isModal) {
      if (deductionAmount) {
        setValue("deduction_total_amount", deductionAmount);
      }

      setValue("payment_total_amount", paymentAmount);
      setValue("paid_net_amount", paidNetAmount);
      setValue("paid_total_amount", paidTotalAmount);
    }
  }, [
    deductionAmount,
    isModal,
    paidNetAmount,
    paidTotalAmount,
    paymentAmount,
    setValue,
  ]);

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} />
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {summaryList.map((item) => (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1.5,
                gap: 3,
                py: item.name === "paid_total_amount" ? 1 : 0,
              }}
              bgcolor={
                item.name === "paid_total_amount" ? "primary.light" : undefined
              }
            >
              <Typography
                color={"primary.main"}
                textAlign={isMobile ? "left" : "right"}
                flex={2}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
              >
                {item.label}
              </Typography>
              <Typography
                color={"primary.main"}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
                sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
              >
                {numberFormatter(item.amount)}
              </Typography>
              <Typography
                color={"primary.main"}
                sx={{ ml: 2.5, mr: 4.7 }}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
              >
                บาท
              </Typography>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
