import { ReactNode } from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "./ModalUI";
import { Box, DialogContentText } from "@mui/material";

interface Props {
  open: boolean;
  title: string;
  message?: ReactNode | string;
  handleClose: () => void;
  action: () => void;
  altTitleColor?: string;
}

const ConfirmationModal = ({
  open,
  title,
  message,
  handleClose,
  action,
  altTitleColor,
}: Props) => {
  return (
    <ModalUI
      title={title}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={handleClose}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={action}
          />
        </Box>
      }
      altTitleColor={altTitleColor}
    >
      {message && <DialogContentText>{message}</DialogContentText>}
    </ModalUI>
  );
};

export default ConfirmationModal;
