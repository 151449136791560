import { CustomerDepositFindUniqueQuery } from "../../../generated/sales";
import dayjs from "dayjs";
import { ICustomerDeposit } from "../../../types/Sales/customerDeposit";
import { SalesReferenceDocumentType } from "../../../generated/inventory";
import { salesPaymentChannelSchema } from "../../../components/Form/Sales/CashSales/schema";
import { IUser } from "../../../types/Auth/user";

export const customerDepositCreateFormatter = (
  data: ICustomerDeposit,
  status: string
) => {
  const {
    deposit_list,
    payment_list,
    customer_details,
    tag_list,
    created_date,
    deduction_document_list,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatDepositList = deposit_list.map(
    ({ reference_document_id, ...otherItem }) => ({
      ...otherItem,
      reference_document_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({
      payment_channel_unique_id_name,
      payment_pre_vat_amount,
      ...otherItem
    }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CustomerDeposit,
      reference_unique_id: data.unique_id,
    })
  );
  const { unique_id_name, ...customer } = customer_details;

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    deposit_list: formatDepositList,
    payment_list: formatPaymentList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    remaining_deposit_amount: otherData.net_amount,
  };
  return formatPayload;
};

export const customerDepositUpdateFormatter = (
  data: ICustomerDeposit,
  status: string
) => {
  const {
    id,
    main_status,
    aggrid_status,
    customer_details,
    last_updated_date,
    deposit_list,
    tag_list,
    created_date,
    branch,
    payment_list,
    deduction_document_list,
    ...otherData
  } = data;

  const { unique_id_name, ...customer } = customer_details;

  const formatDepositList = deposit_list.map(
    ({ reference_document_id, ...otherItem }) => ({
      ...otherItem,
      reference_document_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({
      payment_channel_unique_id_name,
      payment_pre_vat_amount,
      ...otherItem
    }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CustomerDeposit,
      reference_unique_id: data.unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    deposit_list: formatDepositList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    payment_list: formatPaymentList,
    // remaining_deposit_amount: otherData.net_amount,
  };
  return formatPayload;
};

export const customerDepositQueryFormatter = (
  data: CustomerDepositFindUniqueQuery["CustomerDepositFindUnique"]
) => {
  const formatCustomer = {
    ...data.customer_details,
    unique_id_name: `${data.customer_unique_id} - ${data.customer_details.name}`,
  };

  const formatPaymentChannel = data.payment_list?.map(
    ({ payment_date, cheque_date, ...other }) => ({
      ...other,
      payment_channel_unique_id_name: `${other.payment_channel_unique_id} - ${other.payment_channel_name}`,
      payment_date: payment_date ? dayjs(payment_date) : undefined,
      cheque_date: cheque_date ? dayjs(cheque_date) : undefined,
    })
  );

  const formatPayload = {
    ...data,
    customer_details: formatCustomer,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    due_date: data.due_date ? dayjs(data.due_date) : undefined,
    payment_list: formatPaymentChannel,
  } as ICustomerDeposit;

  return formatPayload;
};

export const copyCustomerDepositFormatter = (
  data: ICustomerDeposit,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      created_by,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      deposit_list,
      last_updated_date,
      branch,
      remaining_deposit_amount,
      payment_list,
      paid_total_amount,
      payment_total_amount,
      // related_user_list,
      external_reference_no,
      is_original_print,
      tax_invoice_no,
      ...otherData
    } = data;

    const formatItemList =
      deposit_list && deposit_list.length > 0
        ? deposit_list.map(
            ({ id, reference_document_id, ...otherItemList }) => ({
              ...otherItemList,
            })
          )
        : [];

    // const formatRelatedUserList =
    //   created_by?.user_unique_id !== currentUser?.unique_id
    //     ? [created_by, ...related_user_list]
    //     : related_user_list;

    return {
      ...otherData,
      deposit_list: formatItemList,
      copied_id: id,
      copied_unique_id: unique_id,
      paid_total_amount: 0,
      paid_net_amount: 0,
      payment_list: [
        { ...salesPaymentChannelSchema, payment_date: dayjs().toISOString() },
      ],
      created_date: dayjs().toISOString(),
      issue_date: dayjs().toISOString(),
      due_date: dayjs().toISOString(),
      // related_user_list: formatRelatedUserList,
    };
  }
};
