import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseReturnOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_approved":
        setSelectedModifyOptions([defaultOptions[1]]); // ยกเลิก, ตัดลอก
        break;
      default:
        setSelectedModifyOptions([defaultOptions[0], defaultOptions[1]]);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const usePurchaseReturnCreate = (
  status?: string | null,
  disabledCreate?: boolean
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "ใบ" + t("inventory.goods_issue.index"),
        disabled: disabledCreate,
      },
    ],
    [t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
