import { IPermission } from "../../types/Auth/role";

export const purchasePermissionValue: IPermission[] = [
  {
    variableName: "purchase",
    name: "จัดซื้อ",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "purchase_request",
    name: "ใบขอซื้อ",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "purchase_order",
    name: "ใบสั่งซื้อ",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "purchase_return",
    name: "ใบส่งคืน",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "purchase_report",
    name: "รายงาน​",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "purchase_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
