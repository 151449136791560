import { useFormContext, useWatch } from "react-hook-form";
import { IUser } from "../types/Auth/user";
import { IPermission } from "../types/Auth/role";
import { areArraysOfObjectsSame } from "../utils/Global";
import { useEffect, useMemo } from "react";

const useUserPermission = (
  selectedPermission: Record<string, IPermission[]> | undefined
) => {
  const { control, setValue } = useFormContext<IUser>();

  // Permission names
  const permissionNames = useMemo(
    () => [
      "inventory_permission",
      "contact_permission",
      "sales_permission",
      "purchase_permission",
      "user_permission",
      "setting_permission",
      "marketing_permission",
      "logistic_permission",
    ],
    []
  );

  const currentPermissions = useWatch({
    control,
    name: [
      "inventory_permission",
      "contact_permission",
      "sales_permission",
      "purchase_permission",
      "user_permission",
      "setting_permission",
      "marketing_permission",
      "logistic_permission",
    ],
  });

  const roleId = useWatch({
    control,
    name: "role_id",
  });

  useEffect(() => {
    if (roleId && roleId !== -1 && selectedPermission) {
      const isCustom = permissionNames.some(
        (name, index) =>
          !areArraysOfObjectsSame(
            selectedPermission[name],
            currentPermissions[index]
          )
      );

      setValue("is_custom", isCustom);
    } else {
      setValue("is_custom", false);
    }
  }, [
    currentPermissions,
    selectedPermission,
    setValue,
    permissionNames,
    roleId,
  ]);
};

export default useUserPermission;
