import { GraphQLClient } from "graphql-request";
import { useStateContext } from "../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { useEffect } from "react";
import { UserQuery, useUserQuery } from "../generated/general";

export const usePermission = () => {
  const {
    state: { authUser },
    dispatch,
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const branch_id = sessionStorage.getItem("branch-id");

  const { isLoading, refetch } = useUserQuery<UserQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: authUser?.id,
      },
    },
    {
      enabled: Boolean(authUser?.id && branch_id),
    }
  );

  useEffect(() => {
    if (authUser && branch_id) {
      const getAccess = async () => {
        const { data } = await refetch();
        const permissions = data?.UserFindUnique?.user_permission || {};
        dispatch({
          type: "SET_PERMISSION",
          payload: permissions,
        });
      };
      getAccess();
    }
  }, [authUser, branch_id, dispatch, refetch]);

  return branch_id && authUser && isLoading;
};
