import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Stack, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { IImporterError } from "../../../types/global";

interface Props {
  data: IImporterError[];
  altStyle?: boolean;
  type?: string;
}

interface ExpandedGroups {
  [key: string]: boolean;
}

const ImporterErrorTable = ({ data, altStyle, type }: Props) => {
  const [expandedGroups, setExpandedGroups] = useState<ExpandedGroups>({});

  const toggleGroup = (group: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  // Group data by the 'group' property
  const groupedData: Record<string, IImporterError[]> = data.reduce(
    (acc: any, item) => {
      acc[item.case] = acc[item.case] || [];
      acc[item.case].push(item);
      return acc;
    },
    {}
  );

  return (
    <CustomizedBox
      padding={type === "coupon" ? "0" : "0.5rem 1.5rem"}
      margin={0}
    >
      <TableContainer
        sx={{
          borderRadius: "14px",
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            overflow: "scroll",
          }}
          aria-label="simple table"
        >
          <TableHead
            sx={(theme) => ({
              backgroundColor: type === "coupon" ? "#F0F1FA" : undefined,
              "& .MuiTypography-root": {
                color:
                  type === "coupon" ? "#333333" : theme.palette.primary.main,
              },
            })}
          >
            <TableRow>
              <TableCell width={"25%"}>
                <Typography fontWeight={600}>สาเหตุ</Typography>
              </TableCell>
              {type === "coupon" && (
                <TableCell width="20%">
                  <Typography fontWeight={600}>รหัสคูปอง</Typography>
                </TableCell>
              )}
              <TableCell width={type === "coupon" ? "17.5%" : "25%"}>
                <Typography fontWeight={600}>ข้อมูล</Typography>
              </TableCell>
              <TableCell width={type === "coupon" ? "22.5%" : "35%"}>
                <Typography fontWeight={600}>
                  {type === "coupon" ? "รายละเอียด" : "ค่า"}
                </Typography>
              </TableCell>
              <TableCell width={"15%"}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedData).map((group) => (
              <React.Fragment key={group}>
                <TableRow
                  sx={{
                    bgcolor: altStyle ? "#FFFFFF" : "#FFF4E6",
                  }}
                >
                  <TableCell>{group}</TableCell>
                  <TableCell />
                  <TableCell />
                  {type === "coupon" && <TableCell />}
                  <TableCell>
                    <Stack
                      direction={"row"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Typography>
                        {groupedData[group].length} รายการ
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => toggleGroup(group)}
                        sx={{
                          color: type === "coupon" ? "#32418F" : "#333333",
                        }}
                      >
                        {expandedGroups[group] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
                {expandedGroups[group] &&
                  groupedData[group].map((item, index) => {
                    if (index < 9) {
                      return (
                        <TableRow
                          key={`${item.case}${item.name}-${index}`}
                          sx={{
                            bgcolor: altStyle ? "#FFF4E6" : "#FFFFFF",
                          }}
                        >
                          <TableCell />
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          {type === "coupon" && (
                            <TableCell>{item.description}</TableCell>
                          )}
                          <TableCell />
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomizedBox>
  );
};

export default ImporterErrorTable;
