import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { forwardRef } from "react";
import { ISelectOption } from "../../types/global";

type ExtendedSelectProps = SelectProps & {
  options: ISelectOption[];
  helperText?: any;
  required?: boolean;
  helperTextSize?: number;
};

const CustomizedSelect = forwardRef<HTMLInputElement, ExtendedSelectProps>(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      options,
      disabled,
      error,
      helperText,
      sx,
      color,
      renderValue,
      defaultValue,
      required,
      helperTextSize,
      inputRef,
      inputProps,
      MenuProps,
      placeholder,
    },
    ref
  ) => {
    return (
      <FormControl
        size="small"
        fullWidth
        disabled={disabled}
        error={error}
        ref={ref}
        color={color}
      >
        <InputLabel id="demo-simple-select-label" required={required}>
          {label}
        </InputLabel>
        <Select
          id={id}
          error={error}
          name={name}
          value={value || value === 0 ? value : ""}
          label={label}
          onChange={onChange}
          inputRef={inputRef}
          sx={sx}
          size="small"
          renderValue={renderValue}
          defaultValue={defaultValue || ""}
          required={required}
          inputProps={inputProps}
          MenuProps={MenuProps}
          placeholder={placeholder}
        >
          {options &&
            options.map((option) => {
              return (
                <MenuItem
                  sx={{
                    // maxWidth: 500,
                    wordBreak: "break-word",
                    wordWrap: "break-word",
                    textWrap: "wrap",
                  }}
                  value={option.value}
                  key={option.label}
                  disabled={option.disabled}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <label>{option.label}</label>
                    {option.badge}
                  </Box>
                </MenuItem>
              );
            })}
        </Select>
        {helperText && (
          <FormHelperText sx={{ fontSize: helperTextSize }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CustomizedSelect;
