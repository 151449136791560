import { RoleFindUniqueQuery, RoleUpdateInput } from "../../generated/general";
import { IPermission, IRole } from "../../types/Auth/role";
import { contactPermissionValue } from "../Permission/Contact";
import { inventoryPermissionValue } from "../Permission/Inventory";
import { logisticPermissionValue } from "../Permission/Logistic";
import { marketingPermissionValue } from "../Permission/Marketing";
import { purchasePermissionValue } from "../Permission/Purchase";
import { salesPermissionValue } from "../Permission/Sales";
import { settingPermissionValue } from "../Permission/Setting";
import { userPermissionValue } from "../Permission/User";

export const transformPermissions = (permissions: IPermission[]) => {
  const permissionInput: any = {};

  permissions.forEach((permission) => {
    const {
      variableName,
      view,
      create,
      update,
      cancel,
      delete: deletePermission,
    } = permission;

    // Add permission to PermissionInput structure

    permissionInput[variableName] = {
      view: view === "open",
      view_self: view === "open_self",
      create,
      update,
      cancel,
      delete: deletePermission,
    };
  });

  return permissionInput;
};

export const roleCreateFormatter = (data: IRole) => {
  const {
    user_list,
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
    ...otherData
  } = data;

  const user_id_list =
    user_list && user_list.length > 0
      ? user_list.map((user) => user.id as number)
      : [];

  const merged_permission = [
    ...inventory_permission,
    ...contact_permission,
    ...sales_permission,
    ...purchase_permission,
    ...user_permission,
    ...setting_permission,
    ...marketing_permission,
    ...logistic_permission,
  ];

  const role_permission = transformPermissions(merged_permission);

  const formatData = {
    ...otherData,
    user_id_list,
    role_permission,
  };

  return formatData;
};

export const defaultPermissionValues = {
  inventory_permission: inventoryPermissionValue,
  contact_permission: contactPermissionValue,
  sales_permission: salesPermissionValue,
  purchase_permission: purchasePermissionValue,
  user_permission: userPermissionValue,
  setting_permission: settingPermissionValue,
  marketing_permission: marketingPermissionValue,
  logistic_permission: logisticPermissionValue,
};

export const mergeAllPermissions = (rolePermissions: Record<string, any>) => {
  return Object.entries(defaultPermissionValues).reduce((acc, [key, value]) => {
    acc[key] = value.map((permission) => {
      const { variableName } = permission;

      // Check if the permission exists in the role_permissions
      if (rolePermissions && rolePermissions[variableName]) {
        const rolePerm = rolePermissions[variableName];

        // Merge role permissions with the existing permissions
        return {
          ...permission,
          view: rolePerm.view
            ? "open"
            : rolePerm.view_self
            ? "open_self"
            : "close",
          create: rolePerm.create ?? permission.create,
          update: rolePerm.update ?? permission.update,
          cancel: rolePerm.cancel ?? permission.cancel,
          delete: rolePerm.delete ?? permission.delete,
        };
      }

      // Return the original permission if no matching role permission
      return permission;
    });

    return acc;
  }, {} as Record<string, IPermission[]>);
};

export const roleQueryFormatter = (
  data: RoleFindUniqueQuery["RoleFindUnique"]
) => {
  const mergedPermissions = mergeAllPermissions(data?.role_permission || {});

  const {
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
  } = mergedPermissions;

  const formatUserList = data?.user_list?.map((user) => ({
    ...user,
    branch_name: user?.original_branch_object?.name,
  }));

  const formatData: IRole = {
    id: data?.id,
    name: data?.name || "",
    description: data?.description || "",
    user_list: formatUserList as any,
    user_id_list: data?.user_id_list || [],
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
  };
  return formatData;
};

export const roleUpdateFormatter = (
  data: IRole,
  apply_mode: "all" | "some" = "some"
) => {
  const {
    user_list,
    inventory_permission,
    contact_permission,
    sales_permission,
    purchase_permission,
    user_permission,
    setting_permission,
    marketing_permission,
    logistic_permission,
    created_date,
    last_updated_date,
    ...otherData
  } = data;

  const user_id_list =
    user_list && user_list.length > 0
      ? user_list.map((user) => user.id as number)
      : [];

  const merged_permission = [
    ...inventory_permission,
    ...contact_permission,
    ...sales_permission,
    ...purchase_permission,
    ...user_permission,
    ...setting_permission,
    ...marketing_permission,
    ...logistic_permission,
  ];

  const role_permission = transformPermissions(merged_permission);

  const formatData = {
    ...otherData,
    user_id_list,
    role_permission,
    apply_mode,
  } as RoleUpdateInput;

  return formatData;
};
