import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  th: {
    translation: {
      report: "รายงาน",
      document_info: "ข้อมูลเอกสาร",
      import: "นำเข้า",
      button: {
        trigger: "trigger",
        back: "ย้อนกลับ",
        add: "เพิ่ม",
        delete: "ลบ",
        create: "สร้าง",
        edit: "แก้ไข",
        accept: "ตอบรับ",
        export: "นำออกข้อมูล",
        import: "นำเข้าข้อมูล",
        download: "ดาวน์โหลด Template",
        filter: "กรองข้อมูล",
        reset_filter: "ล้างตัวกรอง",
        save: "บันทึก",
        save_draft: "บันทึกร่าง",
        confirm: "ยืนยัน",
        cancel: "ยกเลิก",
        cancel_pending_scan: "ยกเลิกจำนวนที่ค้าง",
        choose: "เลือก",
        option: "ตัวเลือก",
        next: "ถัดไป",
        send: "ส่ง",
        not_approve: "ไม่อนุมัติ",
        approve: "อนุมัติ",
        in_transit: "ระหว่างทาง",
        transfer: "โอนย้าย",
        adjust: "ปรับปรุง",
        print_document: "พิมพ์เอกสาร",
        print: "พิมพ์",
        partially_ordered: "สั่งซื้อแล้วบางส่วน",
        fully_ordered: "สั่งซื้อแล้ว",
      },
      global: {
        all: "ทั้งหมด",
        billing_address: "ที่อยู่จดทะเบียน",
        picture: "รูปภาพ",
        price: "ราคา",
        special_price: "ราคาพิเศษ",
        phone_number: "เบอร์โทรศัพท์",
        email: "อีเมล",
        fax: "โทรสาร",
        currency: "สกุลเงิน",
        date_range: "กำหนดช่วงวันที่",
      },
      sentence: {
        choose_customer: "เลือกลูกค้า",
        choose_vendor: "เลือกผู้ขาย",
        choose_requestor: "เลือกผู้ขอ",
        deliver_location: "ที่อยู่จัดส่ง",
        detail: "รายละเอียด",
        unique_id: "เลขที่เอกสาร",
        attachment_list: "เอกสารที่เกี่ยวข้อง",
        type: "ประเภท",
        reference_unique_id: "เอกสารอ้างอิง",
        remark: "หมายเหตุ",
        remark_status: "หมายเหตุสถานะ",
        change_status: "เปลี่ยนสถานะ",
        created_by: "ผู้สร้าง",
        edit: "แก้ไข",
        copy_document_title: "ยืนยันหากต้องการคัดลอกเอกสาร",
        delete_document_title: "ยืนยันหากต้องการยกเลิกเอกสาร",
        delete_document_message: "หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้",
        continue: "ดำเนินการต่อ",
      },
      print: {
        index: "การพิมพ์เอกสาร",
        type: "รูปแบบการพิมพ์",
        original: "ต้นฉบับ",
        copy: "สำเนา",
        warning: "สามารถสั่งพิมพ์เอกสารต้นฉบับได้เพียงครั้งเดียว",
      },
      date: {
        range: "ช่วงเวลา",
        from: "ตั้งแต่",
        to: "จนถึง",
        created_date: "วันที่สร้าง",
        issue_date: "วันที่ออกเอกสาร",
        due_date: "วันครบกำหนด",
        to_date: "ใช้ได้ถึง",
        within_date: "ต้องการภายในวันที่",
        expected_date: "วันประมาณการณ์สินค้า",
        delivery_date: "วันกำหนดส่งของ",
        received_date: "วันที่นำเข้า",
        returned_date: "วันที่รับคืน",
        issued_date: "วันที่นำออก",
        transfered_date: "วันที่โอนย้าย",
        adjusted_date: "วันที่ปรับปรุง",
        start_date: "วันที่เริ่มงาน",
        inactive_date: "วันที่หยุดใช้งาน",
        opened_date: "วันที่เปิด",
        lot_date: "Lot",
        manufactured_date: "วันที่ผลิต",
        expired_date: "วันที่หมดอายุ",
        last_updated_date: "วันที่อัปเดต",
        price_day: "ยืนราคา (วัน)",
        promotion: {
          start_date: "วันที่เริ่ม",
          end_date: "วันที่สิ้นสุด",
        },
      },
      status: {
        index: "สถานะ",
        all: "ทั้งหมด",
        draft: "ร่าง",
        finished: "เสร็จสิ้น",
        cancelled: "ยกเลิก",
        wait_accept: "รอตอบรับ",
        wait_issue: "รอนำออก",
        issued: "นำออกแล้ว",
        accepted: "ตอบรับแล้ว",
        wait_approve: "รออนุมัติ",
        approved: "อนุมัติแล้ว",
        not_approved: "ไม่อนุมัติ",
        wait_payment: "รอรับชำระ",
        partially_payment: "รับชำระแล้วบางส่วน",
        fully_payment: "รับชำระแล้ว",
        expired: "เกินเวลา",
        completed: "สำเร็จ",
        not_completed: "ไม่สำเร็จ",
        active: "ใช้งาน",
        inactive: "หยุดใช้งาน",
        in_active: "หยุดใช้งาน",
        partially_ordered: "สั่งซื้อแล้วบางส่วน",
        fully_ordered: "สั่งซื้อแล้ว",
        partially_imported: "นำเข้าแล้วบางส่วน",
        fully_imported: "นำเข้าแล้ว",
        wait_deliver: "รอจัดส่ง",
        delivering: "กำลังจัดส่ง",
        delivery_success: "จัดส่งสำเร็จ",
        delivery_fail: "จัดส่งไม่สำเร็จ",
        wait_previous_jobs: "รองานอื่น",
        insufficient_ingredient: "รอส่วนประกอบ",
        ready: "พร้อมผลิต",
        manufacturing: "กำลังผลิต",
        is_main_bom: "ใช้เป็นสูตรหลัก",
        pending_manu: "รอผลิต",
        wait_transfer: "รอโอนย้าย",
        late: "เกินเวลา",
        wait_receive: "รอนำเข้า",
        in_transit: "ระหว่างทาง",
        success: "สำเร็จ",
        failed: "ไม่สำเร็จ",
        is_active: "รอสแกน",
        is_scanned: "สแกนแล้ว",
        wait_deduction: "รอตัดชำระ",
        partially_deduction: "ตัดชำระบางส่วน",
        fully_deduction: "ตัดชำระแล้ว",
        coupon: {
          active: "พร้อมใช้งาน",
          used: "ใช้งานแล้ว",
          inactive: "ยกเลิก",
          pending: "รอใช้งาน,",
        },
      },
      warehouse: {
        index: "คลัง",
        unique_id: "รหัสคลัง",
        destination: "คลังปลายทาง",
        destination_unique_id: "รหัสคลังปลายทาง",
        source: "คลังปลายทาง",
        source_unique_id: "รหัสคลังปลายทาง",
      },
      user: require("./user.json"),
      setting: require("./setting.json"),
      inventory: require("./inventory.json"),
      contact: require("./contact.json"),
      address: require("./address.json"),
      sales: require("./sales.json"),
      purchase: require("./purchase.json"),
      logistic: require("./logistic.json"),
      manufacture: require("./manufacture.json"),
      project: require("./project.json"),
      reports: require("./report.json"),
      branch: require("./branch.json"),
      marketing: require("./marketing.json"),
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "th",
  fallbackLng: "th",
  resources,
  returnNull: false,
});
