import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { GraphQLClient } from "graphql-request";

import { IPurchaseReturn } from "../../../types/Purchase/purchaseReturn";
import { IApprovalTemplate, IReviewedBy } from "../../../types/global";
import {
  PurchaseDocumentType,
  PurchaseReturnFindUniqueQuery,
} from "../../../generated/purchase";

import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IPurchaseItemList } from "../../../types/Purchase";
import { IUser } from "../../../types/Auth/user";
import { ITraceEntry } from "../../../types/Inventory";
import {
  InventoryDocumentType,
  Tracability,
} from "../../../generated/inventory";
import { IGoodsIssue } from "../../../types/Inventory/GoodsIssue";

export const purchaseReturnCreatePayloadFormatter = (
  data: IPurchaseReturn,
  status: string,
  approval_step?: number,
  reviewed_by?: IReviewedBy
) => {
  const { item_list, tag_list, created_date, last_updated_by, ...otherData } =
    data;

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_unique_id: data.unique_id,
      reference_document_type: PurchaseDocumentType.PurchaseReturn,
    })
  );

  const formatPayload = {
    ...otherData,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    approval_step: approval_step ? 1 : 0,
    reviewer_list: reviewed_by ? [reviewed_by] : [],
  };
  return formatPayload;
};

export const purchaseReturnUpdatePayloadFormatter = async (
  data: IPurchaseReturn,
  status: string,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  isNotApprove?: boolean,
  reviewed_by?: IReviewedBy
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    branch,
    reviewer_list,
    ...otherData
  } = data;

  const formatItemList = item_list.map(
    ({
      uom_group,
      item_sku_qty,
      branch_id,
      pr_reference_unique_id,
      ...otherItem
    }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseReturn,
      pr_reference_unique_id: pr_reference_unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatFlag =
    !isNotApprove && status === "wait_approve"
      ? flag_status?.filter((flag) => flag !== "not_approved")
      : flag_status;

  const formatPayload = {
    ...otherData,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? (data.aggrid_status === "wait_approve" ||
          (data.aggrid_status === "draft" &&
            approver_list &&
            approver_list.length === 0)) &&
        reviewed_by
        ? !isNotApprove
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
    item_list: formatItemList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    flag_status: formatFlag,
  };
  return formatPayload;
};

export const purchaseReturnQueryFormatter = async (
  data: PurchaseReturnFindUniqueQuery["PurchaseReturnFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const allItemListUniqueId =
    data.item_list && data.item_list.length > 0
      ? data.item_list.map((item) => item?.item_sku_name)
      : [];

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as IPurchaseItemList;

      formatItemList.push({
        ...itemType,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
      });
    });
  }

  const formatPayload = {
    ...data,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    delivery_date: data.delivery_date ? dayjs(data.delivery_date) : undefined,
  } as IPurchaseReturn;

  return formatPayload;
};

export const copyPurchaseReturnFormatter = (data: IPurchaseReturn) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,

      created_by,
      reference_no,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      branch,
      approval_step,
      approver_list,
      reviewer_list,

      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(({ id, unique_id, ...otherItemList }) => ({
            ...otherItemList,
            unique_id: uuidv4(),
          }))
        : [];

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const formatPurchaseItemListToGoodsIssueItemList = (
  data: IPurchaseReturn
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = data.item_list.map((item) => ({
    unique_id: uuidv4(),
    reference_unique_id: "",
    is_stockable: true,
    type: InventoryDocumentType.GoodsIssue,
    item_unique_id: item.item_unique_id || "",
    sku_name: item.item_sku_name || "",
    item_name: item.item_name || "",
    item_img_url: item.item_img_url || [],
    qty: 0,
    document_item_qty: item.qty,
    posted_qty: item.qty_issued,
    uom: { id: item.uom_id, name: item.uom_name },
    cost_price: item.price_per_unit,
    remark: "",
    reference_line_item: {
      line_item_unique_id: item.unique_id,
      line_item_document_type: PurchaseDocumentType.PurchaseReturn,
    },
    tracability: (item.tracability as Tracability) || Tracability.Normal,
    barcode: item.tracability === Tracability.Normal ? item.barcode : "",
    status: item.tracability === "serial" ? undefined : "is_active",
    stock_qty: item.stock_qty,
  }));
  return formatData as ITraceEntry[];
};

export const createGoodsIssueFromPurchaseOrder = (
  data: IPurchaseReturn,
  currentUser: IUser | null
): IGoodsIssue => {
  const formatData = {
    unique_id: "",
    type: "purchase_return",
    reference_unique_id: data.unique_id,
    warehouse_id: undefined,
    created_date: dayjs(),
    posted_date: dayjs(),
    remark: "",
    trace_entry_list: formatPurchaseItemListToGoodsIssueItemList(data),
    created_by: {
      user_unique_id: currentUser?.unique_id,
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      img_url: currentUser?.img_url,
    },
    branch_id: data.branch_id,
    main_status: "",
    aggrid_status: "",
    flag_status: [],
  };

  return formatData as IGoodsIssue;
};
