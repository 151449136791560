import { useEffect, useState } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
};

const useAttributeColumnDefs = (
  onEditHandler?: Props["onEditHandler"],
  onDeleteHandler?: Props["onDeleteHandler"],
  enableCheckbox?: boolean
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const defaultColumnDefs: ColDef[] = [
      {
        field: "name",
        headerName: t("setting.attribute.name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        checkboxSelection: enableCheckbox,
      },
      {
        field: "short_name",
        headerName: t("setting.attribute.short_name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "type",
        headerName: t("setting.attribute.type"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "description",
        headerName: t("setting.attribute.description"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
    ];

    if (onEditHandler && onDeleteHandler) {
      setColumnDefs([
        ...defaultColumnDefs,
        {
          field: "",
          filter: false,
          sortable: false,
          width: 100,
          cellStyle: {
            display: "flex",
            justifyContent: "center",
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data) {
              return (
                <Box>
                  <IconButton onClick={() => onEditHandler(params.data?.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            }
          },
        },
      ]);
    } else {
      setColumnDefs(defaultColumnDefs);
    }
  }, [enableCheckbox, onDeleteHandler, onEditHandler, t]);
  return columnDefs;
};

export default useAttributeColumnDefs;
