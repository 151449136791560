import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import { useStateContext } from "../../contexts/auth-context";
import { hasPermission } from "../../utils/Global";

const Logistic = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.delivery_order.index"),
      to: "/logistic/delivery-order",
      permissionKey: "delivery_order",
    },
    {
      name: t("report"),
      to: "/logistic/report",
      permissionKey: "logistic_report",
    },
  ].filter((item) => hasPermission(item.permissionKey, permissions));

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Logistic;
