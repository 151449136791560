import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { IRadio } from "../../../../types/global";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import { useFormContext } from "react-hook-form";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import { useDisable } from "../../../../hooks/use-disable";
import PromotionItemList from "../../../Table/Marketing/Promotion/ItemList/PromotionItemList";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

const PromotionReceiveType = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const receiveTypeList: IRadio[] = [
    {
      label: t("marketing.promotion.receive_type.percent"),
      value: "percent",
    },
    {
      label: t("marketing.promotion.receive_type.amount"),
      value: "amount",
    },
  ];

  const receiveType = watch("receive_type");
  const watchIsApplied = watch("is_applied");
  const isApplied = Boolean(watchIsApplied);

  return (
    <CustomizedBox margin={0}>
      <Box display={"flex"} gap={0.3} mb={1}>
        <Typography
          fontWeight={600}
          color={Boolean(errors.amount_per_item) ? "error.main" : undefined}
        >
          {t("marketing.promotion.receive_type.index")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>
      <ControlledRadioGroup
        control={control}
        name="receive_type"
        radioLists={receiveTypeList}
        row
        disabled={disabled || isApplied}
      />
      {receiveType && (
        <Box my={1}>
          <Grid
            container
            spacing={1.5}
            sx={
              disabled
                ? {
                    "& > .MuiGrid-item": {
                      paddingLeft: "0 !important",
                    },
                  }
                : {}
            }
          >
            <Grid item xs={12} sm={12} md={6}>
              <ControlledNumberTextField
                fullWidth
                label={t("marketing.promotion.receive_type.amount_per_item")}
                control={control}
                name="amount_per_item"
                error={Boolean(errors.amount_per_item)}
                helperText={errors.amount_per_item?.message}
                textAlign="right"
                endAdornmentMessage={receiveType === "percent" ? "%" : "บาท"}
                viewMode={disabled}
                disabled={isApplied}
                required
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box display={"flex"} my={2} gap={0.3}>
        <Typography
          fontWeight={600}
          color={Boolean(errors.redeem_item) ? "error.main" : undefined}
        >
          {t("marketing.promotion.item.index")}
        </Typography>
        <Typography fontWeight={600} color={"error.main"}>
          *
        </Typography>
      </Box>
      <PromotionItemList fieldArrayName="redeem_item" />
      {Boolean(errors.redeem_item) && (
        <FormHelperText error sx={{ mt: 1 }}>
          {errors.redeem_item?.message}
        </FormHelperText>
      )}
    </CustomizedBox>
  );
};

export default PromotionReceiveType;
