import { IPermission } from "../../types/Auth/role";

export const logisticPermissionValue: IPermission[] = [
  {
    variableName: "logistic",
    name: "ขนส่ง",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "delivery_order",
    name: "ใบส่งของ",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  // {
  //   variableName: "logistic_report",
  //   name: "รายงาน​",
  //   view: "close",
  //   create: false,
  //   update: false,
  //   cancel: false,
  //   delete: false,
  // },
  {
    variableName: "logistic_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
