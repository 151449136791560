import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDisable } from "../../../../../hooks/use-disable";

import ControlledTextField from "../../../../Controller/ControlledTextField";

import { parseFloatFunction } from "../../../../../utils/Global";
import { Tracability } from "../../../../../generated/inventory";

type ExtendedProps = {
  fields: any;
  watchItemIsActive: boolean;
  openDisabledConfirmation: any;
  setSelectedSku: any;
  option_1: any;
};

const OneVariantTable = ({
  option_1,
  fields,
  watchItemIsActive,
  openDisabledConfirmation,
  setSelectedSku,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    // formState: { errors },
  } = useFormContext();
  const [disabled] = useDisable();

  const watchTracability = useWatch({ control, name: "tracability" });

  const tableHeader =
    watchTracability !== Tracability.Normal
      ? [
          option_1.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "SKU",
          t("inventory.active"),
        ]
      : [
          option_1.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "SKU",
          "Barcode",
          t("inventory.active"),
        ];

  const priceSx = {
    textAlign: "center",
    maxWidth: 120,
    minWidth: 50,
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableHeader.map((header) => (
            <TableCell sx={{ textAlign: "center" }} key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {fields &&
          fields.map((firstOption: any, firstInd: number) => (
            <Fragment key={firstOption.id}>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {firstOption.name}
                </TableCell>
                <TableCell sx={priceSx}>
                  <ControlledTextField
                    control={control}
                    name={`variation_list.${firstInd}.sale_price`}
                    type="number"
                    onChange={parseFloatFunction}
                    disabled={!firstOption.status || disabled}
                  />
                </TableCell>
                <TableCell sx={priceSx}>
                  <ControlledTextField
                    control={control}
                    name={`variation_list.${firstInd}.special_price`}
                    type="number"
                    onChange={parseFloatFunction}
                    disabled={!firstOption.status || disabled}
                  />
                </TableCell>
                <TableCell sx={priceSx}>
                  <ControlledTextField
                    control={control}
                    name={`variation_list.${firstInd}.purchase_standard_price`}
                    type="number"
                    onChange={parseFloatFunction}
                    disabled={!firstOption.status || disabled}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    minWidth: 250,
                  }}
                >
                  <ControlledTextField
                    control={control}
                    name={`variation_list[${firstInd}].sku_name`}
                    disabled={!firstOption.status || disabled}
                  />
                </TableCell>
                {watchTracability === "normal" && (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      minWidth: 250,
                    }}
                  >
                    <ControlledTextField
                      control={control}
                      name={`variation_list[${firstInd}].barcode`}
                      disabled={!firstOption.status || disabled}
                      // error={errors?.variation_list?.[firstInd]?.barcode}
                      // helperText={
                      //   errors?.variation_list?.[firstInd]?.barcode?.message
                      // }
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name={`variation_list[${firstInd}].status`}
                    render={({ field }) => (
                      <Box
                        sx={{
                          cursor:
                            !disabled && Boolean(!watchItemIsActive)
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          !disabled && Boolean(!watchItemIsActive)
                            ? () => {
                                enqueueSnackbar(
                                  "หากต้องการเปิดการใช้งานของตัวเลือก\nกรุณาเปิดการใช้งานสถานะในหน้าทั่วไปของสินค้าก่อน",
                                  {
                                    variant: "error",
                                    style: {
                                      whiteSpace: "pre-line",
                                    },
                                  }
                                );
                              }
                            : () => {}
                        }
                      >
                        <Switch
                          checked={field.value}
                          onChange={() => {
                            if (!field.value)
                              return field.onChange(!field.value);
                            openDisabledConfirmation();
                            setSelectedSku({
                              name: getValues(
                                `variation_list[${firstInd}].sku_name`
                              ),
                              position: `variation_list[${firstInd}].status`,
                              current_value: field.value,
                            });
                          }}
                          disabled={disabled || !watchItemIsActive}
                        />
                      </Box>
                    )}
                  />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
      </TableBody>
    </Table>
  );
};

export default OneVariantTable;
