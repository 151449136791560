import { IPermission } from "../../types/Auth/role";

export const salesPermissionValue: IPermission[] = [
  {
    variableName: "sales",
    name: "การขาย",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "quotation",
    name: "ใบเสนอราคา",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "sales_order",
    name: "ใบสั่งขาย",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    isLockCancel: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "cash_sales",
    name: "ขายสด",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "sales_invoice",
    name: "ใบแจ้งหนี้",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "sales_return",
    name: "ใบรับคืน",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "customer_deposit",
    name: "ใบมัดจำ",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    isCancelable: true,
    cancel: false,
    delete: false,
  },
  {
    variableName: "sales_report",
    name: "รายงาน​",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "sales_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
