import { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import DocumentInfoTab from "./DocumentInfoTab";
import BreadcrumbsLayout from "../../../components/UI/BreadcrumbsLayout";
import {
  deliveryOrderSchema,
  deliveryOrderValidation,
} from "../../../components/Form/Logistic/DeliveryOrder/schema";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { ActivityLogDocumentType } from "../../../generated/inventory";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  DeliveryOrderFindUniqueQuery,
  useDeliveryOrderFindUniqueQuery,
} from "../../../generated/logistic";

const DeliveryOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const graphQLClient = createGraphQLClientWithMiddleware("logistic");

  const methods = useForm<any>({
    defaultValues: { ...deliveryOrderSchema },
    resolver: yupResolver<any>(deliveryOrderValidation),
  });

  const { control } = methods;

  const { data, isLoading } =
    useDeliveryOrderFindUniqueQuery<DeliveryOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.delivery_order.index"),
      to: "/logistic/delivery-order",
    },
    {
      name: id
        ? data?.DeliveryOrderFindUnique.unique_id || "-"
        : t("button.create") + t("logistic.delivery_order.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        setOpenDrawer={setOpenDrawer}
      />
      <DocumentInfoTab />
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.DeliveryOrder}
        />
      )}
    </FormProvider>
  );
};

export default DeliveryOrderContainer;
