import {
    ColDef,
    IServerSideGetRowsParams,
    GridReadyEvent,
    ICellRendererParams,
    ValueFormatterParams,
} from "ag-grid-community";
import AgGrid from "../../../UI/AgGrid";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { PURCHASE_ORDER_BY_VENDOR } from "../../../../services/AgGrid/Report/PurchaseReportAggrid";
import { numberFormatter, statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

interface Props {
    gridRef: RefObject<AgGridReact>;
}

const PurchaseOrderByVendorReportTable = ({ gridRef }: Props) => {
    const columnDefs: ColDef[] = [
        {
            field: "vendor_id",
            headerName: "รหัสผู้ขาย",
            filter: "agTextColumnFilter",
        },
        {
            field: "vendor_name",
            headerName: "ชื่อผู้ขาย",
            filter: "agTextColumnFilter",
        },
        {
            field: "status",
            headerName: "สถานะในสั่งซื้อ",
            filter: "agSetColumnFilter",
            cellRenderer: (params: ICellRendererParams<any, string>) => (
                <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
                display: "flex",
                justifycontent: "center",
                alignItems: "center",
            },
            minWidth: 200,
            flex: 1,
            filterParams: {
                values: [
                    "draft",
                    "wait_approve",
                    "not_approved",
                    "approved",
                    "partially_imported",
                    "fully_imported",
                    "cancelled",
                ],
                valueFormatter: (params: ValueFormatterParams) =>
                    statusValueFormatter(params.value),
            },
        },
        {
            field: "total_document",
            headerName: "จำนวนใบสั่งซื้อ",
            filter: "agNumberColumnFilter",
            valueFormatter: (params) =>
                params.value ? params.value.toLocaleString() : 0,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "pre_discount_amount",
            headerName: "มูลค่าก่อนหักส่วนลด",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "discount",
            headerName: "ส่วนลด",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "additional_discount",
            headerName: "ส่วนลดเพิ่มเติม",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "pre_vat_amount",
            headerName: "มูลค่าก่อนภาษี",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "vat_amount",
            headerName: "ภาษีมูลค่าเพิ่ม",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
        {
            field: "net_amount",
            headerName: "มูลค่ารวมสุทธิ",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) return numberFormatter(params.value);
                else return "0.00";
            },
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
        },
    ];

    const graphQLClient: GraphQLClient =
        createGraphQLClientWithMiddleware("purchase");

    const datasource = {
        async getRows(params: IServerSideGetRowsParams) {
            const { request } = params;
            const { startRow, endRow, filterModel, sortModel } = request;

            const formatFilter = {
                ...filterModel,
            };

            try {
                const { PurchaseOrderByVendorExportViewsAggird } =
                    await graphQLClient.request(PURCHASE_ORDER_BY_VENDOR, {
                        aggridInput: {
                            startRow,
                            endRow,
                            filterModel: formatFilter,
                            sortModel,
                        },
                    });
                params.success({
                    rowData: PurchaseOrderByVendorExportViewsAggird.results,
                    rowCount: PurchaseOrderByVendorExportViewsAggird.count,
                });
            } catch (err) {
                params.fail();
            }
        },
    };

    const onGridReady = (params: GridReadyEvent) => {
        params.api.setFilterModel({
            status: {
                type: "set",
                values: [ 
                    "approved",
                    "wait_approve",
                    "partially_imported",
                    "fully_imported",
                ],
              },
        });
        params.api.setServerSideDatasource(datasource);
    };

    return (
        <AgGrid
            height={665}
            ref={gridRef}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
        />
    );
};

export default PurchaseOrderByVendorReportTable;
