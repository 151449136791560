import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IAttachment, IDefaultForm } from "../../../types/global";
import { Controller, useForm, useFormContext } from "react-hook-form";
import ModalUI from "./ModalUI";
import { Box, Stack } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import {
  IDeliveryDetails,
  IDeliveryOrder,
} from "../../../types/Logistic/deliveryOrder";
import { useEffect, useState } from "react";
import SubHeader from "../../Form/Purchase/SubHeader";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import ControlledTextField from "../../Controller/ControlledTextField";
import DocumentDropzoneUI from "../DocumentDropzoneUI";

export const deliveryDetailsValidation = Yup.object().shape({
  is_delivery_success: Yup.boolean().required("กรุณาระบุสถานะจัดส่ง"),
  // recipient_name: Yup.string().required("กรุณาระบุผู้รับ"),
  recipient_name: Yup.string().when("is_delivery_success", {
    is: (v: any) => v,
    then: (schema) => schema.required("กรุณาระบุผู้รับ"),
    otherwise: (schema) => schema.notRequired(),
  }),
  attachment_list: Yup.array().max(5),
});

type Props = {
  open: boolean;
  handleClose: () => void;
  updateHandler: (data: IDeliveryDetails) => Promise<void>;
};

const defaultValue = {
  is_delivery_success: true,
  recipient_name: "",
  delivery_remark: "",
  attachment_list: [],
};

const DeliveryStatusModal = ({ updateHandler, open, handleClose }: Props) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext<IDeliveryOrder>();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IDeliveryDetails>({
    defaultValues: defaultValue,
    resolver: yupResolver<any>(deliveryDetailsValidation),
  });

  const remarkValue = watch("delivery_remark");
  const deliveryStatus = watch("is_delivery_success");

  useEffect(() => {
    const mainValue = getValues("delivery_detail");
    if (mainValue) reset(mainValue);
    else reset();
  }, [open]);

  const summitHandler = async (data: IDeliveryDetails) => {
    try {
      await updateHandler(data);
      handleClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  return (
    <ModalUI
      open={open}
      handleClose={handleClose}
      title={t("logistic.delivery_order.delivery_info.index")}
      maxWidth="sm"
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.cancel")}
            onClick={handleClose}
            variant="outlined"
          />
          <CustomizedButton
            title={t("button.save")}
            onClick={handleSubmit(summitHandler)}
            variant="contained"
          />
        </Stack>
      }
    >
      <Box>
        <SubHeader
          title={t("logistic.delivery_order.delivery_info.status")}
          required
        />
        <Controller
          name={"is_delivery_success"}
          control={control}
          render={({ field }) => (
            <CustomizedRadioGroup
              radioList={[
                { value: true, label: "จัดส่งสำเร็จ" },
                { value: false, label: "จัดส่งไม่สำเร็จ" },
              ]}
              row
              {...field}
              onChange={(e) => {
                if (e?.target?.value) {
                  field.onChange(e.target.value === "true" ? true : false);
                  if (e.target.value === "false") {
                    setValue("recipient_name", "");
                  }
                }
              }}
            />
          )}
        />
        <SubHeader
          title={t("logistic.delivery_order.delivery_info.recipient_name")}
        />
        <ControlledTextField
          control={control}
          name="recipient_name"
          placeholder="ชื่อผู้รับ"
          required={deliveryStatus}
          disabled={!deliveryStatus}
          error={Boolean(errors?.recipient_name)}
          helperText={errors?.recipient_name?.message?.toString()}
          sx={{ my: 1 }}
        />
        <SubHeader title={t("sentence.remark")} />
        <ControlledTextField
          name="delivery_remark"
          control={control}
          sx={{ my: 1.5 }}
          multiline
          minRows={5}
          maxRows={10}
          inputProps={{
            maxLength: 1000,
          }}
          viewModeNoHelperText
          helperText={isFocused ? `${remarkValue.length}/1000` : undefined}
          placeholder={t("sentence.remark")}
          onFocus={focusHandler}
          onBlur={blurHandler}
          FormHelperTextProps={{
            style: {
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 0,
            },
          }}
        />
        <DocumentDropzoneUI
          control={control}
          setValue={setValue}
          name="attachment_list"
          maxSize={5}
          multiple
        />
      </Box>
    </ModalUI>
  );
};

export default DeliveryStatusModal;
