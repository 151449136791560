import { ISelectOption } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import TocIcon from "@mui/icons-material/Toc";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import PreVatAmountCell from "./PreVatAmountCell";
// import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useModal } from "../../../../hooks/use-modal";
import { AgGridReact } from "ag-grid-react";
import SumPreVatAmount from "./SumPreVatAmount";
import { v4 as uuidv4 } from "uuid";
import {
  numberFormatter,
  priceNumberFormatter,
  vatTypeFormatter,
} from "../../../../utils/Formatter/Global";
import { useSalesItemListHeader } from "../../../../hooks/Sales/use-sales-item-list-header";
import { ISalesItemList } from "../../../../types/Sales";
import { useDisable } from "../../../../hooks/use-disable";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import ItemTableModal from "../../../UI/Modal/ItemTableModal";
import { priceListInItem } from "../../../../utils/Formatter/Sales";
import { ICashSales } from "../../../../types/Sales/cashSales";
import { IQuotation } from "../../../../types/Sales/quotation";
import SalesReturnItemList from "./SalesReturnItemList";
import { ISalesInvoice } from "../../../../types/Sales/salesInvoice";
import CheckedItemListModal from "../../../UI/Modal/CheckedItemListModal";
import { useParams } from "react-router-dom";
import { usePromotionApply } from "../../../../hooks/Marketing/use-promotion-apply";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PROMOTION_SETTINGS_VALIDATE_COUPONS } from "../../../../services/Marketing/Promotion/PromotionSettings";

interface Props {
  referenceItemList?: ISalesItemList[];
  documentType: string;
}

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const SalesItemList = ({ documentType, referenceItemList }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IQuotation | ISalesOrder | ICashSales | ISalesInvoice>();
  const [disabled] = useDisable();
  const gridRef = useRef<AgGridReact<any>>(null);
  const { id } = useParams();
  const { recalculatePromotionDiscounts } = usePromotionApply({ documentType });
  //const gridRefItemList = useRef<AgGridReact<ISalesItemList>>(null);

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const tableDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  const headers = useSalesItemListHeader(documentType, disabled);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, move, remove, append, replace, update } = useFieldArray({
    control,
    name: "item_list",
    keyName: "unique_id",
  });

  useEffect(() => {
    if (fields && fields.length > 0) {
      let uniqueIdList: string[] = [];

      fields?.forEach(({ reference_line_item }) => {
        if (reference_line_item && reference_line_item.line_item_unique_id) {
          uniqueIdList.push(reference_line_item.line_item_unique_id);
        }
      });

      setItemIds(uniqueIdList);
      setItemIdsSnapshot(uniqueIdList);
    }
  }, [fields, referenceItemList]);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (indexToRemove: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(indexToRemove);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = async () => {
    if (documentType === "cash_sales") {
      if (!deletedIndex && deletedIndex !== 0) {
        closeDeleteItemConfirmationHandler();
        return;
      }

      const formValues = getValues([
        "promotion_list",
        "item_list",
        "customer_details",
        "branch_id",
        "sales_channel_id",
      ]) as any;
      const promotion_list = formValues?.[0];
      if (promotion_list?.length > 0) {
        try {
          await graphQLClientWithHeader.request(
            PROMOTION_SETTINGS_VALIDATE_COUPONS,
            {
              validateInput: {
                coupon_code_list: promotion_list.map(
                  (promotion: any) => promotion.coupon_code
                ),
                customer_id: formValues?.[2]?.id ?? 0,
                total_price: Math.round(
                  formValues?.[1]
                    ?.filter(
                      (item: any, index: number) =>
                        !isNaN(item.item_id) && index !== deletedIndex
                    )
                    .reduce((acc: number, item: any) => {
                      const itemPrice = item.price_per_unit ?? 0;
                      const discount = item.discount ?? 0;
                      const qty = item.qty ?? 0;

                      const adjustedPrice = Math.max(itemPrice - discount, 0);
                      return acc + adjustedPrice * qty;
                    }, 0)
                ),
                branch_id: formValues?.[3] ?? -1,
                item_list: formValues?.[1]
                  ?.map((item: any) => ({
                    item_id: item.item_id,
                    amount: item.qty,
                  }))
                  .filter(
                    (item: any, index: number) =>
                      !isNaN(item.item_id) && index !== deletedIndex
                  ),
                sales_channel_id: formValues?.[4],
              },
            }
          );
        } catch (err) {
          enqueueSnackbar(
            `${t(
              "marketing.promotion.sentence.apply_validate_error"
            )} รายการสินค้า`,
            { variant: "error" }
          );
          closeDeleteItemConfirmationHandler();
          return;
        }
      }

      const itemToDelete = getValues("item_list")?.[deletedIndex];
      const qty = itemToDelete.qty ?? 0;
      const promotionList = getValues("promotion_list");

      promotionList.forEach((promotion, promotionIndex) => {
        let recalculatedPromotionDiscount = 0;

        const redeemItem = promotion.redeem_item_list?.find(
          (redeemItem: any) => redeemItem.item_id === itemToDelete.item_id
        );
        const maxRedeemQty = redeemItem ? redeemItem.qty : qty;

        const effectiveQty = Math.min(qty, maxRedeemQty) ?? 0;

        // Recalculate the promotion discount for the item being deleted
        if (promotion.receive_type === "percent") {
          recalculatedPromotionDiscount =
            (effectiveQty * itemToDelete.price_per_unit -
              (itemToDelete.discount ?? 0)) *
            (promotion.discount_rate / 100);
        } else {
          recalculatedPromotionDiscount =
            (promotion.discount_rate ?? 0) * effectiveQty;
        }

        // Subtract recalculated promotion discount from the promotion's total_discount_amount
        promotionList[promotionIndex] = {
          ...promotionList[promotionIndex],
          total_discount_amount:
            (promotionList[promotionIndex].total_discount_amount || 0) -
            recalculatedPromotionDiscount,
        };
      });
      setValue(`promotion_list`, promotionList);
    }

    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  // const isReferenceDocumentList = getValues("reference_document_list");
  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");

      const vatType = (sale_vat_type: "ไม่มี" | "0 %" | "7 %") => {
        switch (sale_vat_type) {
          case "ไม่มี":
            return "ไม่มี";
          case "0 %":
            return "0";
          case "7 %":
            return "7";
          default:
            return "ไม่มี";
        }
      };

      const priceList = getValues("item_price_list");

      selectedItems.forEach((sku_detail) => {
        let formatItems: ISalesItemList = {
          unique_id: uuidv4(),
          item_unique_id: sku_detail.item_unique_id || "",
          item_id: sku_detail.item_id,
          item_sku_name: sku_detail.sku_name,
          item_name: sku_detail.name,
          item_sku_desc: sku_detail.description,
          tracability: sku_detail.tracability,
          branch_id: getValues("branch_id"),
          qty: 1,
          discount: 0,
          promotion_discount: 0,
          pre_vat_amount: 0,
          price_per_unit: sku_detail.sale_price ?? 0,
          uom_id: sku_detail.stock_uom_id,
          remark: "",
          stock_qty: 1,
          vat_percentage: vatType(sku_detail.sale_vat_type),
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          reference_document_type: documentType,
          reference_unique_id: document_unique_id,
          uom_name: sku_detail.stock_uom_name_th,
          uom_group: sku_detail.uom_group,
          item_sku_qty: sku_detail.total_sku_qty,
          item_img_url: sku_detail.img_url,
          volume: sku_detail.delivery_attribute[0].volume,
          weight: sku_detail.delivery_attribute[0].weight,
          barcode: sku_detail.barcode || undefined,
        };

        if (documentType === "cash_sales") {
          const promotionList = getValues("promotion_list");

          promotionList.forEach((promotion, promotionIndex) => {
            if (!formatItems.promotion_discount) {
              formatItems.promotion_discount = 0.0;
            }

            let currentPromotionDiscount = 0;

            if (promotion.receive_type === "percent") {
              currentPromotionDiscount =
                (formatItems.price_per_unit - (formatItems.discount ?? 0)) *
                (promotion.discount_rate / 100);
            } else {
              currentPromotionDiscount = promotion.discount_rate ?? 0;
            }

            // Update formatItems promotion_discount with the calculated value
            formatItems.promotion_discount += currentPromotionDiscount;

            // Update the total_discount_amount for the specific promotion in the promotionList
            promotionList[promotionIndex] = {
              ...promotionList[promotionIndex],
              total_discount_amount:
                (promotionList[promotionIndex].total_discount_amount || 0) +
                currentPromotionDiscount,
            };
          });

          setValue("promotion_list", promotionList);
        }
        const formatWithPriceList = priceListInItem(priceList, formatItems);

        append(formatWithPriceList);
      });
    }
    closeItemModalHandler();
  };

  const addReferenceItemsHandler = (itemList: any[]) => {
    if (itemList && itemList.length > 0) {
      const currentItemList = getValues("item_list");
      let filteredItems = currentItemList.filter((item) =>
        itemIds.includes(item.reference_line_item?.line_item_unique_id || "")
      );

      // get current Items id to prevent duplication when appending newly selected Items
      const filteredItemsId = filteredItems.map(
        (item) => item.reference_line_item?.line_item_unique_id
      );

      itemList.forEach((item) => {
        if (!filteredItemsId.includes(item.unique_id)) {
          const foundUpdateItem = currentItemList.find(
            (item) =>
              item.reference_line_item?.line_item_unique_id === item.unique_id
          );
          if (foundUpdateItem) {
            filteredItems.push({
              ...item,
              unique_id: foundUpdateItem?.unique_id,
              reference_line_item: {
                line_item_document_type: item.reference_document_type,
                line_item_unique_id: item.unique_id,
              },
            });
          } else {
            filteredItems.push({
              ...item,
              reference_line_item: {
                line_item_document_type: item.reference_document_type,
                line_item_unique_id: item.unique_id,
              },
              unique_id: uuidv4(),
            });
          }
        }
      });
      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return itemIds.indexOf(a.unique_id) - itemIds.indexOf(b.unique_id);
      });

      replace(filteredItems);
    }
  };

  const uomOptions = (field: ISalesItemList) => {
    const options = (
      field.uom_group
        ? field.uom_group.convertable_uom_list &&
          field.uom_group.convertable_uom_list.length > 0
          ? [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
              ...field.uom_group.convertable_uom_list.map((uom) => ({
                label: uom.name_th,
                value: uom.id,
              })),
            ]
          : [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  if (documentType === "sales_return") {
    return (
      <CustomizedBox sx={{ breakAfter: "auto" }} margin={0} maxWidth={1650}>
        <Typography fontWeight={600} color={"primary.main"} mb={2}>
          สินค้า
        </Typography>
        <SalesReturnItemList
          dragEndHandler={dragEndHandler}
          fields={fields}
          headers={headers}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          vatOptions={vatOptions}
        />
        {!disabled && (
          <CustomizedButton
            sx={{ mt: 2 }}
            title="เพิ่มสินค้า"
            variant="outlined"
            onClick={openItemModalHandler}
            addIcon
          />
        )}

        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <SumPreVatAmount control={control} />
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <CheckedItemListModal
          showSelectItem={itemModal}
          closeItemTable={closeItemModalHandler}
          finishItemsSelect={addReferenceItemsHandler}
          rowData={referenceItemList}
          rowSelection="multiple"
          itemIds={itemIds}
          setItemIds={setItemIds}
          itemIdsSnapshot={itemIdsSnapshot}
          setItemIdsSnapshot={setItemIdsSnapshot}
        />
      </CustomizedBox>
    );
  }

  const textAlignToJustify = (align?: string) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  const validateCouponForitemFields = async (
    index: number,
    value: number,
    fieldType: "qty" | "price_per_unit" | "discount"
  ) => {
    const formValues = getValues([
      "promotion_list",
      "item_list",
      "customer_details",
      "branch_id",
      "sales_channel_id",
    ]) as any;
    const promotion_list = formValues?.[0];

    if (promotion_list?.length === 0) {
      return true;
    }

    try {
      await graphQLClientWithHeader.request(
        PROMOTION_SETTINGS_VALIDATE_COUPONS,
        {
          validateInput: {
            coupon_code_list: promotion_list.map(
              (promotion: any) => promotion.coupon_code
            ),
            customer_id: formValues?.[2]?.id ?? 0,
            total_price: Math.round(
              formValues?.[1].reduce(
                (acc: number, item: any, itemIndex: number) => {
                  const itemPrice =
                    itemIndex === index && fieldType === "price_per_unit"
                      ? value
                      : item.price_per_unit;

                  const discount =
                    itemIndex === index && fieldType === "discount"
                      ? value
                      : item.discount;

                  const qty =
                    itemIndex === index && fieldType === "qty"
                      ? value
                      : item.qty ?? 0;

                  const adjustedPrice = itemPrice - discount;
                  return acc + adjustedPrice * qty;
                },
                0
              )
            ),
            branch_id: formValues?.[3] ?? -1,
            item_list: formValues?.[1]
              ?.map((item: any, itemIndex: number) => ({
                item_id: item.item_id,
                amount:
                  itemIndex === index && fieldType === "qty" ? value : item.qty,
              }))
              .filter(
                (item: any, itemIndex: number) =>
                  !isNaN(item.item_id) && itemIndex !== deletedIndex
              ),
            sales_channel_id: formValues?.[4],
          },
        }
      );
      return true;
    } catch (err) {
      enqueueSnackbar(
        `${t(
          "marketing.promotion.sentence.apply_validate_error"
        )} รายการสินค้า`,
        { variant: "error" }
      );
      return;
    }
  };

  return (
    <CustomizedBox sx={{ breakAfter: "auto" }} margin={0} maxWidth={1650}>
      <Typography fontWeight={600} color={"primary.main"} mb={2}>
        สินค้า
      </Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: textAlignToJustify(header.align),
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={tableDisabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.unique_id}
                      draggableId={"item-" + field.unique_id}
                      index={index}
                      isDragDisabled={tableDisabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!tableDisabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={tableDisabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.qty)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.qty`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.qty
                                )}
                                onChange={(value) => {
                                  if (documentType === "cash_sales") {
                                    return;
                                  }
                                  const parseValue = value || 0;
                                  const currentUomId = getValues(
                                    `item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomId ===
                                        field.uom_group.base_uom?.id
                                      ) {
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                  recalculatePromotionDiscounts();
                                }}
                                onBlur={async (e) => {
                                  if (documentType !== "cash_sales") {
                                    return;
                                  }
                                  const parseValue =
                                    parseFloat(
                                      e.target.value?.replace(/,/g, "")
                                    ) || 0;
                                  const isValid =
                                    await validateCouponForitemFields(
                                      index,
                                      parseValue,
                                      "qty"
                                    );
                                  if (!isValid) {
                                    update(index, {
                                      ...getValues(`item_list.${index}`),
                                      qty: getValues(
                                        `item_list.${index}.qty` || 0
                                      ),
                                    });
                                    return;
                                  }
                                  const currentUomId = getValues(
                                    `item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomId ===
                                        field.uom_group.base_uom?.id
                                      ) {
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                  update(index, {
                                    ...getValues(`item_list.${index}`),
                                    qty: parseValue,
                                  });
                                  recalculatePromotionDiscounts();
                                }}
                                textAlign="right"
                                disableDefaultOnChange={
                                  documentType === "cash_sales"
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {field.uom_name}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                name={`item_list.${index}.uom_id`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.uom_id
                                )}
                                options={uomOptions(field)}
                                onChange={(e: any) => {
                                  const uom_name = uomOptions(field).find(
                                    (uom) => uom.value === e.target.value
                                  )?.label;
                                  setValue(
                                    `item_list.${index}.uom_name`,
                                    uom_name
                                  );
                                  const currentQty = getValues(
                                    `item_list.${index}.qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                control={control}
                                name={`item_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                )}
                                decimalScale={4}
                                textAlign="right"
                                // onChange={() => recalculatePromotionDiscounts()}
                                onBlur={async (e) => {
                                  if (documentType !== "cash_sales") {
                                    return;
                                  }
                                  const parseValue =
                                    parseFloat(
                                      e.target.value?.replace(/,/g, "")
                                    ) || 0;
                                  const isValid =
                                    await validateCouponForitemFields(
                                      index,
                                      parseValue,
                                      "price_per_unit"
                                    );
                                  if (!isValid) {
                                    update(index, {
                                      ...getValues(`item_list.${index}`),
                                      price_per_unit: getValues(
                                        `item_list.${index}.price_per_unit` || 0
                                      ),
                                    });
                                    return;
                                  }
                                  update(index, {
                                    ...getValues(`item_list.${index}`),
                                    price_per_unit: parseValue,
                                  });
                                  recalculatePromotionDiscounts();
                                }}
                                disableDefaultOnChange={
                                  documentType === "cash_sales"
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.discount`}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.discount
                                )}
                                decimalScale={4}
                                textAlign="right"
                                onBlur={async (e) => {
                                  if (documentType !== "cash_sales") {
                                    return;
                                  }
                                  const parseValue =
                                    parseFloat(
                                      e.target.value?.replace(/,/g, "")
                                    ) || 0;
                                  const isValid =
                                    await validateCouponForitemFields(
                                      index,
                                      parseValue,
                                      "discount"
                                    );
                                  if (!isValid) {
                                    update(index, {
                                      ...getValues(`item_list.${index}`),
                                      discount: getValues(
                                        `item_list.${index}.discount` || 0
                                      ),
                                    });
                                    return;
                                  }
                                  update(index, {
                                    ...getValues(`item_list.${index}`),
                                    discount: parseValue,
                                  });
                                  recalculatePromotionDiscounts();
                                }}
                                disableDefaultOnChange={
                                  documentType === "cash_sales"
                                }
                              />
                            )}
                          </TableCell>
                          {documentType === "cash_sales" && (
                            <TableCell
                              align="right"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[5]?.width || 0
                                  : 0) + 16
                              }
                            >
                              {tableDisabled ? (
                                <Typography fontSize={14}>
                                  {priceNumberFormatter(
                                    field.promotion_discount,
                                    2
                                  )}
                                </Typography>
                              ) : (
                                <ControlledNumberTextField
                                  control={control}
                                  name={`item_list.${index}.promotion_discount`}
                                  disabled
                                  error={Boolean(
                                    errors?.item_list &&
                                      errors?.item_list[index] &&
                                      errors?.item_list[index]
                                        ?.promotion_discount
                                  )}
                                  decimalScale={2}
                                  textAlign="right"
                                />
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                name={`item_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={tableDisabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            <WithholdingTaxCell
                              control={control}
                              errors={errors}
                              index={index}
                              documentType={documentType}
                              disabled={tableDisabled}
                              setValue={setValue}
                              field={field}
                            />
                          </TableCell> */}
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <>
                                <Typography fontSize={14}>
                                  {numberFormatter(field.stock_qty)}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#BDBDBD" }}
                                >
                                  {field.uom_group?.base_uom?.name_th}
                                </Typography>
                              </>
                            ) : (
                              <ControlledTextField
                                sx={{ mt: 3 }}
                                control={control}
                                name={`item_list.${index}.stock_qty`}
                                helperText={field.uom_group?.base_uom?.name_th}
                                FormHelperTextProps={{
                                  sx: { color: "#BDBDBD", fontSize: 12 },
                                }}
                                viewMode={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list.${index}.remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.remark
                                )}
                                placeholder="หมายเหตุสินค้า"
                              />
                            )}
                          </TableCell>
                          {(documentType === "sales_order" ||
                            documentType === "cash_sales") &&
                            disabled && (
                              <>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[10]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_to_ship || 0)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[11]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_shipped || 0)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[12]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_invoiced || 0)}
                                  </Typography>
                                </TableCell>
                                {documentType !== "cash_sales" && (
                                  <TableCell
                                    align="right"
                                    sx={{
                                      px: 1,
                                      py: 1,
                                    }}
                                    width={
                                      (headers && headers.length > 0
                                        ? headers[13]?.width || 0
                                        : 0) + 16
                                    }
                                  >
                                    <Typography fontSize={13}>
                                      {numberFormatter(field.qty_returned || 0)}
                                    </Typography>
                                  </TableCell>
                                )}
                              </>
                            )}
                          {!tableDisabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              // width={
                              //   (headers && headers.length > 0
                              //     ? headers[11]?.width || 0
                              //     : 0) + 16
                              // }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!tableDisabled && (
        <CustomizedButton
          sx={{ mt: 2 }}
          title="เพิ่มสินค้า"
          variant="outlined"
          onClick={openItemModalHandler}
          addIcon
        />
      )}

      <Grid container maxWidth={984} spacing={0}>
        <Grid item xs={12} sm={12} md={6.5} />
        <Grid item xs={12} sm={12} md={5.5}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
        saleableItem
      />
    </CustomizedBox>
  );
};

export default SalesItemList;
