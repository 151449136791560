import { useFormContext, useWatch } from "react-hook-form";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import { IRadio } from "../../../../types/global";
import { useDisable } from "../../../../hooks/use-disable";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PromotionInfo = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const isApplied = useWatch({ control, name: "is_applied" });

  const statusList: IRadio[] = [
    {
      label: t("status.active"),
      value: "active",
    },
    {
      label: t("status.inactive"),
      value: "inactive",
    },
  ];

  const availableList: IRadio[] = [
    {
      label: t("marketing.promotion.available_for.erp"),
      value: "erp",
      disabled: true,
    },
    {
      label: t("marketing.promotion.available_for.erp_pos"),
      value: "erp_pos",
      disabled: true,
    },
  ];

  return (
    <CustomizedBox>
      <Typography
        fontWeight={600}
        mb={2}
        color={Boolean(errors.name) ? "error.main" : undefined}
      >
        {t("marketing.promotion.info.index")}
      </Typography>
      <Grid
        container
        spacing={1.5}
        sx={
          disabled
            ? {
                "& > .MuiGrid-item": {
                  paddingLeft: "0 !important",
                },
              }
            : {}
        }
      >
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="name"
            label={t("marketing.promotion.info.name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            viewMode={disabled}
            disabled={isApplied}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("marketing.promotion.info.description")}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        mt={1}
        width={315}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Box display={"flex"} gap={0.3}>
            <Typography fontWeight={600}>
              {t("marketing.promotion.status")}
            </Typography>
            <Typography color={"error.main"} fontWeight={600}>
              *
            </Typography>
          </Box>
          <CustomizedTooltip title={t("marketing.promotion.tooltip.status")}>
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
        <ControlledRadioGroup
          control={control}
          name="status"
          radioLists={statusList}
          disabled={disabled}
          row
        />
      </Box>
      <Box display={"flex"} width={340} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"} gap={0.3}>
          <Typography fontWeight={600}>
            {t("marketing.promotion.available_for.index")}
          </Typography>
          <Typography color={"error.main"} fontWeight={600}>
            *
          </Typography>
        </Box>
        <ControlledRadioGroup
          control={control}
          name="available_for"
          radioLists={availableList}
          disabled={disabled}
          row
        />
      </Box>
    </CustomizedBox>
  );
};

export default PromotionInfo;
