import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Grid } from "@mui/material";

import Remark from "./Remark";
import TagList from "../../../UI/TagList";
import SubHeader from "../../Purchase/SubHeader";
import { CustomizedBox } from "../../../Custom/CustomizedBox";

import { useDisable } from "../../../../hooks/use-disable";
// import SubHeader from "../SubHeader";

interface Props {
  documentType: string;
}

const LogisticFooter = ({ documentType }: Props) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const [disabled] = useDisable();

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5}>
          <Remark />
          <SubHeader title={t("sales.tag_list")} sx={{ marginTop: 1 }} />
          <Grid container spacing={1.5} mt={0}>
            <TagList
              fullWidth
              control={control}
              documentType={documentType}
              name="tag_list"
              label={t("sales.tag_list")}
              getValues={getValues}
              setValue={setValue}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default LogisticFooter;
