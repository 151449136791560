import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { useDisable } from "../../../../../hooks/use-disable";
import { IRadio } from "../../../../../types/global";
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import ControlledCheckbox from "../../../../Controller/ControlledCheckbox";
import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PromotionItemGroup from "./ItemGroup";
import PromotionItemList from "../../../../Table/Marketing/Promotion/ItemList/PromotionItemList";
import { itemGroupSchema } from "../schema";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import ConfirmationModal from "../../../../UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const PromotionPurchaseCondition = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = watch("is_applied");

  const isApplied = Boolean(watchIsApplied);

  const removeSpeciticItemsHandler = () => {
    setValue("item_condition", []);
  };

  const cancelUncheckSpecificItemsHandler = () => {
    setValue("is_item_condition", true);
    closeConfirmationHandler();
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(removeSpeciticItemsHandler);

  const purchaseConditionList: IRadio[] = [
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.purchase_condition.all_items")}
          </Typography>
          <CustomizedTooltip
            title={t(
              "marketing.promotion.tooltip.purchase_condition.all_items"
            )}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "all_items",
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.purchase_condition.specific_items")}
          </Typography>
          <CustomizedTooltip
            title={t(
              "marketing.promotion.tooltip.purchase_condition.specific_items"
            )}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "specific_items",
    },
  ];

  const purchaseCondition = watch("purchase_condition");

  const isLimitAmount = watch("is_limit_amount");
  const isItemGroup = watch("is_item_group");
  const isItemCondition = watch("is_item_condition");

  return (
    <>
      <CustomizedBox>
        <Box display={"flex"} mb={1} gap={0.3}>
          <Typography fontWeight={600}>
            {t("marketing.promotion.purchase_condition.index")}
          </Typography>
          <Typography fontWeight={600} color={"error.main"}>
            *
          </Typography>
        </Box>
        <ControlledRadioGroup
          control={control}
          name="purchase_condition"
          radioLists={purchaseConditionList}
          row
          disabled={disabled || isApplied}
          onChange={(_, value) => {
            if (value === "all_items") {
              setValue("is_limit_amount", false);
              setValue("is_item_group", false);
              setValue("is_item_condition", false);
              setValue("limit_amount", undefined);
              setValue("item_group", [itemGroupSchema]);
              setValue("item_condition", []);
            }
          }}
        />
        {purchaseCondition === "specific_items" && (
          <>
            <FormLabel
              sx={{ fontWeight: 600 }}
              error={Boolean(errors.purchase_condition)}
              required
            >
              {t("marketing.promotion.purchase_condition.detail")}
            </FormLabel>
            <Box>
              <ControlledCheckbox
                label={t("marketing.promotion.purchase_condition.limit_amount")}
                control={control}
                name="is_limit_amount"
                disabled={disabled || isApplied}
                onChange={(_, checked) => {
                  if (checked) {
                    clearErrors("purchase_condition");
                  } else {
                    setValue("limit_amount", undefined);
                  }
                }}
              />
              {isLimitAmount && (
                <Grid
                  container
                  bgcolor={"#F0F1FA80"}
                  p={2}
                  mb={1}
                  sx={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <ControlledNumberTextField
                      control={control}
                      name="limit_amount"
                      fullWidth
                      endAdornmentMessage="บาท"
                      error={Boolean(errors.limit_amount)}
                      helperText={errors.limit_amount?.message}
                      textAlign="right"
                      viewMode={disabled}
                      disabled={isApplied}
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box>
              <ControlledCheckbox
                label={
                  <Box display={"flex"} alignItems={"center"} gap={0.5}>
                    <Typography
                      color={
                        disabled || isApplied
                          ? "rgba(0, 0, 0, 0.38)"
                          : undefined
                      }
                    >
                      {t(
                        "marketing.promotion.purchase_condition.select_item_group"
                      )}
                    </Typography>
                    <CustomizedTooltip
                      title={t(
                        "marketing.promotion.tooltip.purchase_condition.select_item_group"
                      )}
                    >
                      <InfoOutlinedIcon
                        color={"primary"}
                        sx={{ fontSize: 16 }}
                      />
                    </CustomizedTooltip>
                  </Box>
                }
                control={control}
                name="is_item_group"
                disabled={disabled || isApplied}
                onChange={(_, checked) => {
                  if (checked) {
                    clearErrors("purchase_condition");
                  } else {
                    setValue("item_group", [itemGroupSchema]);
                  }
                }}
              />
              {isItemGroup && <PromotionItemGroup />}
            </Box>
            <Box>
              <ControlledCheckbox
                label={t(
                  "marketing.promotion.purchase_condition.select_item_condition"
                )}
                control={control}
                name="is_item_condition"
                disabled={disabled || confirmation || isApplied}
                onChange={(_, checked) => {
                  if (checked) {
                    clearErrors("purchase_condition");
                  } else {
                    const itemConditions = getValues("item_condition");
                    if (itemConditions.length > 0) {
                      openConfirmationHandler();
                    }
                  }
                }}
              />
              {(isItemCondition || confirmation) && (
                <PromotionItemList fieldArrayName="item_condition" />
              )}
            </Box>
            {Boolean(errors.purchase_condition) && (
              <FormHelperText error={Boolean(errors.purchase_condition)}>
                {errors.purchase_condition?.message}
              </FormHelperText>
            )}
          </>
        )}
      </CustomizedBox>
      <ConfirmationModal
        title="การเปลี่ยนตัวเลือก"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนตัวเลือก"}</Typography>
            <Typography>{"ข้อมูลด้านล่างจะถูกล้างค่า"}</Typography>
          </Box>
        }
        open={confirmation}
        handleClose={cancelUncheckSpecificItemsHandler}
        action={submitConfirmationHandler}
        altTitleColor={"#333333"}
      />
    </>
  );
};

export default PromotionPurchaseCondition;
