import { IPermission } from "../../types/Auth/role";

export const contactPermissionValue: IPermission[] = [
  {
    variableName: "contact",
    name: "ผู้ติดต่อ",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
  {
    variableName: "customer",
    name: "ลูกค้า",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "vendor",
    name: "ผู้ขาย",
    view: "close",
    isCreatable: true,
    create: false,
    isUpdatable: true,
    update: false,
    cancel: false,
    isDeletable: true,
    isLockDelete: true,
    delete: false,
  },
  {
    variableName: "contact_export",
    name: "นำออกข้อมูล",
    view: "close",
    create: false,
    update: false,
    cancel: false,
    delete: false,
  },
];
