import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import CheckboxAggridModal from "./CheckboxAggridModal";
import { GetRowIdParams, ICellRendererParams } from "ag-grid-community";
import { Box, Typography } from "@mui/material";
import {
  numberFormatter,
  priceNumberFormatter,
} from "../../../utils/Formatter/Global";
import { ValueFormatterParams } from "ag-grid-community";

type CheckedItemListModalProps = {
  showSelectItem: boolean;
  closeItemTable: () => void;
  finishItemsSelect: (data: any) => void;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  rowSelection: "single" | "multiple";
  documentType?: string;
  btnTitle?: string;
  useAltButtons?: boolean;
};

const customCellStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 6px",
};

const CheckedItemListModal = ({
  showSelectItem,
  closeItemTable,
  finishItemsSelect,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  rowData,
  rowSelection,
  documentType,
  btnTitle,
  useAltButtons,
}: CheckedItemListModalProps) => {
  const { t } = useTranslation();
  const selectGridRef = useRef();

  const defaultColumns = useMemo(
    () => [
      {
        headerName: "รายการ",
        cellRenderer: (params: ICellRendererParams) => {
          return (params?.node?.rowIndex || 0) + 1;
        },
        // cellStyle: customCellStyle,
        width: 150,
        // checkboxSelection: disabled ? false : true,
      },
      {
        field: "item_name",
        headerName: "ชื่อสินค้า",
        cellRenderer: (params: ICellRendererParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "stretch",
                flex: 1,
                textAlign: "left",
              }}
            >
              <Typography variant="body2" align="left" mt={1} color="primary">
                {params.data.item_name}
              </Typography>
              <Typography
                variant="overline"
                sx={{
                  my: 0,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {params.data.item_unique_id}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                gutterBottom
                sx={{
                  whiteSpace: "normal",
                }}
              >
                {params.data.item_sku_desc}
              </Typography>
            </Box>
          </Box>
        ),
        autoHeight: true,
        cellStyle: {
          padding: "0 6px",
        },
        width: 350,
      },
      {
        field: "qty",
        headerName: "จำนวน",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {numberFormatter(params.data.qty)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "uom_name",
        headerName: "หน่วย",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {params.data.uom_name}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "stock_qty",
        headerName: "จำนวนคลัง",
        cellRenderer: (params: ICellRendererParams) => (
          <Box>
            <Typography fontSize={14} align="center">
              {numberFormatter(params.data.stock_qty)}
            </Typography>
            <Typography
              align="center"
              sx={{ fontSize: "10px", whiteSpace: "normal" }}
            >
              {params.data.uom_group?.base_uom?.name}
            </Typography>
          </Box>
        ),
        cellStyle: customCellStyle,
        autoHeight: true,
        width: 150,
      },
      {
        field: "price_per_unit",
        headerName: "ราคาต่อหน่วย",
        cellRenderer: (params: ICellRendererParams) => (
          <Box>
            <Typography fontSize={14} align="center">
              {priceNumberFormatter(params.data.price_per_unit)}
            </Typography>
            {/* {documentType !== "purchase_return" &&
              params.data.purchase_standard_price && (
                <Typography
                  align="center"
                  sx={{ fontSize: "10px", whiteSpace: "normal" }}
                >
                  ราคาทุน {formatNumber(params.data.purchase_standard_price)}
                </Typography>
              )} */}
          </Box>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "discount",
        headerName: "ส่วนลด",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {priceNumberFormatter(params.value)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "vat_percentage",
        headerName: "ภาษี",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {params.value}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "pre_vat_amount",
        headerName: "จำนวนเงิน",
        valueFormatter: (params: ValueFormatterParams) =>
          numberFormatter(params.data.pre_vat_amount),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "withholding_tax_type",
        headerName: "หัก ณ ที่จ่าย (%)",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {params.value} {params.value !== "ยังไม่ระบุ" && "%"}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "remark",
        headerName: "หมายเหตุสินค้า",
        cellRenderer: (params) => (
          <Typography fontSize={14} align="center">
            {params.data.remark}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
    ],
    []
  );

  const returnColumns = useMemo(
    () => [
      {
        headerName: "รายการ",
        cellRenderer: (params: ICellRendererParams) => {
          return (params?.node?.rowIndex || 0) + 1;
        },
        // cellStyle: customCellStyle,
        width: 150,
        // checkboxSelection: disabled ? false : true,
      },
      {
        field: "item_name",
        headerName: "ชื่อสินค้า",
        cellRenderer: (params: ICellRendererParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "stretch",
                flex: 1,
                textAlign: "left",
              }}
            >
              <Typography variant="body2" align="left" mt={1} color="primary">
                {params.data.item_name}
              </Typography>
              <Typography
                variant="overline"
                sx={{
                  my: 0,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {params.data.item_unique_id}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                gutterBottom
                sx={{
                  whiteSpace: "normal",
                }}
              >
                {params.data.item_sku_desc}
              </Typography>
            </Box>
          </Box>
        ),
        autoHeight: true,
        cellStyle: {
          padding: "0 6px",
        },
        width: 350,
      },
      {
        field: "qty",
        headerName: "จำนวน",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {numberFormatter(params.data.qty)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "uom_name",
        headerName: "หน่วย",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {params.data.uom_name}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "stock_qty",
        headerName: "จำนวนคลัง",
        cellRenderer: (params: ICellRendererParams) => (
          <Box>
            <Typography fontSize={14} align="center">
              {numberFormatter(params.data.stock_qty)}
            </Typography>
            <Typography
              align="center"
              sx={{ fontSize: "10px", whiteSpace: "normal" }}
            >
              {params.data.uom_group?.base_uom?.name}
            </Typography>
          </Box>
        ),
        cellStyle: customCellStyle,
        autoHeight: true,
        width: 150,
      },
      {
        field: "price_per_unit",
        headerName: "ราคาต่อหน่วย",
        cellRenderer: (params: ICellRendererParams) => (
          <Box>
            <Typography fontSize={14} align="center">
              {priceNumberFormatter(params.data.price_per_unit)}
            </Typography>
            {/* {documentType !== "purchase_return" &&
              params.data.purchase_standard_price && (
                <Typography
                  align="center"
                  sx={{ fontSize: "10px", whiteSpace: "normal" }}
                >
                  ราคาทุน {formatNumber(params.data.purchase_standard_price)}
                </Typography>
              )} */}
          </Box>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "discount",
        headerName: "ส่วนลด",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {priceNumberFormatter(params.value)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "vat_percentage",
        headerName: "ภาษี",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {params.value}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "pre_vat_amount",
        headerName: "จำนวนเงิน",
        valueFormatter: (params: ValueFormatterParams) =>
          numberFormatter(params.data.pre_vat_amount),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "remark",
        headerName: "หมายเหตุสินค้า",
        cellRenderer: (params) => (
          <Typography fontSize={14} align="center">
            {params.data.remark}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
    ],
    []
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      useAltButtons={useAltButtons}
      modalTitle={"รายการสินค้า"}
      btnTitle={btnTitle ?? t("button.add")}
      gridRef={selectGridRef}
      height={665}
      columnDefs={
        documentType === "purchase_return" ? returnColumns : defaultColumns
      }
      rowData={rowData}
      rowSelection={rowSelection}
      onFinishEditing={finishItemsSelect}
      modalIsOpen={showSelectItem}
      getRowId={getRowId}
      closeModal={closeItemTable}
      selectedIds={itemIds}
      setSelectedIds={setItemIds}
      idsSnapshot={itemIdsSnapshot}
      setIdsSnapshot={setItemIdsSnapshot}
      rowMultiSelectWithClick={true}
      keyName="unique_id"
    />
  );
};

export default CheckedItemListModal;
