import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import SubHeader from "../../Purchase/SubHeader";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import DocumentDropzoneUI from "../../../UI/DocumentDropzoneUI";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { Grid } from "@mui/material";

const DeliveryDetails = () => {
  const { t } = useTranslation();

  const { control, setValue, watch } = useFormContext();

  const status = watch("aggrid_status");

  return (
    <CustomizedBox margin={0}>
      <SubHeader
        title={t("logistic.delivery_order.delivery_info.index")}
        sx={{ marginY: 1 }}
      />
      <Grid container spacing={1}>
        {status === "delivery_success" && (
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label={t("logistic.delivery_order.delivery_info.recipient_name")}
              name="delivery_detail.recipient_name"
              viewMode
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label={t("sentence.remark")}
            name="delivery_detail.delivery_remark"
            viewMode
          />
        </Grid>

        <Grid item xs={12}>
          <DocumentDropzoneUI
            control={control}
            name="delivery_detail.attachment_list"
            setValue={setValue}
            disabled
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default DeliveryDetails;
