import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../types/Marketing/Promotion";
import useBranchColumnDefs from "../../Setting/Branch/useBranchColumnDefs";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import AgGrid from "../../../UI/AgGrid";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDisable } from "../../../../hooks/use-disable";

type Props = {
  rowData: any[];
  onRemoveHandler: (index: number) => void;
};

const PromotionSelectBranchTable = ({ rowData, onRemoveHandler }: Props) => {
  const gridRef = useRef<AgGridReact>();
  const columnDefs: ColDef[] = useBranchColumnDefs({ isPromotion: true });
  const [disabled] = useDisable();

  const { control } = useFormContext<IPromotion>();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const formatColumnDef: ColDef[] = [
    ...columnDefs,
    ...(disabled || isApplied
      ? []
      : [
          {
            field: "",
            filter: false,
            sortable: false,
            width: 50,
            cellStyle: {
              display: "flex",
              justifyContent: "center",
            },
            cellRenderer: (params: ICellRendererParams) => {
              if (params.data) {
                return (
                  <IconButton
                    onClick={() => onRemoveHandler(params.rowIndex)}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                );
              }
            },
          },
        ]),
  ];

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={formatColumnDef}
      rowData={rowData}
      height={300}
    />
  );
};

export default PromotionSelectBranchTable;
