import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import {
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
} from "../../../generated/inventory";
import { DeliveryOrderFindUniqueQuery } from "../../../generated/logistic";
import { ITraceEntry } from "../../../types/Inventory";
import { IDeliveryOrder } from "../../../types/Logistic/deliveryOrder";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import { ICashSales } from "../../../types/Sales/cashSales";
import { SalesReferenceDocumentType } from "../../../generated/sales";
import { formatDate } from "../../Date";

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    const keyParts = key.split("."); // Split the key on the dots
    let value = x;

    // Traverse the object properties based on the keyParts
    for (let part of keyParts) {
      value = value?.[part]; // Safely access nested properties
    }

    (rv[value] = rv[value] || []).push(x);
    return rv;
  }, {});
};

export const deliveryOrderCreatePayloadFormatter = (
  data: IDeliveryOrder,
  status: string
) => {
  const {
    trace_entry_list,
    tag_list,
    created_date,
    last_updated_by,
    ...otherData
  } = data;

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            source_bin_location,
            destination_bin_location,
            all_bin_locations,
            stock_qty,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            reference_unique_id: data.unique_id,
            document_item_qty: trace.document_item_qty,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          all_bin_locations,
          stock_qty,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push(trace);
    }
  });

  const formatPayload = {
    ...otherData,
    trace_entry_list: allTraceEntries,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
  };
  return formatPayload;
};

export const deliveryOrderUpdatePayloadFormatter = async (
  data: IDeliveryOrder,
  status: string
) => {
  const {
    id,
    unique_id,
    main_status,
    aggrid_status,
    last_updated_date,
    trace_entry_list,
    tag_list,
    created_by,
    created_date,
    branch,
    destination_warehouse,
    ...otherData
  } = data;

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            all_bin_locations,
            stock_qty,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            reference_unique_id: data.unique_id,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            // status: "is_scanned",
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          created_date,
          all_bin_locations,
          stock_qty,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          reference_unique_id: data.unique_id,
          // status: "is_scanned",
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push({
        ...trace,
        status: "is_scanned",
      });
    }
  });

  const formatPayload = {
    ...otherData,
    trace_entry_list: allTraceEntries,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
  };

  return formatPayload;
};

export const deliveryOrderQueryFormatter = async (
  data: DeliveryOrderFindUniqueQuery["DeliveryOrderFindUnique"],
  allSkuQtys?: ItemSkuQtysQuery
) => {
  const {
    trace_entry_list,
    created_date,
    delivery_date,
    exported_date,
    issue_date,
    ...otherData
  } = data;

  const groupedTrace = groupBy(
    trace_entry_list,
    "reference_line_item.line_item_unique_id"
  );

  const allTraceEntries: ITraceEntry[] = [];
  if (allSkuQtys)
    for (const entry of Object.entries(groupedTrace)) {
      const key = entry[0] as string;
      const value = entry[1] as ITraceEntry[];

      const foundAllSku =
        allSkuQtys &&
        allSkuQtys.itemSkuQtys &&
        allSkuQtys.itemSkuQtys.length > 0
          ? allSkuQtys.itemSkuQtys.filter(
              (sku) => sku?.barcode === value[0].barcode
            )
          : [];

      const allBinLocation = foundAllSku.map((sku) => ({
        id: sku?.bin_id,
        name: sku?.bin_name,
        stock_qty: sku?.stock_qty,
        warehouse_id: sku?.warehouse_id,
      }));

      const filterBinLocations = allBinLocation.filter(
        (bin) => bin.warehouse_id === otherData.destination_warehouse_id
      );

      const foundBinLocation = filterBinLocations.find(
        (bin) => bin.id === value[0].source_bin_location_id
      );

      const formatSerial = value.map((serial) => ({
        ...serial,
        posted_date: serial.posted_date ?? undefined,
        scanned_by: serial.scanned_by ?? undefined,
        all_bin_locations: filterBinLocations,
        stock_qty: foundBinLocation?.stock_qty,
      }));

      const formatTrace: ITraceEntry = {
        item_unique_id: value[0].item_unique_id,
        is_stockable: value[0].is_stockable,
        sku_name: value[0].sku_name,
        item_name: value[0].item_name,
        item_img_url: value[0].item_img_url,
        tracability: value[0].tracability,
        type: value[0].type,
        unique_id: value[0].unique_id,
        qty: value.reduce((prev, curr) => prev + curr.qty, 0),
        posted_qty: value[0].posted_qty,
        document_item_qty: value[0].document_item_qty,
        posted_date:
          value[0].tracability === Tracability.Normal
            ? value[0].posted_date
            : undefined,
        created_date: value[0].created_date,
        uom: value[0].uom,
        source_bin_location:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location
            : undefined,
        source_bin_location_id:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location_id
            : undefined,
        barcode:
          value[0].tracability === Tracability.Normal
            ? value[0].barcode
            : undefined,
        serial_list: value.some(
          (serial) => serial.tracability === Tracability.Normal
        )
          ? undefined
          : value[0].status === "is_scanned"
          ? formatSerial
          : undefined,
        status:
          value[0].tracability === Tracability.Normal
            ? value[0].status
            : undefined,
        scanned_by:
          value[0].tracability === Tracability.Normal
            ? value[0].scanned_by ?? undefined
            : undefined,
        all_bin_locations: filterBinLocations,
        reference_unique_id: value[0].reference_unique_id,
        reference_line_item: value[0].reference_line_item,
        stock_qty:
          value[0].tracability === Tracability.Normal
            ? foundBinLocation?.stock_qty
            : formatSerial?.reduce<number>(
                (prev, curr) => prev + (curr.stock_qty ?? 0),
                0
              ),
        remark: value[0].remark,
      };

      allTraceEntries.push(formatTrace);
    }

  return {
    ...otherData,
    trace_entry_list: allSkuQtys ? allTraceEntries : trace_entry_list,
    created_date: created_date ? dayjs(created_date) : undefined,
    delivery_date: delivery_date ? dayjs(delivery_date) : undefined,
    exported_date: exported_date ? dayjs(exported_date) : undefined,
    issue_date: issue_date ? dayjs(issue_date) : undefined,
  } as IDeliveryOrder;
};

export const formatSalesItemListToTraceEntry = (
  item_list: ISalesOrder["item_list"] | ICashSales["item_list"],
  documentType?: SalesReferenceDocumentType
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = item_list.map((item) => ({
    unique_id: uuidv4(),
    reference_unique_id: "",
    is_stockable: true,
    type: InventoryDocumentType.GoodsIssue,
    item_unique_id: item.item_unique_id || "",
    sku_name: item.item_sku_name || "",
    item_name: item.item_name || "",
    item_img_url: item.item_img_url || [],
    qty: 0,
    document_item_qty: item.qty,
    posted_qty: item.qty_shipped,
    uom: { id: item.uom_id, name_th: item.uom_name },
    uom_id: item.uom_id,
    cost_price: item.price_per_unit,
    remark: "",
    reference_line_item: {
      line_item_unique_id: item.unique_id,
      line_item_document_type: documentType,
    },
    tracability: (item.tracability as Tracability) || Tracability.Normal,
    barcode: item.tracability === Tracability.Normal ? item.barcode : "",
    status: item.tracability === "serial" ? undefined : "is_active",
    stock_qty: item.stock_qty,
  }));
  return formatData as ITraceEntry[];
};

export const createDeliveryOrderFromSales = async (
  data: ISalesOrder | ICashSales,
  documentType: SalesReferenceDocumentType
): Promise<IDeliveryOrder> => {
  const formatPayload = {
    unique_id: "",
    main_status: "",
    sub_status: "",
    aggrid_status: "",
    flag_status: [],
    issue_date: dayjs(),
    exported_date: dayjs(),
    delivery_date: data.delivery_date
      ? data.delivery_date.toISOString()
      : undefined,
    last_updated_by: null,
    created_date: dayjs(),
    reference_no: "",
    reference_document_list: [
      {
        document_id: data.id || 0,
        document_type:
          documentType === SalesReferenceDocumentType.SalesOrder
            ? SalesReferenceDocumentType.SalesOrder
            : SalesReferenceDocumentType.CashSales,
        document_unique_id: data.unique_id,
      },
    ],
    related_user_list: [],
    delivery_type: "",
    tracking_no: "",
    customer_unique_id: data.customer_unique_id ?? "",
    customer_details: data.customer_details ?? {},
    tag_list: [],
    trace_entry_list: formatSalesItemListToTraceEntry(
      data.item_list,
      documentType
    ),
    remark: "",
  };

  return formatPayload as IDeliveryOrder;
};
